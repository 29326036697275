import { GeneralUtil } from './../../util/general-util';
 

export class NutritionFormDto {
  public evaluationDate: string;
  public nutritionist: string;
  public treatmentCenter: string;
  public feedingInformationReason: string[];
  public feedingInformationStatus: string;
  public weight: number;
  public height: number;
  public hemoglobin: number;
  public pcv: number;
  public midUpperArmCircumference: number;
  public headCircumference: number;
  public triceptsSkinfold: number;
  public subscapularSkinfold: number;
  public feedingInformation: string[];
  public nutritionalStatus: string[] = [];
  public patientCare: string;
  public outPatientCare: string[] =[];
  public inPatientCare: string;
  public referralToAnotherFacility: string[]= [];
  public nutritionCarePlan: string[];
  public anemiaManagement: string[];
  public birthWeightInformation: string;
  public bmi: number;
  public adult: boolean;
  public infant: boolean;
  public adolescent: boolean;
  public child: boolean;
  public clinicianName: string;
  public ageRange: string;
  constructor() {
    this.adolescent = false;
    this.child = false;
    this.infant = false;
    this.adult = false;
  }

  public mapToNutritionForms(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
        this.evaluationDate = data.evaluationDate;
        this.nutritionist = data.nutritionist;
        this.treatmentCenter = data.treatmentCenter;
        this.feedingInformationStatus = data.feedingInformationStatus;
        this.feedingInformationReason = data.feedingInformationReason;
        this.weight = data.weight;
        this.height = data.height;
        this.hemoglobin = data.hemoglobin;
        this.pcv = data.pcv;
        this.midUpperArmCircumference = data.midUpperArmCircumference;
        this.headCircumference = data.headCircumference;
        this.triceptsSkinfold = data.triceptsSkinfold;
        this.subscapularSkinfold = data.subscapularSkinfold;
        this.birthWeightInformation = data.birthWeightInformation;
        this.bmi = data.bmi;
        this.adult = data.adult;
        this.adolescent = data.adolescent;
        this.infant = data.infant;
        this.child = data.child;
        this.clinicianName = data.clinicianName;
        this.ageRange = data.ageRange;
        this.feedingInformation = data.feedingInformation;
        this.nutritionalStatus = data.nutritionalStatus;
        this.patientCare = data.patientCare;
        this.outPatientCare = data.outPatientCare;
        this.inPatientCare = data.inPatientCare;
        this.referralToAnotherFacility = data.referralToAnotherFacility;
        this.nutritionCarePlan = data.nutritionCarePlan;
        this.anemiaManagement = data.anemiaManagement;
        

    }
}

}
