import { Component, OnInit } from '@angular/core';
import { TreatmentEncounterStage } from 'src/app/constant/treatment-encounter-stage.enum';
import { DashboardStat } from 'src/app/dto/dashboard-stat';
import { PatientInformation } from 'src/app/entity/patient-information';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { AuthService } from './../../service/auth/auth.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public patients: PatientInformation[] = [];
  public treatmentEncounterStage = TreatmentEncounterStage;
  public showPreloader = false;
  public greeting;
  public dashboardStats = new DashboardStat();
  public search = '';

  constructor(public authService: AuthService,
    public patientService: PatientService,
    public router: Router

    ) { }

  ngOnInit() {
    this.greetingUser();
    this.initReload();
    this.getPatients('', '', '');
    setTimeout(() => {
      this.getDashboardStats()
    }, 500);
  }

  greetingUser() {
    const time = new Date().getHours();
    if (time < 12) {
      this.greeting = 'Good morning!';
    } else if (time < 18) {
      this.greeting = 'Good afternoon!';
    } else {
      this.greeting = 'Good evening!';
    }
  }

  getDashboardStats() {
    this.patientService.getDashboardStats().subscribe(
      data => {
        this.dashboardStats.mapToDashboardStats(data);
      },
      error => this.errorHandler(error)
    );
  }

    initReload() {
    if (!localStorage.getItem('load')) {
      localStorage.setItem('load', 'no load');
      location.reload();
    } else {
      localStorage.removeItem('load');
    }
  }

  getPatients(searchParam, treatmentEncounterStage, facility) {
    this.showPreloader = true;
    this.patientService.getPatients(searchParam, '', treatmentEncounterStage, 0, 5, facility)
      .subscribe(data => {
        this.showPreloader = false;
        this.patients = [];
        if (data.total > 0) {
          const patientArray = data.entities;
          patientArray.forEach(p => {
            const patient = new PatientInformation();
            patient.mapToPatientInformation(p);
            this.patients.push(patient);
          })
          return this.patients;
        }
      },
        error1 => {
          this.errorHandler(error1);
          this.showPreloader = false;
        });
  }

  public errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
