import { PatientService } from './../../../service/patient.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PatientInformation } from 'src/app/entity/patient-information';
import { Router } from '@angular/router';
import { GeneralUtil } from 'src/app/util/general-util';

declare function successToast(msg): any;

@Component({
  selector: 'app-page-five',
  templateUrl: './page-five.component.html',
  styleUrls: ['./page-five.component.scss']
})
export class PageFiveComponent implements OnInit {
  // variables
  public patientInformation: PatientInformation;
  @Output() previousPage = new EventEmitter<string>();
  @Output() toPageOne = new EventEmitter<string>();

  public showPreloader = false;


  constructor(public patientService: PatientService,
    public router: Router) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('patientInformation')) {
      this.patientInformation = JSON.parse(sessionStorage.getItem('patientInformation'));
    } else {
      this.gotoPageOne();
    }
  }

  toPreviousPage(event: Event) {
    sessionStorage.setItem('patientInformation', JSON.stringify(this.patientInformation));
    this.previousPage.emit('four');
    event.preventDefault();
  }

  gotoPageOne() {
    this.toPageOne.emit('one');
  }

  submitForm() {
    this.showPreloader = true;

    this.patientService.registerPatient(this.patientInformation)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Patient Registration Successful');

        setTimeout(() => {
          sessionStorage.removeItem('patientInformation');
          this.router.navigate(['/report/patient-information']);
        }, 500);
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  // Error Handling

  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
