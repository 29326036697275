import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PatientInformation } from '../../../entity/patient-information';
import { PreviousLiveBirth } from '../../../entity/previous-live-birth';
import { MaternalIllness } from './../../../entity/maternal-illness';
import { MaternalMedication } from './../../../entity/maternal-medication';
import { MaternalLifestyleAndExposures } from '../../../entity/maternal-lifestyle-and-exposures';
import { OtherMedicationDto } from '../../../dto/other-medication-dto';
import { Malformation } from '../../../entity/malformation';


declare function successToast(msg): any;
declare function failureToast(msg): any;
@Component({
  selector: 'app-page-four',
  templateUrl: './page-four.component.html',
  styleUrls: ['./page-four.component.scss']
})
export class PageFourComponent implements OnInit {
  @Output() previousPage = new EventEmitter<string>();
  @Output() pageFourCompleted = new EventEmitter<string>();
  @Output() toPageOne = new EventEmitter<string>();
  public patientInformation: PatientInformation;
  public previousLiveBirth = new PreviousLiveBirth();
  public otherMedicationDto = new OtherMedicationDto();
  public malformationList = [];
  public timeOfExposureList = [];
  public malformations = [];
  public diabetes = [];
  public hypertension = [];
  public asthma = [];
  public chronicDisease = [];
  public tuberculosis = [];
  public threatenedAbortion = [];
  public vaginalBleeding = [];
  public folicAcid = [];
  public iron = [];
  public vitamins = [];
  public nativeMedication = [];
  public otherMedication = [];
  public tobacco = [];
  public smokelessTobacco = [];
  public alcohol = [];
  public diagnosticXrays = [];
  public environmentalPollution = [];
  public environmentalHazard = [];
  public otherMed: string;
  dropdownSettings: IDropdownSettings = {};
  public today = new Date().toJSON().split('T')[0];

  constructor() { }

  ngOnInit(): void {

    if (sessionStorage.getItem('patientInformation')) {
      this.patientInformation = JSON.parse(sessionStorage.getItem('patientInformation'));
    } else {
      this.gotoPageOne();
    }

    this.malformationList = [
      { item_id: 1, item_text: 'Cleft' },
      { item_id: 2, item_text: 'Craniofacial anomaly' },
      { item_id: 3, item_text: 'Limb abnormality' },
      { item_id: 4, item_text: 'Cardiovascular anomaly' },
      { item_id: 5, item_text: 'Multiple malformations' },
      { item_id: 6, item_text: 'None' },
      { item_id: 7, item_text: 'Unknown' }
    ];

    this.timeOfExposureList = [
      { item_id: 1, item_text: 'Before conception' },
      { item_id: 2, item_text: 'During 1st Trimester' },
      { item_id: 3, item_text: 'During 2nd Trimester' },
      { item_id: 4, item_text: 'During 3rd Trimester' },
      { item_id: 5, item_text: 'No history' },
      { item_id: 7, item_text: 'Unknown' }
    ]

    /* multiple select settings */
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
     };
  }

  addPreviousBirth(previousBirthForm) {
    this.patientInformation.maternalData.previousLiveBirths.push(this.previousLiveBirth);

    this.previousLiveBirth = new PreviousLiveBirth();
    previousBirthForm.resetForm();

    successToast('Previous birth added');
  }

  removePreviousBirth(child: PreviousLiveBirth) {
    this.patientInformation.maternalData.previousLiveBirths =
        this.patientInformation.maternalData.previousLiveBirths
            .filter(c => child.dateOfBirth !== c.dateOfBirth);

    successToast('Previous birth removed');
  }

  addMalformations() {
    this.patientInformation.maternalData.malformations = [];
    this.malformations.forEach(m => {
      const malfunc = new Malformation();
      malfunc.details = m.item_text;
      if(!this.patientInformation.maternalData.malformations.includes(malfunc))
      this.patientInformation.maternalData.malformations.push(malfunc)
    })
  }

  addDiabetes() {
    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'DIABETES');
    })

    this.diabetes.forEach(m => {
      const diabetes = new MaternalIllness();
      diabetes.illness = 'DIABETES';
      diabetes.periodOfIllness = m.item_text;
      this.patientInformation.maternalData.maternalIllnesses.push(diabetes)
    })
  }

  addHypertension() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'HYPERTENSION');
    })

    this.hypertension.forEach(m => {
      const hypertens = new MaternalIllness();
      hypertens.illness = 'HYPERTENSION';
      hypertens.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(hypertens)
    })
  }

  addAsthma() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'ASTHMA');
    })

    this.asthma.forEach(m => {
      const asth = new MaternalIllness();
      asth.illness = 'ASTHMA';
      asth.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(asth)
    })

  }

  addChronicDisease() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'CHRONIC_DISEASE');
    })

    this.chronicDisease.forEach(m => {
      const chronicdisease = new MaternalIllness();
      chronicdisease.illness = 'CHRONIC_DISEASE';
      chronicdisease.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(chronicdisease)
    })
  }

  addTuberculosis() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'TUBERCULOSIS');
    })

    this.tuberculosis.forEach(m => {
      const tubercu = new MaternalIllness();
      tubercu.illness = 'TUBERCULOSIS';
      tubercu.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(tubercu)
    })
  }

  addThreatenedAbortion() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'THREATENED_ABORTION');
    })

    this.threatenedAbortion.forEach(m => {
      const threatAbortion = new MaternalIllness();
      threatAbortion.illness = 'THREATENED_ABORTION';
      threatAbortion.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(threatAbortion)
    })
  }

  addVaginalBleeding() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'VAGINAL_BLEEDING');
    })

    this.vaginalBleeding.forEach(m => {
      const vaginalBleed = new MaternalIllness();
      vaginalBleed.illness = 'VAGINAL_BLEEDING';
      vaginalBleed.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(vaginalBleed)
    })
  }

  /* Maternal Medications */
  addFolicAcid() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'FOLIC_ACID');
    })

    this.folicAcid.forEach(m => {
      const folicacid = new MaternalMedication();
      folicacid.medicationName = 'FOLIC_ACID';
      folicacid.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(folicacid)
    })
  }

  addIron() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'IRON');
    })

    this.iron.forEach(m => {
      const Iron = new MaternalMedication();
      Iron.medicationName = 'IRON';
      Iron.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(Iron)
    })
  }

  addVitamins() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'VITAMINS');
    })

    this.vitamins.forEach(m => {
      const vitamin = new MaternalMedication();
      vitamin.medicationName = 'VITAMINS';
      vitamin.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(vitamin)
    })
  }

  addNativeMedication() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'NATIVE_MEDICATION');
    })

    this.nativeMedication.forEach(m => {
      const nativeMed = new MaternalMedication();
      nativeMed.medicationName = 'NATIVE_MEDICATION';
      nativeMed.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(nativeMed)
    })
  }

  addOtherMedication(otherMedicationForm) {
    // ensure we don't have a medication of same-name created previously
    let itExists = false;
    this.patientInformation.maternalData.maternalMedications.forEach(m => {
      if (m.medicationName === 'OTHERS' && m.otherMedicationName === this.otherMedicationDto.otherMedicationName) {
        itExists = true;
      }
    });

    if (itExists) {
      failureToast(this.otherMedicationDto.otherMedicationName + ' has been created already');
      return;
    }

    // create maternal medications of name OTHER
    const otherMedicationList: MaternalMedication[] = [];
    this.otherMedicationDto.otherMedicationTakenAt.forEach(o => {
      const medication = new MaternalMedication();
      medication.medicationName = 'OTHERS';
      medication.otherMedicationName = this.otherMedicationDto.otherMedicationName;
      medication.otherMedicationTakenAt = o.item_text;
      otherMedicationList.push(medication);
    });

    otherMedicationList.forEach(o => {
      this.patientInformation.maternalData.maternalMedications.push(o);
    });

    successToast(this.otherMedicationDto.otherMedicationName + ' medication added');
    otherMedicationForm.resetForm();
    this.otherMedicationDto = new OtherMedicationDto();
  }

  getOtherMedications() {

    const prop = 'otherMedicationName';
    const lookupObject  = {};
    const otherMedications: MaternalMedication[] = [];

    const originalArray = this.patientInformation.maternalData.maternalMedications;

    for (const i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (const i in lookupObject) {
      otherMedications.push(lookupObject[i]);
    }
    return otherMedications.filter(o => o.medicationName === 'OTHERS');
  }

  removeOtherMedication(otherMedicationName: string) {
    this.patientInformation.maternalData.maternalMedications =
        this.patientInformation.maternalData.maternalMedications.filter(m => {
                return (m.otherMedicationName !== otherMedicationName);
            });

    successToast(otherMedicationName  + ' removed');
  }

  addTobacco() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'TOBACCO');
    })

    this.tobacco.forEach(m => {
      const tobac = new MaternalLifestyleAndExposures();
      tobac.contaminant = 'TOBACCO';
      tobac.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(tobac)
    })
  }

  addSmokelessTobacco() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'SMOKELESS_TOBACCO');
    })

    this.smokelessTobacco.forEach(m => {
      const smokelesstobac = new MaternalLifestyleAndExposures();
      smokelesstobac.contaminant = 'SMOKELESS_TOBACCO';
      smokelesstobac.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(smokelesstobac)
    })
  }

  addAlcohol() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'ALCOHOL');
    })

    this.alcohol.forEach(m => {
      const alcoh = new MaternalLifestyleAndExposures();
      alcoh.contaminant = 'ALCOHOL';
      alcoh.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(alcoh)
    })
  }

  addDiagnosticXrays() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'DIAGNOSTIC_XRAYS');
    })

    this.diagnosticXrays.forEach(m => {
      const diagnostic = new MaternalLifestyleAndExposures();
      diagnostic.contaminant = 'DIAGNOSTIC_XRAYS';
      diagnostic.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(diagnostic)
    })
  }

  addEnvironmentalPollution() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'EXPOSURE_TO_POLLUTIONS');
    })

    this.environmentalPollution.forEach(m => {
      const environment = new MaternalLifestyleAndExposures();
      environment.contaminant = 'EXPOSURE_TO_POLLUTIONS';
      environment.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(environment)
    })
  }

  addEnvironmentalHazard() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'EXPOSURE_TO_HAZARDS');
    })

    this.environmentalHazard.forEach(m => {
      const environmentHazard = new MaternalLifestyleAndExposures();
      environmentHazard.contaminant = 'EXPOSURE_TO_HAZARDS';
      environmentHazard.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(environmentHazard)
    })
  }

  gotoPageOne() {
    this.toPageOne.emit('one');
  }

  toPreviousPage(event: Event) {
    sessionStorage.setItem('patientInformation', JSON.stringify(this.patientInformation));
    this.previousPage.emit('three');
    event.preventDefault();
  }

  pageFourFinished(event: Event) {
    sessionStorage.setItem('patientInformation', JSON.stringify(this.patientInformation))
    this.pageFourCompleted.emit('four');
    event.preventDefault();
  }
}
