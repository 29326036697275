import { Address } from './address';
import { GeneralUtil } from '../util/general-util';

export class Facility {
    public address = new Address();
    public facilityLevel: string;
    public name: string;
    public id: number;
    public active: boolean;
    public facilityRef: string;

    // transient
    public selected: boolean;

    public mapToFacility(data: any) {

        if (GeneralUtil.isValidJSON(data)) {
            if (data.address) {
                this.address.mapToAddress(data.address);
            }
            this.name = data.name;
            this.facilityLevel = data.level;
            this.active = data.active;
            this.id = data.id;
            this.facilityRef = data.facilityRef;
        }
    }
}
