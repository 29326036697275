import { Component, OnInit } from '@angular/core';
import { FormsDto } from '../../../../../../../dto/forms/forms-dto';
import { FormsService } from '../../../../../../../service/forms.service';
import { PatientInformation } from '../../../../../../../entity/patient-information';
import { Router } from '@angular/router';
import { PatientService } from '../../../../../../../service/patient.service';
import { GeneralUtil } from '../../../../../../../util/general-util';
import { SpeechAssessmentFormDto } from './../../../../../../../dto/forms/speech-assessment-form-dto';


declare function successToast(msg: string): any;

@Component({
  selector: 'app-speech-assessment-forms',
  templateUrl: './speech-assessment-forms.component.html',
  styleUrls: ['./speech-assessment-forms.component.scss']
})
export class SpeechAssessmentFormsComponent implements OnInit {
  // variables
  public action: string;
  public formRef: string;
  public formCode: string;
  public patientId: number;
  public submitButton = false;
  public patientInformation: PatientInformation;
  public today = new Date().toJSON().split('T')[0];
  public formsDto = new FormsDto();
  public speechAssessmentForm: SpeechAssessmentFormDto;

  constructor(
    public router: Router,
    public patientService: PatientService,
    public formService: FormsService) { }

  ngOnInit(): void {
    this.speechAssessmentForm = new SpeechAssessmentFormDto();

    setTimeout(() => {
      this.getPatient(this.patientId);

      if (this.action === 'update') {
        this.getSingleSpeechAssessmentForm();
      }

    }, 500);

  }

  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  getPatient(id) {
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => {
          this.errorHandler(error);
        })
  }

  getSingleSpeechAssessmentForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {

        this.speechAssessmentForm = new SpeechAssessmentFormDto();
        this.speechAssessmentForm.mapFormToData(data);
        this.speechAssessmentForm.dateOfProcedure = GeneralUtil.reverseDateFormat(this.speechAssessmentForm.dateOfProcedure);

        },
        error1 => {
          this.errorHandler(error1);
        })
  }

  submitForm() {
    this.submitButton = true;
    this.speechAssessmentForm.dateOfProcedure = GeneralUtil.convertDateTimeToDateformat(this.speechAssessmentForm.dateOfProcedure);
    // assign form, formcode and patient ID to form to be posted
    this.formsDto.speechAssessmentFormDto = this.speechAssessmentForm;
    this.formsDto.code = this.formCode;

    /* Registration */
    if (this.action === 'registration') {
      this.formsDto.patientId = this.patientId;

      this.formService.formRegistration(this.formsDto)
          .subscribe(data => {
              this.submitButton = false;
              successToast('Form submitted successfully');
              this.router.navigate(['/report/view-patient-information/' + this.patientId]);
          },
              error1 => {
                  this.submitButton = false;
                  this.errorHandler(error1);
              });
      return;
    }

     /* Update */
    if (this.action === 'update') {
      this.formService.formUpdate(this.formsDto, this.formRef)
        .subscribe(data => {
          this.submitButton = false;
          successToast('Form updated successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, error1 => {
          this.submitButton = false;
          this.errorHandler(error1);
        });
      return;
    }

}


    // Error handling
    errorHandler(error) {
      return GeneralUtil.errorHandler(error)
    }
}
