import {GeneralUtil} from '../util/general-util';

export class Lga {
    public id: number;
    public name: string;

    constructor() {}

    public mapToLga(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.id = data.id;
            this.name = data.name;
        }
    }
}
