import { Component, OnInit, ViewChild } from '@angular/core';
import { FormCode } from 'src/app/constant/form-code-enum';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormsService } from './../../../../../../service/forms.service';
import { SurgicalTreatmentViewComponent } from './surgical-treatment-view/surgical-treatment-view.component';
import { NutritionFormViewComponent } from './nutrition-form-view/nutrition-form-view.component';
import { RehabilitationFormViewComponent } from './rehabilitation-form-view/rehabilitation-form-view.component';
import { SpeechAssessmentViewComponent } from './speech-assessment-view/speech-assessment-view.component';
import { OrthodonticTreatmentViewComponent } from './orthodontic-treatment-view/orthodontic-treatment-view.component';
import {SpeechTreatmentFormViewComponent} from './speech-treatment-form-view/speech-treatment-form-view.component';
import {MortalityFormViewComponent} from './mortality-form-view/mortality-form-view.component';
import { ComplicationFormViewComponent } from './complication-form-view/complication-form-view.component';

declare function failureToast(msg): any;

@Component({
  selector: 'app-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss']
})
export class FormViewComponent implements OnInit {
  // variables
  public formCode: string;
  public patientId: any;
  public formRef: string;
  public formEnum = FormCode;

  // Form modules
  @ViewChild(SurgicalTreatmentViewComponent) private surgicalTreatment: SurgicalTreatmentViewComponent;
  @ViewChild(NutritionFormViewComponent) private nutritionFormView: NutritionFormViewComponent;
  @ViewChild(RehabilitationFormViewComponent) private rehabilitaionForm: RehabilitationFormViewComponent;
  @ViewChild(SpeechAssessmentViewComponent) private speechAssessment: SpeechAssessmentViewComponent;
  @ViewChild(OrthodonticTreatmentViewComponent) private orthodonticTreatment: OrthodonticTreatmentViewComponent;
  @ViewChild(SpeechTreatmentFormViewComponent) private speechTreatment: SpeechTreatmentFormViewComponent;
  @ViewChild(ComplicationFormViewComponent) private complicationForm: ComplicationFormViewComponent;
  @ViewChild(MortalityFormViewComponent) private mortalityForm: MortalityFormViewComponent;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formService: FormsService
  ) { }

  ngOnInit(): void {
    // get form code and patient id
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.formCode = params.get('formCode');
      this.patientId = Number(params.get('patientId'));
      this.formRef = params.get('formRef');
    });

    // pass the formCode and patientId to the appropriate Form
    switch (this.formCode) {

      case FormCode.SURGICAL_TREATMENT_CODE:
        setTimeout(() => {
          this.surgicalTreatment.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

      case FormCode.COMPLICATION_CODE:
        setTimeout(() => {
          this.complicationForm.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

      case FormCode.MORTALITY_CODE:
        setTimeout(() => {
          this.mortalityForm.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

      case FormCode.REHABILITATION_CODE:
        setTimeout(() => {
          this.rehabilitaionForm.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

      case FormCode.SPEECH_ASSESSMENT_CODE:
        setTimeout(() => {
          this.speechAssessment.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

      case FormCode.NUTRITION_CODE:
        setTimeout(() => {
          this.nutritionFormView.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

        case FormCode.NUTRITION_CODE_II:
          setTimeout(() => {
            this.nutritionFormView.setFormDetails(this.formCode, this.patientId, this.formRef);
          }, 500);
          break;

      case FormCode.ORTHODONTIC_TREATMENT_CODE:
        setTimeout(() => {
          this.orthodonticTreatment.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

      case FormCode.SPEECH_TREATMENT_CODE:
        setTimeout(() => {
          this.speechTreatment.setFormDetails(this.formCode, this.patientId, this.formRef);
        }, 500);
        break;

      default:
        failureToast('Form error');
    }
  }

}
