import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {GeneralEnum} from '../constant/general-enum.enum';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmileTrainPartnerService {

  constructor(public httpClient: HttpClient) { }

  public getSmileTrainPartners() {

    const url = GeneralEnum.baseUrl + '/auth/smile-train-partners?status=ACTIVE';

    return this.httpClient.get<any>(url)
        .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
