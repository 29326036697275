export class SpeechAssessmentFormDto {
  public clinicianComment: string;
  public patientRef: string;
  public comments: string;
  public clinicianName: string;
  public dateOfProcedure: string;
  public speechAssessmentType: string;
  public status: string;
  public speechAssessment: string;
  public recommendations: string;
  public patientFirstName: string;
  public patientLastName: string;
  public createdAt: string;

  public mapFormToData(data) {
    this.clinicianComment = data.clinicianComment;
    this.comments = data.comments;
    this.patientRef = data.patientRef;
    this.patientFirstName = data.patientFirstName;
    this.patientLastName = data.patientLastName;
    this.clinicianName = data.clinicianName;
    this.dateOfProcedure = data.dateOfProcedure;
    this.speechAssessmentType = data.speechAssessmentType;
    this.status = data.status;
    this.speechAssessment = data.speechAssessment;
    this.recommendations = data.recommendations;
    this.patientRef = data.patientRef;
    this.createdAt = data.createdAt;
  }
}
