import { Component, OnInit } from '@angular/core';
import { GeneralUtil } from '../../../../util/general-util';
import { FormCode } from '../../../../constant/form-code-enum';
import { FormsService } from '../../../../service/forms.service';
import { SpeechTreatmentFormDto } from '../../../../dto/forms/speech-treatment-dto';
import { FacilityService } from '../../../../service/facility.service';
import { Facility } from '../../../../entity/facility';
import { PaginationDataDto } from '../../../../dto/pagination-data-dto';
import { GeneralEnum } from '../../../../constant/general-enum.enum';
import { FilterDto } from './../../../../dto/filter-dto';

@Component({
  selector: 'app-speech-treatment-form',
  templateUrl: './speech-treatment-form.component.html',
  styleUrls: ['./speech-treatment-form.component.scss']
})
export class SpeechTreatmentFormComponent implements OnInit {
  public showPreloader = false;
  public formCode = FormCode;
  public facilities: Facility[] = [];
  public speechTreatmentForms = [];
  public paginationData = new PaginationDataDto();  // pagination object
  public filterDto = new FilterDto();

  constructor(private formService: FormsService, public facilityService: FacilityService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getSpeechTreatmentForms()
  }

  filterForm() {
    this.initializePaginationData();

    this.getSpeechTreatmentForms();
  }

  getSpeechTreatmentForms() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.formService.getForms(this.formCode.SPEECH_TREATMENT_CODE, this.filterDto.facility, (currentPage - 1),
      defaultLimit, this.filterDto.search)
      .subscribe(data => {
        this.speechTreatmentForms = [];
        this.showPreloader = false;
        const speechTreatmentFormsArray = data.entities;
        this.paginationData.totalSize = data.total;
        if (speechTreatmentFormsArray !== null && speechTreatmentFormsArray.length > 0) {
          speechTreatmentFormsArray.forEach(s => {
            const speechTreatment = new SpeechTreatmentFormDto();
            speechTreatment.mapToSpeechTreatmentForm(s);
            this.speechTreatmentForms.push(speechTreatment);
          })
        }
    } ,
    error1 => {
      this.errorHandler(error1);
      this.showPreloader = false;
    })
  }

  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  export2csv() {
    return GeneralUtil.export2csv('speech-treatment-report.csv');
  }

  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

     // Error handling
     errorHandler(error) {
      return GeneralUtil.errorHandler(error)
     }

}
