import {GeneralUtil} from '../util/general-util';

export class MaternalMedication {
    public medicationName: string;
    public medicationTakenAt: string;

    public otherMedicationName: string;
    public otherMedicationTakenAt: string;

    constructor() {}

    public mapToMaternalMedication(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.medicationName = data.medicationName;
            this.medicationTakenAt = data.medicationTakenAt;
            this.otherMedicationName = data.otherMedicationName;
            this.otherMedicationTakenAt = data.otherMedicationTakenAt;
        }
    }
}
