import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PatientInformation } from '../../../entity/patient-information';
import { CountryAndStateService } from '../../../service/country-and-state.service';
import { Country } from '../../../entity/country';
import { GeneralUtil } from '../../../util/general-util';
import { State } from '../../../entity/state';
import { Lga } from '../../../entity/lga';
import { ParentGuardianInfo } from '../../../entity/parent-guardian-info';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientDetails } from 'src/app/entity/patient-details-dto';
import { PatientService } from 'src/app/service/patient.service';


declare function successToast(msg: string): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-page-two-update',
  templateUrl: './page-two-update.component.html',
  styleUrls: ['./page-two-update.component.scss']
})
export class PageTwoUpdateComponent implements OnInit {

  // variables
  public parentGuardianInfo = new ParentGuardianInfo();
  public today = new Date().toJSON().split('T')[0];
  public countries: Country[] = [];
  public states: State[] = [];
  public lgas: Lga[] = [];
  public ageNumber: number;
  public ageRange: string;
  public age: number;
  public enterAge = 'dateOfBirth';
  public id: string;
  public patientInformation = new PatientInformation();
  public patient: PatientDetails;
  public showPreloader = false;


  constructor(public countryAndStateService: CountryAndStateService,
    public router: Router,
    public route: ActivatedRoute,
    public patientService: PatientService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('patientId');
    this.getPatient(this.id);

    // get countries
    this.getCountries();
  }

  getCountries() {
    this.countries = [];
    this.countryAndStateService.getCountries()
      .subscribe(data => {
        if (data.length > 0) {
          const countryList = data;
          countryList.forEach(c => {
            const country = new Country();
            country.mapToCountry(c);
            this.countries.push(country);
          });
        }
      },
        error1 => this.errorHandler(error1));
  }

  getStates(country: string) {
    // get id of country selected.
    const countryName = country;
    if (!GeneralUtil.isValidString(countryName)) {
      return;
    }

    let countryId;
    this.countries.forEach(c => {
      if (c.name === countryName) {
        countryId = c.id;
      }
    });

    this.states = [];
    this.countryAndStateService.getStates(countryId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const state = new State();
            state.mapToState(c);
            this.states.push(state);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  getLgas(state: string) {

    // get id of state selected.
    const stateName = state;
    if (!GeneralUtil.isValidString(stateName)) {
      return;
    }

    let stateId;
    this.states.forEach(s => {
      if (s.name === stateName) {
        stateId = s.id;
      }
    });

    this.lgas = [];
    this.countryAndStateService.getLgas(stateId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const lga = new Lga();
            lga.mapToLga(c);
            this.lgas.push(lga);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });

  }

  addNewParentOrGuardian(patientForm) {
    // validate - ensure Father and Mother are not created multiple times
    const parentGuardianList = this.patientInformation.parentGuardianInfo;
    if (this.parentGuardianInfo.relationshipWithChild === 'FATHER') {
      if (parentGuardianList.filter(p => {
        return p.relationshipWithChild === 'FATHER';
      }).length > 0) {
        failureToast('Father has been created already');
        return;
      }
    }

    if (this.parentGuardianInfo.relationshipWithChild === 'MOTHER') {
      if (parentGuardianList.filter(p => {
        return p.relationshipWithChild === 'MOTHER';
      }).length > 0) {
        failureToast('Mother has been created already');
        return;
      }
    }

    if (this.parentGuardianInfo.relationshipWithChild === 'GUARDIAN') {
      if (parentGuardianList.filter(p => {
        return p.relationshipWithChild === 'GUARDIAN';
      }).length > 0) {
        failureToast('Guardian has been created already');
        return;
      }
    }

    this.parentGuardianInfo.dateOfBirth = GeneralUtil.reverseDateFormat(this.parentGuardianInfo.dateOfBirth);
    parentGuardianList.push(this.parentGuardianInfo);
    this.patientInformation.parentGuardianInfo = parentGuardianList;

    this.parentGuardianInfo = new ParentGuardianInfo();

    patientForm.resetForm();
    successToast('Parent/Guardian added');

  }

  removeGuardian(pg: ParentGuardianInfo) {
    this.patientInformation.parentGuardianInfo =
      this.patientInformation.parentGuardianInfo
        .filter(g => pg.firstName !== g.firstName ||
          pg.relationshipWithChild !== g.relationshipWithChild);

    successToast('Parent/Guardian has been deleted');
  }

  setDateOfBirth() {
    const dob = GeneralUtil.getDateOfBirthByAgeRange(this.ageRange, this.ageNumber);
    this.parentGuardianInfo.dateOfBirth =
    GeneralUtil.convertDateTimeToDateInputFormat(GeneralUtil.convertDateStringToIsoDateTimeFormat(dob));

  }

  getPatient(id) {
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);


        return this.patient;
      },
        error1 => {
          this.errorHandler(error1);
        });
  }



  // UPDATE PAGE TWO
  updatePageTwo() {
    this.showPreloader = true;
    // ensure required Guardians Father and Mother are registered
    const parentGuardianList = this.patientInformation.parentGuardianInfo;

    let relationship = false;
    parentGuardianList.forEach(p => {
      if (p.relationshipWithChild === 'MOTHER' ||
        p.relationshipWithChild === 'FATHER' ||
        p.relationshipWithChild === 'GUARDIAN') {
        relationship = true;
      }

    });

    if (relationship == false) {
      failureToast('Father or Mother or Guardian details MUST be registered');
      this.showPreloader = false;
      return;
    }
    // update
    this.patientService.updateParentGuardian(this.patientInformation.parentGuardianInfo, this.id)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Data updated successfully');

        // route
        setTimeout(() => {
          this.router.navigate(['/report/view-patient-information/' + this.patientInformation.id]);
        }, 1000);
      },

        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);

        }
      );
    return;
  }


  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
