import { updateRoleRequest } from './../../../dto/update-role-dto';

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Role } from './../../../entity/role';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './../../../service/auth/auth.service';
import { CreateRoleRequestDto } from './../../../dto/role-dto';
import { Permission } from './../../../entity/permission';
import { Status } from '../../../constant/status.enum';
import { RoleService } from './../../../service/role.service';
import { Router } from '@angular/router';
import { GeneralUtil } from '../../../util/general-util';


declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss']
})
export class RoleManagementComponent implements OnInit {

  // variables
  public roles: Role[] = [];
  public showPreloader = false;
  public newrole = new CreateRoleRequestDto();
  public permissions: Permission[] = [];
  public status = Status;
  public newRole = new Role();
  public selectedpermissions = []
  public updateRole = new Role();
  @ViewChild('close') close: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;


  constructor(public roleservice: RoleService,
    public authService: AuthService,
    public router: Router) { }

  ngOnInit() {
    // get roles
    this.getRoles();
    // get permissions
    this.getPermission()
  }

  registerRole() {
    if (this.newrole.permissions.length < 1) {
      failureToast('At least one permission is required');
    } else {
      this.showPreloader = true;
      this.roleservice.registerRole(this.newrole)
        .subscribe(data => {
          this.showPreloader = false;
          successToast('Role registered successfully');
          setTimeout(() => {
            this.newrole = new CreateRoleRequestDto();
            this.close.nativeElement.click();
            this.getRoles();
          }, 1000);
        },
          error1 => {
            this.showPreloader = false;
            this.errorHandler(error1);
          });
    }
  }

  changeRoleStatus() {
    this.roleservice.changeRoleStatus(this.newRole.id, this.newRole.status)
      .subscribe(data => {
        successToast('status changed successfully!');
        /* to get a role and map to new role */
        this.updateRole = new Role();
        this.updateRole.mapToRole(data);
        /* find the index of that particular role, from role array, use the index to 
        update the new role */
        const index = this.roles.findIndex(roleindex => roleindex.id == this.updateRole.id);
        this.roles[index] = this.updateRole;
        setTimeout(() => {
          this.showPreloader = false;
          this.closeModal.nativeElement.click();
        }, 1000)
      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      })
  }

  getSingleRole(roleId: number) {
    this.selectedpermissions = [];
    this.roleservice.getRoleById(roleId)
      .subscribe(data => {
        this.newRole.mapToRole(data);
        this.newRole.permissions.forEach(p => {
          if (!this.selectedpermissions.includes(p.id)) {
            this.selectedpermissions.push(p.id)
          }
        });

      }, error1 => {
        this.errorHandler(error1);
      })
  }

  onCheckChange(event) {
    // selected checkboxes
    if (event.target.checked) {
      if (this.newrole.permissions.includes(Number(event.target.value)) === false) {
        this.newrole.permissions.push(Number(event.target.value));
      }
    } else {
      // unselected checkboxes
      this.newrole.permissions.forEach(f => {
        if (f == event.target.value) {
          const index = this.newrole.permissions.indexOf(f);

          if (index > -1) {
            this.newrole.permissions.splice(index, 1);
          }
        }
      })
      const index = this.newrole.permissions.findIndex(x => x === event.target.value);
      this.newrole.permissions.splice(index, 1);
    }
  }

  getRoles() {
    this.showPreloader = true;
    this.roles = [];
    this.roleservice.getRoles()
      .subscribe(data => {
        this.showPreloader = false;
        const roleArray = data;
        if (roleArray.length > 0) {
          roleArray.forEach(r => {
            const role = new Role();
            role.mapToRole(r);
            this.roles.push(role);
          });
        }
      }, error1 => {
        this.errorHandler(error1);
      })
  }

  removeUnderscore(mystring: string): string {
    return mystring.replace(/_/g, ' ');
  }

  getPermission() {
    this.roleservice.getPermissions()
      .subscribe(data => {
        this.permissions = [];
        if (data.length > 0) {
          data.forEach(p => {
            const rol = new Permission();
            rol.mapToPermission(p);
            this.permissions.push(p);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  // Error Handling

  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
