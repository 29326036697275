import { GeneralUtil } from '../../util/general-util';
export class OrthodonticTreatmentType {
  public dateOfProcedure: string;
  public repairType: string;
  public technique: string;

  public mapToOrthodonticTreatmentType(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.dateOfProcedure = data.dateOfProcedure;
      this.repairType = data.repairType;
      this.technique = data.technique;
    }
  }
}
