import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { StateDto } from './../dto/state-dto';
import { LgaDto } from './../dto/lga-dto';
 
 

@Injectable({
  providedIn: 'root'
})
export class CountryAndStateService {

  constructor(public httpClient: HttpClient) { }
  public getCountries() {
    const url = GeneralEnum.baseUrl + '/country';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public getStates(countryId: number) {
    const url = GeneralEnum.baseUrl + '/state/' + countryId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

   
  public getLgas(stateId: number) {
    const url = GeneralEnum.baseUrl + '/lga/' + stateId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }


  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
