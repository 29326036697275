import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeneralEnum } from '../constant/general-enum.enum';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PatientInformation } from '../entity/patient-information';
import { GeneralUtil } from '../util/general-util';
import { PaternalData } from '../entity/paternal-data';
import { ParentGuardianInfo } from './../entity/parent-guardian-info';
import { PatientData } from './../entity/patient-data';
import { MaternalData } from './../entity/maternal-data';

@Injectable({
  providedIn: 'root'
})

export class PatientService {
  constructor(public httpClient: HttpClient) { }

  // get patient information

  getPatientDetail(id) {

    const url = GeneralEnum.baseUrl + '/auth/patients/' + id;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  //update Patient Info
  updatePatientInfo(patientInfo: PatientInformation) {
    const patient = JSON.parse(JSON.stringify(patientInfo));
    //   convert all dates to acceptable format before sending to the server
    if (GeneralUtil.isValidString(patient.dateOfBirth)) {
      patient.dateOfBirth = GeneralUtil.convertDateTimeToDateformat(patient.dateOfBirth);
    }
    const url = GeneralEnum.baseUrl + '/auth/patients';
    return this.httpClient.put<any>(url, patient)
      .pipe(catchError(this.errorHandler));

  }

  updateParentGuardian(guardians: ParentGuardianInfo[], patientId: string) {
    const parentGuardian = JSON.parse(JSON.stringify(guardians));

    //send the patient details to the server
    const url = GeneralEnum.baseUrl + '/auth/parent-guardian/' + patientId;
    return this.httpClient.put<any>(url, parentGuardian)

    .pipe(catchError(this.errorHandler));

  }

  // update patient data
  updatePatientData(patientInfo: PatientData, id: string) {
    const patient = JSON.parse(JSON.stringify(patientInfo));
    const url = GeneralEnum.baseUrl + '/auth/patient-data/' + id;
    return this.httpClient.put<any>(url, patient)

        .pipe(catchError(this.errorHandler));
}

  // get patients
  public getPatients(searchParam: string, patientStatus: string,
    treatmentEncounterStage: string, page: number, limit: number, facility) {

    const url = GeneralEnum.baseUrl + '/auth/patients?search=' + searchParam + '&patientStatus=' + patientStatus
      + '&stage=' + treatmentEncounterStage + '&page=' + page + '&limit=' + limit + '&facilityId=' + facility;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public getAllPatients(searchParam: string, patientStatus: string,
    treatmentEncounterStage: string, page: number, limit: number, facility) {

    const url = GeneralEnum.baseUrl + '/auth/patients/all?search=' + searchParam + '&patientStatus=' + patientStatus
      + '&stage=' + treatmentEncounterStage + '&page=' + page + '&limit=' + limit + '&facilityId=' + facility;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  //Get patient treatment stage
  public getPatientTreatmentStage(patientId: number) {

    const url = GeneralEnum.baseUrl + '/auth/treatment/' + patientId;
    return this.httpClient.get<String>(url, { responseType: 'text' as 'json' })
      .pipe(catchError(this.errorHandler));
  }

  public getDashboardStats() {
    const url = GeneralEnum.baseUrl + '/auth/mobile-app-stats';
    return this.httpClient.get<any>(url)
  }

  // register patients
  public registerPatient(patientInfo: PatientInformation) {

    const patient = JSON.parse(JSON.stringify(patientInfo));

    // process some patient params b4 upload

    if (GeneralUtil.isValidString(patient.dateOfBirth)) {
      patient.dateOfBirth = GeneralUtil.reverseDateFormat(patient.dateOfBirth);
    }

    if (patient.maternalData.previousLiveBirths.length > 0) {
      patient.maternalData.previousLiveBirths.forEach(p => {
        if (GeneralUtil.isValidString(p.dateOfBirth)) {
          p.dateOfBirth = GeneralUtil.reverseDateFormat(p.dateOfBirth);
        }
      });
    }

    if (patient.parentGuardianInfo.length > 0) {
      patient.parentGuardianInfo.forEach(p => {
        if (GeneralUtil.isValidString(p.dateOfBirth)) {
          p.dateOfBirth = GeneralUtil.reverseDateFormat(p.dateOfBirth);
        }
      });
    }
    const url = GeneralEnum.baseUrl + '/auth/patients';

    return this.httpClient.post<any>(url, patient)
      .pipe(catchError(this.errorHandler));
  }

  // update Paternal Data

  updatePaternalData(paternalData: PaternalData, id: number) {
    const paternal = JSON.parse(JSON.stringify(paternalData));
    const url = GeneralEnum.baseUrl + '/auth/paternal-data/' + id;

    return this.httpClient.put<any>(url, paternal)
      .pipe(catchError(this.errorHandler));

  }

  // Maternal data update
  updateMaternalData(maternalData: MaternalData, id: string) {
    const maternal = JSON.parse(JSON.stringify(maternalData));

    // Send the patient details to the server
    const url = GeneralEnum.baseUrl + '/auth/maternal-data/' + id;

    return this.httpClient.put<any>(url, maternal)
      .pipe(catchError(this.errorHandler));
  }

  // Move patient to a new stage
  movePatientToNextStage(patientId: number){
    const body = {'patientId' : patientId}

    const url = GeneralEnum.baseUrl + '/auth/treatment-encounter-stage/change';


    return this.httpClient.patch<any>(url, body)
        .pipe(catchError(this.errorHandler));
  }

  // Deactivate patient

  changePatientStatus( patientRef: string, patientStatus: string){
    const body = {'patientRef' : patientRef,'patientStatus' : patientStatus}

    const url = GeneralEnum.baseUrl + '/auth/patients/changeStatus';

    return this.httpClient.patch<any>(url, body)
        .pipe(catchError(this.errorHandler));
  }

  // Transfer Patient
  transferPatient(patientRef: string, facilityId: number) {
    const body = { 'patientRef': patientRef, 'facilityId': facilityId }

    const url = GeneralEnum.baseUrl + '/auth/patient/transfer';
    return this.httpClient.patch<any>(url, body)
      .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
