import {MaternalLifestyleAndExposures} from './maternal-lifestyle-and-exposures';
import {MaternalIllness} from './maternal-illness';
import {PreviousLiveBirth} from './previous-live-birth';
import {Malformation} from './malformation';
import {GeneralUtil} from '../util/general-util';
import {MaternalMedication} from './maternal-medication';

export class MaternalData {
    public id: number;
    public cleftOrCraniofacialAnomaly: string;
    public describeTheCleftOrCraniofacialAnomaly: string;
    public religion: string;
    public otherReligion: string;
    public ageAtSubjectsBirth: number;
    public education: string;
    public occupation: string;
    public antenatalFacility: string;
    public mothersBirthLocation: string;
    public locationDuringPregnancy: string;
    public consanguinityOfParents: string;
    public doYouKnowRtpIfConsanguinity: string;
    public describeRtpOfConsanguineousPartners: string;
    public noOfPreviousLiveBirths: number;
    public ifDifferentFatherDescribe: string;
    public methodOfCooking: string;
    public otherMethodOfCooking: string;
    public smokingType: string;
    public otherMaternalIllness: string;

    public tobaccoAmount: string;
    public smokelessTobaccoAmount: string;
    public alcoholAmount: string;
    public diagnosticXraysNoOfTimes: string;
    public environmentalPollutionFrequency: string;
    public environmentalPollutionFrequencyOther: string;

    public maternalMedications: MaternalMedication[] = [];
    public maternalLifestyleAndExposures: MaternalLifestyleAndExposures[] = [];
    public maternalIllnesses: MaternalIllness[] = [];
    public previousLiveBirths: PreviousLiveBirth[] = [];
    public malformations: Malformation[] = [];

    constructor() {}

    public mapToMaternalData(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.id = data.id;
            this.cleftOrCraniofacialAnomaly = data.cleftOrCraniofacialAnomaly;
            this.describeTheCleftOrCraniofacialAnomaly = data.describeTheCleftOrCraniofacialAnomaly;
            this.religion = data.religion;
            this.otherReligion = data.otherReligion;
            this.ageAtSubjectsBirth = data.ageAtSubjectsBirth;
            this.education = data.education;
            this.occupation = data.occupation;
            this.antenatalFacility = data.antenatalFacility;
            this.mothersBirthLocation = data.mothersBirthLocation;
            this.locationDuringPregnancy = data.locationDuringPregnancy;
            this.consanguinityOfParents = data.consanguinityOfParents;
            this.doYouKnowRtpIfConsanguinity = data.doYouKnowRtpIfConsanguinity;
            this.describeRtpOfConsanguineousPartners = data.describeRtpOfConsanguineousPartners;
            this.noOfPreviousLiveBirths = data.noOfPreviousLiveBirths;
            this.ifDifferentFatherDescribe = data.ifDifferentFatherDescribe;
            this.methodOfCooking = data.methodOfCooking;
            this.otherMethodOfCooking = data.otherMethodOfCooking;
            this.smokingType = data.smokingType;
            this.otherMaternalIllness = data.otherMaternalIllness;

            this.tobaccoAmount = data.tobaccoAmount;
            this.smokelessTobaccoAmount = data.smokelessTobaccoAmount;
            this.alcoholAmount = data.alcoholAmount;
            this.diagnosticXraysNoOfTimes = data.diagnosticXraysNoOfTimes;
            this.environmentalPollutionFrequency = data.environmentalPollutionFrequency;
            this.environmentalPollutionFrequencyOther = data.environmentalPollutionFrequencyOther;

            // set maternal medication
            if (data.maternalMedications !== null && data.maternalMedications.length > 0) {
                const maternalMedicatnArray = data.maternalMedications;
                maternalMedicatnArray.forEach(m => {
                    const maternalMedArray = new MaternalMedication();
                    maternalMedArray.mapToMaternalMedication(m);
                    this.maternalMedications.push(maternalMedArray);
                });
            }

            // set maternal lifestyle and exposure
            if (data.maternalLifestyleAndExposures != null && data.maternalLifestyleAndExposures.length > 0) {
                const exposureArray = data.maternalLifestyleAndExposures;
                exposureArray.forEach(e => {
                   const exposure = new MaternalLifestyleAndExposures();
                   exposure.mapToMaternalLifestyleAndExposures(e);
                   this.maternalLifestyleAndExposures.push(exposure);
                });
            }

            // set maternal illness
            if (data.maternalIllnesses != null && data.maternalIllnesses.length > 0) {
                const illnessArray = data.maternalIllnesses;
                illnessArray.forEach(s => {
                   const illness = new MaternalIllness();
                   illness.mapToMaternalIllness(s);
                   this.maternalIllnesses.push(illness);
                });
            }

            // set previousLiveBirths
            if (data.previousLiveBirths != null && data.previousLiveBirths.length > 0) {
                const liveBirthArray = data.previousLiveBirths;
                liveBirthArray.forEach(s => {
                   const liveBirth = new PreviousLiveBirth();
                   liveBirth.mapToPreviousLiveBirth(s);
                   this.previousLiveBirths.push(liveBirth);
                });
            }

            // set malformations
            if (data.malformations != null && data.malformations.length > 0) {
                const malformationArray = data.malformations;
                malformationArray.forEach(m => {
                    const malformation = new Malformation();
                    malformation.mapToMalformation(m);
                    this.malformations.push(malformation);
                });
            }
        }
    }

}
