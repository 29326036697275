import { Component, OnInit } from '@angular/core';
import { PatientInformation } from 'src/app/entity/patient-information';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PatientService } from 'src/app/service/patient.service';
import { FormsService } from 'src/app/service/forms.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { CostOfCare } from './../../../../../../dto/forms/cost-of-care';


declare function successToast(msg): any;

@Component({
  selector: 'app-cost-of-care',
  templateUrl: './cost-of-care.component.html',
  styleUrls: ['./cost-of-care.component.scss']
})
export class CostOfCareComponent implements OnInit {
  public patientId: number;
  public action: string;
  public patientInformation = new PatientInformation();
  public showPreloader = false;
  public costOfCare = new CostOfCare();
  public sourceOfFinance = ['Savings', 'Borrowed', 'Contribution from family and friends', 'Sale of property',
    'Loan from bank', 'Other'];
  public otherSourceOfFinance = '';
  public otherSource = false;
  public selectedSources = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public patientService: PatientService,
    public formService: FormsService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.patientId =  Number(params.get('patientId'));
    })

    this.getPatient(this.patientId);
    this.getCostOfCareForm();
  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
        .subscribe(data => {
            this.patientInformation = new PatientInformation();
            this.patientInformation.mapToPatientInformation(data);
            return this.patientInformation;
        },
        error => this.errorHandler(error));
  }

  onSourceSelect(source) {
    // selected source
    if (source.checked) {
      if (source.value == 'Other') {
        this.otherSource = true;
      }
      if (this.selectedSources.includes(source.value) === false && source.value !== 'Other') {
        this.selectedSources.push(source.value)
      }
      // unselected source
    } else {
      this.selectedSources.forEach(c => {
        if (c == source.value) {
          if (c == source.value && source.value == 'Other') {
            this.otherSource = false;
          }
          const index = this.selectedSources.indexOf(c);
          if (index > -1) {
            this.selectedSources.splice(index, 1);
          }
        }
      })
    }
  }

  getCostOfCareForm() {
    this.formService.getCostOfCareForm(this.patientId)
      .subscribe(data => {
        if (data) {
          if (data.patientId) {
            this.action = 'update';
            this.costOfCare.mapToCostOfCare(data);
          } else {
            this.action = 'create';
            this.costOfCare = new CostOfCare();
          }
        }
      },
      error => this.errorHandler(error));
  }

  calculateTotalMedicalCost() {
    this.costOfCare.totalCostOfMedications = (this.costOfCare.totalMedicalCosts ? this.costOfCare.totalMedicalCosts : 0) +
      (this.costOfCare.totalCostOfLabTests ? this.costOfCare.totalCostOfLabTests : 0)
      + (this.costOfCare.totalImagingStudies ? this.costOfCare.totalImagingStudies : 0) +
      (this.costOfCare.totalSurgicalProcedure ? this.costOfCare.totalSurgicalProcedure : 0) +
      (this.costOfCare.totalCostOfBedStays ? this.costOfCare.totalCostOfBedStays : 0) +
      (this.costOfCare.totalCostOfICUStays ? this.costOfCare.totalCostOfICUStays : 0) +
      (this.costOfCare.totalCostOfNutritionalCare ? this.costOfCare.totalCostOfNutritionalCare : 0) +
      (this.costOfCare.otherMedicalCosts ? this.costOfCare.otherMedicalCosts : 0);
  }

  calculateTotalNonMedicalCost() {
    this.costOfCare.totalNonMedicalCosts = (this.costOfCare.costOfTransportation ? this.costOfCare.costOfTransportation : 0) +
      (this.costOfCare.costOfUpkeep ? this.costOfCare.costOfUpkeep : 0);
  }

  submitCostOfCare() {
    this.showPreloader = true;
    this.costOfCare.patientId = this.patientId;


    if (this.action === 'create') {
      this.costOfCare.sourcesOfFinance = [...this.selectedSources, this.otherSourceOfFinance];
      this.formService.registerCostOfCareForm(this.costOfCare)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Form Registered Successfully');
        setTimeout(() => {
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, 500)
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
      })
    }

    if (this.action === 'update') {
      this.costOfCare.sourcesOfFinance = [];
      this.costOfCare.sourcesOfFinance = [...this.selectedSources, this.otherSourceOfFinance];
      this.formService.updateCostOfCareForm(this.costOfCare)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Form Updated Successfully');
        setTimeout(() => {
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, 500)
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
      })
    }
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
