import { GeneralUtil } from './../../util/general-util';
export class RehabilitationFormDto {
  public clinicianComment: string;
  public clinicianName: string;
  public dateRehabEnded: string;
  public dateRehabStarted: string;
  public homeRehab: string;
  public hospitalRehab: string;
  public outcome: string;
  public rehabNeeded: string;
  public rehabReason: string;
  public rehabType: string;
  public patientFirstName: string;
  public patientLastName: string;
  public patientRef: string;
  public createdAt: string;

  public mapToRehabilitation(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.clinicianComment = data.clinicianComment;
      this.patientRef = data.patientRef;
      this.clinicianName = data.clinicianName;
      this.dateRehabEnded =  GeneralUtil.reverseDateFormat(data.dateRehabEnded);
      this.dateRehabStarted =  GeneralUtil.reverseDateFormat(data.dateRehabStarted);
      this.homeRehab = data.homeRehab;
      this.hospitalRehab = data.hospitalRehab;
      this.outcome = data.outcome;
      this.rehabNeeded = data.rehabNeeded;
      this.rehabReason = data.rehabReason;
      this.rehabType = data.rehabType;
      this.patientLastName = data.patientLastName;
      this.patientFirstName = data.patientFirstName;
      this.createdAt = data.createdAt;
    }
  }
}
