import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientInformation } from 'src/app/entity/patient-information';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';

declare function successToast(msg: string): any;

@Component({
  selector: 'app-page-five-update',
  templateUrl: './page-five-update.component.html',
  styleUrls: ['./page-five-update.component.scss']
})
export class PageFiveUpdateComponent implements OnInit {
  // variables
  public showPreloader = false;
  public patientId: string;
  public patientInformation: PatientInformation;


  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public patientService: PatientService
  ) { }

  ngOnInit(): void {
    // get patient Id
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    setTimeout(() => {
      this.getPatient(this.patientId);
    }, 500)
  }
  getPatient(patientId) {
    this.patientService.getPatientDetail(patientId)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);

        return this.patientInformation;
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  updatePaternalData() {
    this.showPreloader = true;
    //update information

    this.patientService.updatePaternalData(this.patientInformation.paternalData, this.patientInformation.id)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Paternal data updated successfully');
        // route back to details page
        this.router.navigate(['/report/view-patient-information/' + this.patientInformation.id]);

      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
    return;
  }


  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
