import { GeneralUtil } from '../../util/general-util';
import { SurgicalTreatmentType } from './surgical-treatment-type';

export class SurgicalTreatmentFormDto {
    public patientRef: string;
    public firstName: string;
    public lastName: string;
    public clinicianComment: string;
    public clinicianName: string;
    public anaesthesiaType: string;
    public createdAt: string;
    public surgicalTreatmentTypes: SurgicalTreatmentType[] = [];

    constructor() { }

    public mapToSurgicalTreatmentForm(data: any) {
      if (GeneralUtil.isValidJSON(data)) {
        this.patientRef = data.patientRef;
        this.firstName = data.patientFirstName;
        this.lastName = data.patientLastName;
        this.anaesthesiaType = data.anaesthesiaType;
        this.clinicianComment = data.clinicianComment;
        this.clinicianName = data.clinicianName;
        this.createdAt = data.createdAt;

            // set surgicalTreatmentTypes..
        if (data.surgicalTreatmentTypes != null && data.surgicalTreatmentTypes.length > 0) {
            const surgicalTreatmentArray = data.surgicalTreatmentTypes;
            surgicalTreatmentArray.forEach(s => {
              const surgicalTreatment = new SurgicalTreatmentType();
              surgicalTreatment.mapToSurgicalTreatmentType(s);
              this.surgicalTreatmentTypes.push(surgicalTreatment);
              });
            }
        }
    }
}

