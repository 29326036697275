import { SurgicalTreatmentFormDto } from './surgical-treatment-form-dto';
import { RehabilitationFormDto } from './rehabilitation-form-dto';
import { MortalityFormDto } from './mortality-form-dto';
import { SpeechAssessmentFormDto } from 'src/app/dto/forms/speech-assessment-form-dto';
import { SpeechTreatmentFormDto } from './speech-treatment-form-dto';
import { ComplicationFormDto } from './complication-form-dto';
 import { OrthodonticTreatmentFormDto } from './orthodontic-treatment-form-dto';
 import { NutritionFormDto } from './nutritionFormDto';

export class FormsDto {
    public code: string;
    public patientId: number;
    public surgicalTreatmentFormDto: SurgicalTreatmentFormDto;
    public speechAssessmentFormDto: SpeechAssessmentFormDto;
    public speechTreatmentFormDto: SpeechTreatmentFormDto;
    public complicationFormDto: ComplicationFormDto;
    public orthodonticTreatmentFormDto: OrthodonticTreatmentFormDto;
    public rehabilitationFormDto: RehabilitationFormDto;
    public mortalityFormDto: MortalityFormDto;
    public nutritionFormDto: NutritionFormDto;

    constructor(){ }

}
