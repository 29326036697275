import {Component, OnInit, ViewChild} from '@angular/core';
import {MortalityFormsComponent} from '../form-module/mortality-forms/mortality-forms.component';
import {SpeechTreatmentFormsComponent} from '../form-module/speech-treatment-forms/speech-treatment-forms.component';
import {ComplicationFormsComponent} from '../form-module/complication-forms/complication-forms.component';
import {SurgicalTreatmentFormsComponent} from '../form-module/surgical-treatment-forms/surgical-treatment-forms.component';
import {RehabilitationFormsComponent} from '../form-module/rehabilitation-forms/rehabilitation-forms.component';
import {SpeechAssessmentFormsComponent} from '../form-module/speech-assessment-forms/speech-assessment-forms.component';
import {OrthodonticTreatmentFormsComponent} from '../form-module/orthodontic-treatment-forms/orthodontic-treatment-forms.component';
import {NutritionFormsComponent} from '../form-module/nutrition-forms/nutrition-forms.component';
import { FormCode } from 'src/app/constant/form-code-enum';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {GeneralUtil} from '../../../../../../util/general-util';

declare function failureToast(msg): any;

@Component({
  selector: 'app-form-update',
  templateUrl: './form-update.component.html',
  styleUrls: ['./form-update.component.scss']
})
export class FormUpdateComponent implements OnInit {

  // variables
  public formCode: string;
  public FormCode = FormCode;
  public patientId: number;


  // Form modules
  @ViewChild(MortalityFormsComponent) private mortalityForm: MortalityFormsComponent;
  @ViewChild(SpeechTreatmentFormsComponent) private speechTreatmentForm: SpeechTreatmentFormsComponent;
  @ViewChild(ComplicationFormsComponent) private complicationForm: ComplicationFormsComponent;
  @ViewChild(SurgicalTreatmentFormsComponent) private surgicalTreatmentForm: SurgicalTreatmentFormsComponent;
  @ViewChild(RehabilitationFormsComponent) private rehabilitationForm: RehabilitationFormsComponent;
  @ViewChild(SpeechAssessmentFormsComponent) private speechAssessmentForm: SpeechAssessmentFormsComponent;
  @ViewChild(OrthodonticTreatmentFormsComponent) private orthodonticTreatmentForm: OrthodonticTreatmentFormsComponent;
  @ViewChild(NutritionFormsComponent) private nutritionForm: NutritionFormsComponent;


  constructor(public route: ActivatedRoute) { }

  ngOnInit() {
    // get form code
    this.route.paramMap.subscribe((params: ParamMap) => {
      const formCode = params.get('formCode');
      const patientId = params.get('patientId');
      const formRef = params.get('formRef');

      if (GeneralUtil.isValidString(formCode) && GeneralUtil.isValidString(patientId)) {

        this.formCode = formCode;
        this.patientId = Number(patientId);
        const action = 'update';

        // pass the formCode, action and patientId to the appropriate Form
        switch (this.formCode) {

          case FormCode.MORTALITY_CODE:
            setTimeout(() => {
              this.mortalityForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          case FormCode.SPEECH_TREATMENT_CODE:
            setTimeout(() => {
              this.speechTreatmentForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          case FormCode.COMPLICATION_CODE:
            setTimeout(() => {
              this.complicationForm.setFormDetails(this.formCode, this.patientId, formRef, action)
            }, 500);
            break;

          case FormCode.SPEECH_ASSESSMENT_CODE:
            setTimeout(() => {
              this.speechAssessmentForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          case FormCode.NUTRITION_CODE:
            setTimeout(() => {
              this.nutritionForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          case FormCode.REHABILITATION_CODE:
            setTimeout(() => {
              this.rehabilitationForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;
          case FormCode.NUTRITION_CODE:
            setTimeout(() => {
              this.nutritionForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          case FormCode.NUTRITION_CODE_II:
            setTimeout(() => {
              this.nutritionForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          case FormCode.ORTHODONTIC_TREATMENT_CODE:
            setTimeout(() => {
              this.orthodonticTreatmentForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          case FormCode.SURGICAL_TREATMENT_CODE:
            setTimeout(() => {
              this.surgicalTreatmentForm.setFormDetails(this.formCode, this.patientId, formRef, action);
            }, 500);
            break;

          default:
            failureToast('Form error');
        }

      }
    });
  }

}
