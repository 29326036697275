import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientInformation } from 'src/app/entity/patient-information';
import { FormsService } from 'src/app/service/forms.service';
import { PatientService } from 'src/app/service/patient.service';
import { ComplicationFormDto } from './../../../../../../../dto/forms/complication-form-dto';
import { GeneralUtil } from 'src/app/util/general-util';
import { SurgicalTreatmentFormDto } from 'src/app/dto/forms/surgical-treatment-form-dto';
import { FormCode } from 'src/app/constant/form-code-enum';

@Component({
  selector: 'app-complication-form-view',
  templateUrl: './complication-form-view.component.html',
  styleUrls: ['./complication-form-view.component.scss']
})
export class ComplicationFormViewComponent implements OnInit {
  // variables
  public formCode;
  public patientId;
  public formRef: string;
  public patientInformation: PatientInformation;
  public complicationform: ComplicationFormDto
  public surgicalTreatmentForms: SurgicalTreatmentFormDto[] = [];

  constructor(public patientService: PatientService,
    public formService: FormsService,
    public router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);
      this.getSurgicalFormsType();
    }, 1000)

    setTimeout(() => {
      this.getSingleForm();
    }, 500)
  }

  getPatient(id) {
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => this.errorHandler(error));
  }

  getSingleForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {

        this.complicationform = new ComplicationFormDto();
        this.complicationform.mapToComplication(data);
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  getSurgicalFormsType() {
    this.formService.getSurgicalForms(FormCode.SURGICAL_TREATMENT_CODE, this.patientId)
      .subscribe(data => {
        this.surgicalTreatmentForms = [];
        const surgeryArray = data;
        surgeryArray.forEach(s => {
          const surgeryTreatment = new SurgicalTreatmentFormDto();
          surgeryTreatment.mapToSurgicalTreatmentForm(s);
          this.surgicalTreatmentForms.push(surgeryTreatment);
        })
        return this.surgicalTreatmentForms;
      },
        error1 => {

          this.errorHandler(error1);
        });
  }

  setFormDetails(formCode: string, patientId: number, formRef: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.formRef = formRef;
  }

  removeUnderscore(underscoreWord) {
    return GeneralUtil.removeUnderscore(underscoreWord);
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
