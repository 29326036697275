import { Component, OnInit } from '@angular/core';
import { PatientInformation } from 'src/app/entity/patient-information';
import { Router } from '@angular/router';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { SurgicalTreatmentFormDto } from '../../../../../../../dto/forms/surgical-treatment-form-dto';
import { SurgicalTreatmentType } from '../../../../../../../dto/forms/surgical-treatment-type';
import { FormsDto } from '../../../../../../../dto/forms/forms-dto';
import { FormsService } from '../../../../../../../service/forms.service';

declare function successToast(msg: string): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-surgical-treatment-forms',
  templateUrl: './surgical-treatment-forms.component.html',
  styleUrls: ['./surgical-treatment-forms.component.scss']
})
export class SurgicalTreatmentFormsComponent implements OnInit {
  // variables
  public action: string;
  public formRef: string;
  public formCode: string;
  public patientId: number;
  public patientInformation: PatientInformation;
  public today = new Date().toJSON().split('T')[0];
  public formsDto = new FormsDto();
  public surgicalTreatmentForm = new SurgicalTreatmentFormDto();
  public generalSurgicalDto = new SurgicalTreatmentType();
  public partnerSpecificDto = new SurgicalTreatmentType();
  public generalSurgeryList: SurgicalTreatmentType[] = [];
  public partnerSurgeryList: SurgicalTreatmentType[] = [];
  public othersGeneral = true;
  public othersSpecific = true;
  public generalSurgeries: string; // Temporary holding string
  public generalTechnique: string; // Temporary holding string
  public partnerRepair: string; // Temporary holding string
  public submitButton = false;

  constructor(
        public router: Router,
        public patientService: PatientService,
        public formService: FormsService
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.getPatient(this.patientId);

      if (this.action === 'update') {
        this.getSingleSurgicalForm();
      }

    }, 500);

  }

  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
        .subscribe(data => {
            this.patientInformation = new PatientInformation();
            this.patientInformation.mapToPatientInformation(data);
            return this.patientInformation;
        },
            error => this.errorHandler(error));

  }

  convertDateTimeToDateformat(date) {
    return GeneralUtil.convertDateTimeToDateformat(date);
  }

  /* Add general surgery */
  addGeneralTreatment(surgicalForm) {
    this.generalSurgicalDto.type = 'GENERAL_TREATMENT_TYPE';
    this.generalSurgicalDto.dateOfProcedure = this.convertDateTimeToDateformat(this.generalSurgicalDto.dateOfProcedure)
    this.generalSurgeryList.push(this.generalSurgicalDto);

    this.generalSurgicalDto = new SurgicalTreatmentType();
    surgicalForm.resetForm();         // On submit reset form
    this.generalTechnique = '';       // clear the variable
    this.othersGeneral = true;        // Toggle the select list on for new selections
    successToast('General surgery added');
  }

  /* Remove general surgery */
  removeGeneralTreatment(surgery: SurgicalTreatmentType) {
    this.generalSurgeryList = this.generalSurgeryList.filter(s =>
      s.repairType !== surgery.repairType || s.technique !== surgery.technique
      );
    successToast('General surgery removed');
  }

  checkGeneralRepairType() {
    if (this.generalSurgeries === 'OtherSurgery') {
      this.othersGeneral = false;
      this.generalSurgicalDto.repairType = ''; // clear the previously selected repair type
      this.generalSurgicalDto.technique = ''; // clear the previously selected technique
    } else {
      this.generalSurgicalDto.repairType = this.generalSurgeries;
      this.othersGeneral = true;
    }
  }

    /* On change, check the technique selected */
    checkTechnique() {
      if (this.generalTechnique === 'Other-General') { // If others is selected
        this.othersGeneral = false;    // Hide the select list
        this.generalSurgicalDto.technique = ''; // clear previously selected technique

      } else {
        this.generalSurgicalDto.technique = this.generalTechnique; // Else assign the selected technique
        this.othersGeneral = true;
      }
    }

    /* Add partner specific */
    addPartnerSpecificTreatment(surgicalForm) {
      this.partnerSpecificDto.type = 'PARTNER_SPECIFIC_TREATMENT_TYPE';
      this.partnerSpecificDto.dateOfProcedure = this.convertDateTimeToDateformat(this.partnerSpecificDto.dateOfProcedure);
      this.partnerSurgeryList.push(this.partnerSpecificDto);

      this.partnerSpecificDto = new SurgicalTreatmentType();
      surgicalForm.resetForm();
      this.othersSpecific = true;
      this.partnerRepair = '';
      successToast('Partner specific surgery added');

    }

    /* Remove Partner Specific */
    removePartnerTreatment(surgery: SurgicalTreatmentType) {
      this.partnerSurgeryList = this.partnerSurgeryList.filter(s =>
        s.repairType !== surgery.repairType || s.technique !== surgery.technique
        );
      successToast('Partner Specific surgery removed');
    }

    checkPartnerRepairType() {
      if (this.partnerRepair === 'Other-specific') {
        this.othersSpecific = false;
        this.partnerSpecificDto.repairType = ''; // clear previously selected repairType
      } else {
        this.partnerSpecificDto.repairType = this.partnerRepair;
        this.othersGeneral = true;
      }
    }


  getSingleSurgicalForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
        this.surgicalTreatmentForm = new SurgicalTreatmentFormDto();
        this.surgicalTreatmentForm.mapToSurgicalTreatmentForm(data);

        this.generalSurgeryList = this.surgicalTreatmentForm.surgicalTreatmentTypes.filter(s => {
          return s.type === 'GENERAL_TREATMENT_TYPE';
        })

        this.partnerSurgeryList = this.surgicalTreatmentForm.surgicalTreatmentTypes.filter(s => {
          return s.type === 'PARTNER_SPECIFIC_TREATMENT_TYPE';
        })

      },
        error1 => {
          this.errorHandler(error1);
        })
  }

    submitForm() {
      this.submitButton = true;
      this.formsDto.code = this.formCode;
      this.formsDto.surgicalTreatmentFormDto = this.surgicalTreatmentForm;

       /* Registration */
      if (this.action === 'registration') {
        this.formsDto.patientId = this.patientId;
        this.surgicalTreatmentForm.surgicalTreatmentTypes.push(...this.generalSurgeryList, ...this.partnerSurgeryList);
        // submit service

        this.formService.formRegistration(this.formsDto)
          .subscribe(data => {
            this.submitButton = false;
            successToast('Form submitted successfully');
            this.router.navigate(['/report/view-patient-information/' + this.patientId]);
            }, error1 => {
              this.submitButton = false;
              this.errorHandler(error1);
            });
        return;
      }

      /* Update */
      if (this.action === 'update') {
        this.surgicalTreatmentForm.surgicalTreatmentTypes = [];
        this.surgicalTreatmentForm.surgicalTreatmentTypes.push(...this.generalSurgeryList, ...this.partnerSurgeryList);

        // update service
        this.formService.formUpdate(this.formsDto, this.formRef)
          .subscribe(data => {
            this.submitButton = false;
            successToast('Form updated successfully');
            this.router.navigate(['/report/view-patient-information/' + this.patientId]);
          }, error1 => {
            this.submitButton = false;
            this.errorHandler(error1);
          });
        return;
      }

    }

    // Error handling
    errorHandler(error) {
      return GeneralUtil.errorHandler(error)
    }
}
