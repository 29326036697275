import {GeneralUtil} from '../util/general-util';

export class PierreRobinAdditionalFeature {
    public name: string;

    constructor() {}

    public mapToPierreRobinAdditionalFeature(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.name = data.name;
        }
    }
}
