export enum FormCode{
  COMPLICATION_CODE = 'C001',
  MORTALITY_CODE = 'M001',
  REHABILITATION_CODE = 'R001',
  SPEECH_ASSESSMENT_CODE = 'S001',
  NUTRITION_CODE = 'N001',
  NUTRITION_CODE_II = 'N002',
  SURGICAL_TREATMENT_CODE = 'S002',
  ORTHODONTIC_TREATMENT_CODE = 'T002',
  SPEECH_TREATMENT_CODE = 'P002',
  

}