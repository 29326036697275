import { Facility } from './facility';
import { GeneralUtil } from '../util/general-util';

export class User {
  public id: number;
  public username: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public designation: string;
  public specialty: string;
  public status: string;
  public roleId: number;
  public roleName: string;
  public facilityIds: Facility[] = []

  public mapToUser(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.username = data.username;
      this.email = data.email;
      this.phone = data.phone;
      this.designation = data.designation;
      this.specialty = data.specialty;
      this.status = data.status;
      this.roleId = data.role.id;
      this.roleName = data.role.name;
      this.facilityIds = data.facilities;
    }
  }
}
