import {GeneralUtil} from '../util/general-util';

export class Permission {
    public id: number;
    public name: string;
    public description: string;
    // transient
    public selected: boolean;

    constructor() {}

    public mapToPermission(data: any) {

        if (GeneralUtil.isValidJSON(data)) {
            this.id = data.id;
            this.name = data.name;
            this.description = data.description;
        }
    }
}

