import {GeneralUtil} from '../util/general-util';

export class MaternalIllness {
    public illness: string;
    public periodOfIllness: string;
    public otherMaternalIllnessWithDetails: string;

    constructor() {}

    public mapToMaternalIllness(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.illness = data.illness;
            this.periodOfIllness = data.periodOfIllness;
            this.otherMaternalIllnessWithDetails = data.otherMaternalIllnessWithDetails;
        }
    }

}
