import { Component, OnInit } from '@angular/core';
import { GeneralUtil } from '../../../../util/general-util';
import { FormCode } from '../../../../constant/form-code-enum';
import { FormsService } from '../../../../service/forms.service';
import { SurgicalTreatmentFormDto } from './../../../../dto/forms/surgical-treatment-form-dto';
import { FacilityService } from '../../../../service/facility.service';
import { Facility } from '../../../../entity/facility';
import { PaginationDataDto } from '../../../../dto/pagination-data-dto';
import { GeneralEnum } from '../../../../constant/general-enum.enum';
import { FilterDto } from './../../../../dto/filter-dto';

@Component({
  selector: 'app-surgical-treatment-form',
  templateUrl: './surgical-treatment-form.component.html',
  styleUrls: ['./surgical-treatment-form.component.scss']
})
export class SurgicalTreatmentFormComponent implements OnInit {
  public showPreloader = false;
  public formCode = FormCode;
  public facilities: Facility[] = [];
  public surgicalTreatmentForms = [];
  public paginationData = new PaginationDataDto();  // pagination object
  public filterDto = new FilterDto();

  constructor(private formService: FormsService, public facilityService: FacilityService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getSurgicalTreatmentForms();
  }

  filterForm() {
    this.initializePaginationData();

    this.getSurgicalTreatmentForms();
  }

  getSurgicalTreatmentForms() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.formService.getForms(this.formCode.SURGICAL_TREATMENT_CODE, this.filterDto.facility, (currentPage - 1),
      defaultLimit, this.filterDto.search)
      .subscribe(data => {
        this.showPreloader = false;
        this.surgicalTreatmentForms = [];
        this.paginationData.totalSize = data.total;
        const surgicalTreatmentFormArray = data.entities;
        if (surgicalTreatmentFormArray !== null && surgicalTreatmentFormArray.length > 0) {
          surgicalTreatmentFormArray.forEach(s => {
            const surgicalTreatmentForm = new SurgicalTreatmentFormDto();
            surgicalTreatmentForm.mapToSurgicalTreatmentForm(s);
            this.surgicalTreatmentForms.push(surgicalTreatmentForm);
          })
        }
    },
    error1 => {
      this.errorHandler(error1);
      this.showPreloader = false;
    })
  }

  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

    /*********PAGINATION FUNCTIONS***************/
    initializePaginationData() {
      this.paginationData.currentPage = 1;
      this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
      this.paginationData.totalSize = 100;
    }

  removeUnderScore(data: string) {
    return GeneralUtil.removeUnderscore(data);
  }

  export2csv() {
    return GeneralUtil.export2csv('surgical-treatment-report.csv');
  }

     // Error handling
     errorHandler(error) {
      return GeneralUtil.errorHandler(error)
     }

}
