import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.scss']
})
export class PatientRegistrationComponent implements OnInit {
  @ViewChild('next') next: ElementRef;

  public active;
  public disabled = true;
  public id = 1;

  constructor() { }

  ngOnInit(): void {

  }



  /* This takes care of moving to next page */
  switchToNextPage(event) {
    switch (event) {
      case 'one':
        this.id = 2;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;

      case 'two':
        this.id = 3;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;

      case 'three':
        this.id = 4;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;

      case 'four':
        this.id = 5;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;
    }
  }

  /* This takes care of moving to previous page */
  switchToPreviousPage(event) {
    switch (event) {
      case 'one':
        this.id = 1;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;

      case 'two':
        this.id = 2;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;

      case 'three':
        this.id = 3;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;

      case 'four':
        this.id = 4;
        setTimeout(() => { this.next.nativeElement.click(); }, 500)
        break;
    }
  }

}
