import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList} from '@angular/core';
import { UserService } from './../../../service/user.service';
import { FacilityService } from './../../../service/facility.service';
import { User } from './../../../entity/user';
import { GeneralUtil } from 'src/app/util/general-util';
import {Router} from '@angular/router';
import { Facility } from 'src/app/entity/facility';
import { UserDto } from './../../../dto/user-dto';
import { Role } from './../../../entity/role';
import { PaginationDataDto } from '../../../dto/pagination-data-dto';
import {GeneralEnum} from '../../../constant/general-enum.enum';


declare function successToast(msg): any;
declare function failureToast(msg): any;
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  public users = [];
  public facilities: Facility[] = [];
  public selectedFacilities = [];
  public userDto = new UserDto();
  public user = new User();
  public showPreloader = false;
  public roles = [];
  @ViewChild('close') close: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  public paginationData = new PaginationDataDto();  // pagination object
  public search = '';

  constructor(public userService: UserService,
              public facilityService: FacilityService,
              public route: Router) { }

  ngOnInit() {
    this.initializePaginationData();
    this.getAllUsers();
    this.getFacilities();
    this.getRoles()
  }

  getFacilities() {
    this.facilities = [];
    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        const facilityArray = data.entities;
        if (facilityArray.length > 0) {
          facilityArray.forEach(f => {
            const fac = new Facility();
            fac.mapToFacility(f);
            this.facilities.push(fac);
          });
        }
    }, error1 => {
      this.errorHandler(error1);
    })
  }

  getRoles() {
    this.userService.getUserRoles()
      .subscribe(data => {
        const roleArray = data;
        if (roleArray.length > 0) {
          roleArray.forEach(r => {
            const role = new Role();
            role.mapToRole(r);
            this.roles.push(role);
          });
        }
    }, error1 => {
      this.errorHandler(error1);
    })
  }

  filterUsers() {
    this.initializePaginationData();
    this.getAllUsers();
  }

  getAllUsers() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.userService.getAllUsers((currentPage - 1), defaultLimit, this.search )
      .subscribe(data => {
        this.showPreloader = false;
        const userArray = data.entities;
        this.users = [];
        this.paginationData.totalSize = data.total;

        if (userArray.length > 0) {
          userArray.forEach(u => {
            const user = new User();
            user.mapToUser(u);
            this.users.push(user)
          })
        }
      }, error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      })
  }

  getSingleUser(userId: number) {
    this.selectedFacilities = [];

    this.userService.getUserById(userId)
      .subscribe(data => {
        this.user = new User();
        setTimeout(() => {
          this.user.mapToUser(data);
          this.user.facilityIds.forEach(f => {
            if (!this.selectedFacilities.includes(f.id)) {
              this.selectedFacilities.push(f.id)
            }
          });
        }, 1000)
    },  error1 => {
      this.errorHandler(error1);
    })
  }

  getUserToBeUpdated(userId: number) {
    this.selectedFacilities = [];

    this.userService.getUserById(userId)
      .subscribe(data => {
        this.user.mapToUser(data);
        this.user.facilityIds.forEach(f => {
          if (!this.selectedFacilities.includes(f.id)) {
            this.selectedFacilities.push(f.id)
          }
        });
        this.facilities.forEach(facility => this.user.facilityIds.forEach(f => {
          if (f.id === facility.id) {
            facility.selected = true;
          }
        }))
      },  error1 => {
        this.errorHandler(error1);
      })
  }

  toggleUserStatus(userId: number) {
    this.showPreloader = true;
    this.userService.toggleUserStatus(userId)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Status toggled successfully');
        this.getAllUsers();
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        })
  }

  onCheckboxChange(event) {
    // selected checkbox
    if (event.checked) {
      if (this.userDto.facilityIds.includes(Number(event.value)) === false) {
        this.userDto.facilityIds.push(Number(event.value))
      }
      // unselected checkbox
    } else {
      this.userDto.facilityIds.forEach(f => {
        if (f == event.value) {
          const index = this.userDto.facilityIds.indexOf(f);
          if (index > -1) {
            this.userDto.facilityIds.splice(index, 1);
          }
        }
      });
    }
  }

  onCheckChange(event, facility) {
    // selected checkboxes
    if (event.checked) {
      facility.selected = true;
      if (this.selectedFacilities.includes(Number(event.value)) === false) {
        this.selectedFacilities.push(Number(event.value))
      }
      // unselected checkboxes
    } else {
      facility.selected = false;
      this.selectedFacilities.forEach(f => {
        if (f == event.value) {
          const index = this.selectedFacilities.indexOf(f);
          if (index > -1) {
            this.selectedFacilities.splice(index, 1)
          }
        }
      })
    }


  }

  registerUser(userForm) {
    if (this.userDto.facilityIds.length < 1) {
      failureToast('At least one facility is required');
    } else {
      this.showPreloader = true;
      this.userService.registerUser(this.userDto)
        .subscribe(data => {
          successToast('User registered successfully');
          setTimeout(() => {
            this.showPreloader = false;
            this.userDto = new UserDto();
            this.close.nativeElement.click();
            this.checkboxes.forEach((element) => {
              element.nativeElement.checked = false;
            });
            this.getAllUsers();
          }, 1000)
      },  error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
    }

  }

  updateUser() {
    this.user.facilityIds = this.selectedFacilities;

    if (this.user.facilityIds.length < 1) {
      failureToast('At least one facility is required');
    } else {
      this.showPreloader = true;
      this.userService.updateUser(this.user, this.user.id)
        .subscribe(data => {
          successToast('User updated successfully');
          setTimeout(() => {
            this.showPreloader = false;
            this.closeModal.nativeElement.click();
            this.getAllUsers();
          }, 1000)

    },  error1 => {
      this.showPreloader = false;
      this.errorHandler(error1);
    })
    }
  }

    /*********PAGINATION FUNCTIONS***************/
    initializePaginationData() {
      this.paginationData.currentPage = 1;
      this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
      this.paginationData.totalSize = 100;
    }

   // Error handling
  errorHandler(error) {
      return GeneralUtil.errorHandler(error)
    }

}
