export class SpeechTreatmentFormDto {
  public clinicianName: string;
  public comments: string;
  public recommendation: string;
  public status: string;
  public treatment: string;

  public mapToSpeechTreatmentForm(data) {
    this.clinicianName = data.clinicianName;  
    this.comments = data.comments;
    this.recommendation = data.recommendation;
    this.status = data.status;
    this.treatment = data.treatment;
  }
}


