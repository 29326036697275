import { Component, OnInit } from '@angular/core';
import { PatientInformation } from 'src/app/entity/patient-information';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { FormsService } from './../../../../../../../service/forms.service';
import { SurgicalTreatmentFormDto } from './../../../../../../../dto/forms/surgical-treatment-form-dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-surgical-treatment-view',
  templateUrl: './surgical-treatment-view.component.html',
  styleUrls: ['./surgical-treatment-view.component.scss']
})
export class SurgicalTreatmentViewComponent implements OnInit {
  public formCode;
  public patientId;
  public formRef: string;
  public patientInformation: PatientInformation;
  public formDetails: SurgicalTreatmentFormDto;

  constructor(
    public patientService: PatientService,
    public formService: FormsService,
    public router: Router
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);

      this.getSingleForm();
    }, 500)

  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => this.errorHandler(error));
  }

  getSingleForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {

        this.formDetails = new SurgicalTreatmentFormDto();
        this.formDetails.mapToSurgicalTreatmentForm(data);
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  removeUnderscore(underscoreWord) {
    return GeneralUtil.removeUnderscore(underscoreWord);
  }


  setFormDetails(formCode: string, patientId: number, formRef: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.formRef = formRef;
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
