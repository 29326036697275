import { GeneralUtil } from "src/app/util/general-util";

export class MortalityFormDto {
  public clinicianName: string;
  public autopsyPerformed: string;
  public dateOfDeath: string;
  public deathOccurred: string;
  public primaryCauseOfDeath: string;
  public secondaryCauseOfDeath: string;
  public patientFirstName: string;
  public patientLastName: string;
  public patientRef: string;
  public createdAt: string;

  public mapToMortalityForm(data: MortalityFormDto) {
    if (GeneralUtil.isValidJSON(data)) {
      this.patientRef = data.patientRef;
      this.clinicianName = data.clinicianName;
      this.patientFirstName = data.patientFirstName;
      this.patientLastName = data.patientLastName;
      this.autopsyPerformed = data.autopsyPerformed;
      this.dateOfDeath = data.dateOfDeath;
      this.deathOccurred = data.deathOccurred;
      this.primaryCauseOfDeath = data.primaryCauseOfDeath;
      this.secondaryCauseOfDeath = data.secondaryCauseOfDeath;
      this.createdAt = data.createdAt;
    }
  }
}
