import { GeneralUtil } from '../util/general-util';

export class Address {
    public houseNoAddress: string;
    public postCode: string;
    public city: string;
    public lga: string;
    public state: string;
    public country: string;
    public addressType = 'CONTACT_ADDRESS';
    public id: number;

    constructor() { }

    public mapToAddress(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.houseNoAddress = data.houseNoAddress;
            this.postCode = data.postCode;
            this.city = data.city;
            this.lga = data.lga;
            this.state = data.state;
            this.country = data.country;
            this.id = data.id;
        }
    }

}
