import { AuthService } from './auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeneralEnum } from '../constant/general-enum.enum';
import { throwError } from 'rxjs';
import { GeneralUtil } from '../util/general-util';
import { catchError } from 'rxjs/operators';
import { UserDto } from './../dto/user-dto';
import { User } from './../entity/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public httpClient: HttpClient,
              public authService: AuthService) { }

  public getUser() {
    const userEmail = this.authService.getUserDetailItem(AuthService.userDetailsEmail);
    const url = GeneralEnum.baseUrl + '/auth/' + userEmail + '/users';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public getAllUsers(page, limit, search) {
    const url = GeneralEnum.baseUrl + '/auth/health-admin?limit=' + limit + '&page=' + page + '&emailOrUsernameOrPhone=' + search;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public getUserById(userId: number) {
    const url = GeneralEnum.baseUrl + '/auth/user/' + userId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public getUserRoles() {
    const url = GeneralEnum.baseUrl + '/auth/roles';

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public toggleUserStatus(userId: number) {
    const url = GeneralEnum.baseUrl + '/auth/user/changeStatus/' + userId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public registerUser(user: UserDto) {
    const url = GeneralEnum.baseUrl + '/auth/user/register';

    return this.httpClient.post<any>(url, user)
    .pipe(catchError(this.errorHandler));
  }

  public updateUser(user: User, userId: number) {
    const url = GeneralEnum.baseUrl + '/auth/user/update/' + userId;

    return this.httpClient.put<any>(url, user)
    .pipe(catchError(this.errorHandler));
  }
   // Error Handler
   errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
