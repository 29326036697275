import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { Facility } from 'src/app/entity/facility';
import { FacilityUpdate } from 'src/app/entity/facility-update';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(public httpClient: HttpClient) { }

  public getAllFacilities(page, limit, activeStatus, searchParam, smilePartner) {
    const url = GeneralEnum.baseUrl + '/auth/health-providers?limit=' + limit +
      '&page=' + page + '&active=' + activeStatus + '&search=' + searchParam + '&smilePartnerId=' + smilePartner;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public registerFacility(facility: Facility) {
    const url = GeneralEnum.baseUrl + '/auth/facility/register';

    return this.httpClient.post<any>(url, facility)
      .pipe(catchError(this.errorHandler))
  }

  public retrieveFacility(facilityId: number) {
    const url = GeneralEnum.baseUrl + '/auth/facility/retrieve/' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public updateFacility(facility: FacilityUpdate) {
    const url = GeneralEnum.baseUrl + '/auth/facility/update/';

    return this.httpClient.put<any>(url, facility)
    .pipe(catchError(this.errorHandler));
  }

  public toggleStatus(facilityId: number) {
    const url = GeneralEnum.baseUrl + '/auth/facility/toggleActive/' + facilityId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  // Error Handler
  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }

}
