import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormCode } from 'src/app/constant/form-code-enum';
import { FormsDto } from 'src/app/dto/forms/forms-dto';
import { MortalityFormDto } from 'src/app/dto/forms/mortality-form-dto';
import { SurgicalTreatmentFormDto } from 'src/app/dto/forms/surgical-treatment-form-dto';
import { PatientInformation } from 'src/app/entity/patient-information';
import { AuthService } from 'src/app/service/auth/auth.service';
import { FormsService } from 'src/app/service/forms.service';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';

declare function successToast(msg): any;

@Component({
  selector: 'app-mortality-forms',
  templateUrl: './mortality-forms.component.html',
  styleUrls: ['./mortality-forms.component.scss']
})
export class MortalityFormsComponent implements OnInit {

  // variables
  public formCode: string;
  public formRef: string;
  public action: string;
  public patientId: number;
  public showPreloader = false;
  public showFormPreloader = false;
  public surgicalTreatmentForms: SurgicalTreatmentFormDto[] = [];
  public patientInformation: PatientInformation;
  public mortalityForm: MortalityFormDto;
  public today = new Date().toJSON().split('T')[0] + 'T' + new Date().getHours() + ':' + new Date().getMinutes();
  public formsDto = new FormsDto();

  constructor(
    public authService: AuthService,
    public router: Router,
    public patientService: PatientService,
    public formService: FormsService
  ) { }

  ngOnInit(): void {
    this.mortalityForm = new MortalityFormDto();
    setTimeout(() => {
      this.getPatient(this.patientId);

      if (this.action === 'update') {
        this.getSingleMortalityForm();
      }

      this.getSurgicalFormsType();
    }, 1000);
  }

  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  getPatient(id) {
    this.showPreloader = true;
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.showPreloader = false;
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => {
          this.showPreloader = false;
          this.errorHandler(error);
        })
  }


  getSurgicalFormsType() {
    this.formService.getSurgicalForms(FormCode.SURGICAL_TREATMENT_CODE, this.patientId)
      .subscribe(data => {
        this.surgicalTreatmentForms = [];
        const surgeryArray = data;
        surgeryArray.forEach(s => {
          const surgeryTreatment = new SurgicalTreatmentFormDto();
          surgeryTreatment.mapToSurgicalTreatmentForm(s);
          this.surgicalTreatmentForms.push(surgeryTreatment);
        })

        return this.surgicalTreatmentForms;
      },
        error1 => {

          this.errorHandler(error1);
        });
  }

  convertDateTimeToDateformat(date) {
    return GeneralUtil.convertDateTimeToDateformat(date);
  }

  removeUnderscore(mystring: string): string {
    return mystring.replace(/_/g, ' ');
  }

  getSingleMortalityForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
          this.mortalityForm = new MortalityFormDto();
          this.mortalityForm.mapToMortalityForm(data);
          this.mortalityForm.dateOfDeath = GeneralUtil.convertddMmYyyyHhMmAToDateTimeInput(this.mortalityForm.dateOfDeath);
        },
        error1 => {
          this.errorHandler(error1);
        })
  }

  submitForm() {
    this.showFormPreloader = true;
    // assign form and form code to form to be posted
    this.formsDto.mortalityFormDto = this.mortalityForm;
    this.formsDto.code = this.formCode;

    /* Registration */
    if (this.action === 'registration') {
      this.formsDto.patientId = this.patientId;
      this.formService.formRegistration(this.formsDto)
        .subscribe(data => {
            this.showFormPreloader = false;
            successToast('Form submitted successfully');
            // route
            this.router.navigate(['/report/view-patient-information/' + this.patientId]);
          },
          error1 => {
            this.showFormPreloader = false;
            this.errorHandler(error1);
          });
      return;
    }
    /* Update */
    if (this.action === 'update') {

      // hit the update service
      this.formService.formUpdate(this.formsDto, this.formRef)
        .subscribe(data => {
          this.showFormPreloader = false;
          successToast('Form updated successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, error1 => {
          this.showFormPreloader = false;
          this.errorHandler(error1);
        });
      return;
    }

  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
