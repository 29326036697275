import { GeneralUtil } from '../../util/general-util';

export class SurgicalTreatmentType {

  public dateOfProcedure: string;
  public repairType: string;
  public technique: string;
  public type: string;

  public mapToSurgicalTreatmentType(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
        this.dateOfProcedure = data.dateOfProcedure;
        this.repairType = data.repairType;
        this.technique = data.technique;
        this.type = data.type;
    }
  }
}

