import { Component, OnInit } from '@angular/core';
import { TreatmentEncounterStage } from './../../../constant/treatment-encounter-stage.enum';
import { PatientService } from './../../../service/patient.service';
import { FacilityService } from '../../../service/facility.service';
import { PatientInformation } from 'src/app/entity/patient-information';
import { Router } from '@angular/router';
import { GeneralUtil } from 'src/app/util/general-util';
import { Facility } from '../../../entity/facility';
import { FilterDto } from './../../../dto/filter-dto';
import { PaginationDataDto } from '../../../dto/pagination-data-dto';
import {GeneralEnum} from '../../../constant/general-enum.enum';

@Component({
  selector: 'app-complete-patient-info',
  templateUrl: './complete-patient-info.component.html',
  styleUrls: ['./complete-patient-info.component.scss']
})
export class CompletePatientInfoComponent implements OnInit {
  public showPreloader = false;
  public patients: PatientInformation[] = [];
  public treatmentEncounterStage = TreatmentEncounterStage;
  public facilities: Facility[] = [];
  public filterDto = new FilterDto();
  public paginationData = new PaginationDataDto();  // pagination object

  constructor(
    public patientService: PatientService,
    public facilityService: FacilityService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getAllPatients()
  }

  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  filterPatients() {
    // initialize pagination data
    this.initializePaginationData();

    // then hit getAllPatients function
    this.getAllPatients();
  }

  getAllPatients() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.patientService.getAllPatients(this.filterDto.search , '', this.filterDto.treatmentStage, (currentPage - 1),
      defaultLimit, this.filterDto.facility)
      .subscribe(data => {
        this.showPreloader = false;
        this.patients = [];
        this.paginationData.totalSize = data.total;

        if (data.total > 0) {
          const patientArray = data.entities;
          patientArray.forEach(p => {
            const patient = new PatientInformation();
            patient.mapToPatientInformation(p);
            this.patients.push(patient);
          })
          return this.patients;
        }
      },
        error1 => {
          this.errorHandler(error1);
          this.showPreloader = false;
        });
  }

  removeUnderscore(underscoreWord) {
    return GeneralUtil.removeUnderscore(underscoreWord);
  }

    /*********PAGINATION FUNCTIONS***************/
    initializePaginationData() {
      this.paginationData.currentPage = 1;
      this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
      this.paginationData.totalSize = 100;
  }

  // error Handler
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
