import { Component, OnInit } from '@angular/core';
import { FormCode } from './../../../../constant/form-code-enum';
import { FormsService } from 'src/app/service/forms.service';
import { NutritionFormDto } from '../../../../dto/forms/nutrition-form-dto';
import { GeneralUtil } from 'src/app/util/general-util';
import { FacilityService } from '../../../../service/facility.service';
import { Facility } from '../../../../entity/facility';
import { PaginationDataDto } from './../../../../dto/pagination-data-dto';
import { GeneralEnum } from './../../../../constant/general-enum.enum';
import { FilterDto } from './../../../../dto/filter-dto';

@Component({
  selector: 'app-nutrition-form',
  templateUrl: './nutrition-form.component.html',
  styleUrls: ['./nutrition-form.component.scss']
})
export class NutritionFormComponent implements OnInit {
  public showPreloader = false;
  public formCode = FormCode;
  public nutritionForms: NutritionFormDto[] = [];
  public nutritionFormsII: NutritionFormDto[] = [];
  public facilities: Facility[] = [];
  public surgery = 'Pre-Surgery';
  public paginationData = new PaginationDataDto();  // pagination object
  public filterDto = new FilterDto();



  constructor(public formService: FormsService, public facilityService: FacilityService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getNutritionForms();
    this.getNutritionFormsII();
  }

  export2csv() {
    return GeneralUtil.export2csv('nutrition-form-report.csv');
  }

  filterForm(){
    this.initializePaginationData();
    this.getNutritionForms();

  }

  filterFormII(){
    this.initializePaginationData();
    this.getNutritionFormsII();
  }

  getNutritionForms() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;
    this.formService.getForms(this.formCode.NUTRITION_CODE, this.filterDto.facility, (currentPage - 1),
      defaultLimit, this.filterDto.search)
      .subscribe(data => {
        this.nutritionForms = [];
        this.showPreloader = false;
        const nutritionFormsArray = data.entities;
        this.paginationData.totalSize = data.total;
        if (nutritionFormsArray !== null && nutritionFormsArray.length > 0) {
          nutritionFormsArray.forEach(n => {
            const nutritionFormsdata = new NutritionFormDto();
            nutritionFormsdata.mapToNutritionForms(n);
            this.nutritionForms.push(nutritionFormsdata);
          })
        }
      })
  }

  getNutritionFormsII() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;
    this.formService.getForms(this.formCode.NUTRITION_CODE_II, this.filterDto.facility,
      (currentPage - 1), defaultLimit, this.filterDto.search)
      .subscribe(data => {
        this.nutritionFormsII = [];
        this.showPreloader = false;
        const nutritionFormsIIArray = data.entities;
        this.paginationData.totalSize = data.total;
        if (nutritionFormsIIArray !== null && nutritionFormsIIArray.length > 0) {
          nutritionFormsIIArray.forEach(nII => {
            const nutritionFormsIIdata = new NutritionFormDto();
            nutritionFormsIIdata.mapToNutritionForms(nII);
            this.nutritionFormsII.push(nutritionFormsIIdata);
          })
         }
      })
  }

  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

   /*********PAGINATION FUNCTIONS***************/
   initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

   // Error Handling
    errorHandler(error) {
    return GeneralUtil.errorHandler(error)
   }

}
