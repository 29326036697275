import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PatientInformation } from '../../../entity/patient-information';
import { CountryAndStateService } from '../../../service/country-and-state.service';
import { State } from '../../../entity/state';
import { Lga } from '../../../entity/lga';
import { Facility } from '../../../entity/facility';
import { FacilityService } from '../../../service/facility.service';
import { Country } from '../../../entity/country';
import { GeneralUtil } from '../../../util/general-util';
import { SmileTrainPartner } from '../../../entity/smile-train-partner';
import { SmileTrainPartnerService } from '../../../service/smile-train-partner.service';

@Component({
  selector: 'app-page-one',
  templateUrl: './page-one.component.html',
  styleUrls: ['./page-one.component.scss']
})
export class PageOneComponent implements OnInit {

  // variables
  public countries: Country[] = [];
  public states: State[] = [];
  public lgas: Lga[] = [];
  public facilities: Facility[] = [];
  public today = new Date().toJSON().split('T')[0];
  public patientInformation: PatientInformation;
  public ageNumber: number;
  public ageRange: string;
  public age: number;
  public enterAge = 'dateOfBirth';
  partners: SmileTrainPartner[] = [];
  @Output() pageOneCompleted = new EventEmitter<string>();

  constructor(
    public facilityService: FacilityService,
    public countryAndStateService: CountryAndStateService,
    public smileTrainPartnerService: SmileTrainPartnerService) { }

  ngOnInit(): void {
    this.getCountries();

    setTimeout(() => {
      this.getStates(this.patientInformation.address.country)
    }, 200)

    setTimeout(() => {
      this.getLgas(this.patientInformation.address.state)
    }, 300)

    setTimeout(() => {
      this.getSmileTrainPartners();
    }, 100)

    if (sessionStorage.getItem('patientInformation')) {
      this.patientInformation = JSON.parse(sessionStorage.getItem('patientInformation'))
    } else {
      this.patientInformation = new PatientInformation();
      this.patientInformation.race = 'AFRICAN_BLACK';
    }
  }

  getCountries() {
    this.countries = [];
    this.countryAndStateService.getCountries()
      .subscribe(data => {
        if (data.length > 0) {
          const countryList = data;
          countryList.forEach(c => {
            const country = new Country();
            country.mapToCountry(c);
            this.countries.push(country);
          });
        }
      },
        error1 => this.errorHandler(error1));
  }

  getStates(country: string) {
    // get id of country selected.
    const countryName = country;
    if (!GeneralUtil.isValidString(countryName)) {
      return;
    }

    let countryId;
    this.countries.forEach(c => {
      if (c.name === countryName) {
        countryId = c.id;
      }
    });

    this.states = [];
    this.countryAndStateService.getStates(countryId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const state = new State();
            state.mapToState(c);
            this.states.push(state);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  getLgas(state: string) {

    // get id of state selected.
    const stateName = state;
    if (!GeneralUtil.isValidString(stateName)) {
      return;
    }

    let stateId;
    this.states.forEach(s => {
      if (s.name === stateName) {
        stateId = s.id;
      }
    });

    this.lgas = [];
    this.countryAndStateService.getLgas(stateId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const lga = new Lga();
            lga.mapToLga(c);
            this.lgas.push(lga);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });

  }

  getSmileTrainPartners() {
    this.smileTrainPartnerService.getSmileTrainPartners()
    .subscribe(data => {
          if (data.length > 0) {
            data.forEach(s => {
              const partner = new SmileTrainPartner();
              partner.mapToEntity(s);
              this.partners.push(partner);
            });
      }
        },
        error1 => this.errorHandler(error1));
  }


  getFacilitiesByPartnerSelection() {
    let partnerId;
    this.partners.forEach(p => {
      if (p.name == this.patientInformation.partner) {
        partnerId = p.id
      }
    })

    this.facilityService.getAllFacilities('', 500, true, '', partnerId)
      .subscribe(data => {
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  setDateOfBirth() {
    const dob = GeneralUtil.getDateOfBirthByAgeRange(this.ageRange, this.ageNumber);
    this.patientInformation.dateOfBirth =
    GeneralUtil.convertDateTimeToDateInputFormat(GeneralUtil.convertDateStringToIsoDateTimeFormat(dob));
  }

  pageOneFinished() {
    // assign a temp id to the patient
    if (!GeneralUtil.isValidString(this.patientInformation.tempId)) {
      this.patientInformation.tempId = GeneralUtil.getRandomString(8);
    }
    sessionStorage.setItem('patientInformation', JSON.stringify(this.patientInformation))
    this.pageOneCompleted.emit('one');
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
