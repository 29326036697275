import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { RehabilitationFormDto } from './../../../../dto/forms/rehabilitation-form-dto';
import { FormsService } from './../../../../service/forms.service';
import { Component, OnInit } from '@angular/core';
import { FormCode } from 'src/app/constant/form-code-enum';
import { GeneralUtil } from 'src/app/util/general-util';
import { FacilityService } from '../../../../service/facility.service';
import { Facility } from '../../../../entity/facility';
import { PaginationDataDto } from './../../../../dto/pagination-data-dto';
import { FilterDto } from './../../../../dto/filter-dto';

@Component({
  selector: 'app-rehabilitation-form',
  templateUrl: './rehabilitation-form.component.html',
  styleUrls: ['./rehabilitation-form.component.scss']
})
export class RehabilitationFormComponent implements OnInit {
  public showPreloader = false;
  public formCode = FormCode;
  public facilities: Facility[] = [];
  public rehabilitationForms: RehabilitationFormDto[] = [];
  public paginationData = new PaginationDataDto();
  public filterDto = new FilterDto();

  constructor(
    public formService: FormsService, public facilityService: FacilityService
  ) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getRehabilitationForms();
  }

  filterForm(){
    this.initializePaginationData();

    this.getRehabilitationForms();
  }

  getRehabilitationForms() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;
    this.formService.getForms(this.formCode.REHABILITATION_CODE, this.filterDto.facility, (currentPage - 1),
      defaultLimit, this.filterDto.search)
      .subscribe(data => {
        this.showPreloader = false;
        this.rehabilitationForms = [];

        const rehabilitationFormsArray = data.entities;
        this.paginationData.totalSize = data.total;
        if (rehabilitationFormsArray !== null && rehabilitationFormsArray.length > 0) {
          rehabilitationFormsArray.forEach(rehabForm => {
            const rehabilitation = new RehabilitationFormDto();
            rehabilitation.mapToRehabilitation(rehabForm);
            this.rehabilitationForms.push(rehabilitation);
          })
        }
      },
      (error) => {
        this.showPreloader = false;
        this.errorHandler(error);
      })
  }

  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }



  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  export2csv() {
    return GeneralUtil.export2csv('rehabilitation-form-report.csv');
  }

  // Error Handling

  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
   }

}
