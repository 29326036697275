import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CountryAndStateService } from 'src/app/service/country-and-state.service';
import { PatientInformation } from 'src/app/entity/patient-information';
import { State } from 'src/app/entity/state';
import { Lga } from 'src/app/entity/lga';
import { Facility } from 'src/app/entity/facility';
import { FacilityService } from 'src/app/service/facility.service';
import { Country } from 'src/app/entity/country';
import { GeneralUtil } from 'src/app/util/general-util';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/service/patient.service';
import { SmileTrainPartner } from '../../../entity/smile-train-partner';
import { SmileTrainPartnerService } from '../../../service/smile-train-partner.service';

declare function successToast(msg: string): any;
declare function failureToast(msg): any;


@Component({
  selector: 'app-page-one-update',
  templateUrl: './page-one-update.component.html',
  styleUrls: ['./page-one-update.component.scss']
})
export class PageOneUpdateComponent implements OnInit {
  // variables
  public countries: Country[] = [];
  public states: State[] = [];
  public lgas: Lga[] = [];
  public facilities: Facility[] = [];
  public today = new Date().toJSON().split('T')[0];
  public patientInformation: PatientInformation;
  public ageNumber: number;
  public ageRange: string;
  public age: number;
  public enterAge = 'dateOfBirth';
  public patientId: string;
  public showPreloader = false;
  partners: SmileTrainPartner[] = [];


  constructor(
    public facilityService: FacilityService,
    public countryAndStateService: CountryAndStateService,
    public router: Router,
    public route: ActivatedRoute,
    public patientService: PatientService,
    public smileTrainPartnerService: SmileTrainPartnerService) { }

  ngOnInit(): void {
    // get patient Id
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    setTimeout(() => {
      this.getPatient(this.patientId);
    }, 500)


    // get facilities
    setTimeout(() => {
      this.getFacilities('');
    }, 500);

    // get countries
    this.getCountries();

    setTimeout(() => {
      this.getSmileTrainPartners();
    }, 100)

    setTimeout(() => {
      this.getStates(this.patientInformation.address.country)
    }, 200)

    setTimeout(() => {
      this.getLgas(this.patientInformation.address.state)
    }, 300)

 }

  getCountries() {
    this.countries = [];
    this.countryAndStateService.getCountries()
      .subscribe(data => {
        if (data.length > 0) {
          const countryList = data;
          countryList.forEach(c => {
            const country = new Country();
            country.mapToCountry(c);
            this.countries.push(country);
          });
        }
      },
        error1 => this.errorHandler(error1));
  }

  getStates(country: string) {
    // get id of country selected.
    const countryName = country;
    if (!GeneralUtil.isValidString(countryName)) {
      return;
    }
    let countryId;
    this.countries.forEach(c => {
      if (c.name === countryName) {
        countryId = c.id;
      }
    });
    this.states = [];
    this.countryAndStateService.getStates(countryId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const state = new State();
            state.mapToState(c);
            this.states.push(state);
          });

        }
      },
        error1 => {
          this.errorHandler(error1);
        });

  }

  getLgas(state: string) {
    // get id of state selected.
    const stateName = state;
    if (!GeneralUtil.isValidString(stateName)) {
      return;
    }
    let stateId;
    this.states.forEach(s => {
      if (s.name === stateName) {
        stateId = s.id;
      }
    });
    this.lgas = [];
    this.countryAndStateService.getLgas(stateId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const lga = new Lga();
            lga.mapToLga(c);
            this.lgas.push(lga);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  getSmileTrainPartners() {
    this.smileTrainPartnerService.getSmileTrainPartners()
    .subscribe(data => {
          if (data.length > 0) {
            data.forEach(s => {
              const partner = new SmileTrainPartner();
              partner.mapToEntity(s);
              this.partners.push(partner);
            });
      }
        },
        error1 => this.errorHandler(error1));
  }

  getFacilitiesByPartnerSelection() {
    let partnerId;
    this.partners.forEach(p => {
      if (p.name == this.patientInformation.partner) {
        partnerId = p.id
      }
    });

    this.getFacilities(partnerId);
  }


  getFacilities(partnerId) {
    this.facilityService.getAllFacilities('', 500, true, '', partnerId)
      .subscribe(data => {
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  setDateOfBirth() {
    const dob = GeneralUtil.getDateOfBirthByAgeRange(this.ageRange, this.ageNumber);
    this.patientInformation.dateOfBirth = GeneralUtil.convertDateTimeToDateInputFormat(GeneralUtil.convertDateStringToIsoDateTimeFormat(dob));
  }

  getPatient(id) {
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        // set the facility id from the facility object
        this.patientInformation.facilityId = this.patientInformation.facility.id;
        this.patientInformation.dateOfBirth = GeneralUtil.reverseDateFormat(this.patientInformation.dateOfBirth);

        return this.patientInformation;
      },
        error1 => {
          this.errorHandler(error1);
        });
  }


  updatePageOne() {
     this.showPreloader = true;
    // first name and last name must be available before saving
    if (!GeneralUtil.isValidString(this.patientInformation.firstName) ||
      !GeneralUtil.isValidString(this.patientInformation.lastName)) {
      failureToast('Insufficient patient data');
      this.showPreloader = false;
      return;
    }

    //update information
    this.patientService.updatePatientInfo(this.patientInformation)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Data updated successfully');
        // route
       setTimeout(() => {
          this.router.navigate(['/report/view-patient-information/' + this.patientInformation.id]);
        }, 1000);
      },
        error1 => {
           this.showPreloader = false;
          this.errorHandler(error1);
        });
    return;
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
