import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './service/auth/auth.guard';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { LoginComponent } from './component/login/login.component';
import { FacilityManagementComponent } from './component/setup/facility-management/facility-management.component';
import { PatientInformationComponent } from './component/report/patient-information/patient-information.component';
import { FormManagementComponent } from './component/setup/form-management/form-management.component';
import { PermissionsPageComponent } from './component/setup/role-management/permissions-page/permissions-page.component';
import { RoleManagementComponent } from './component/setup/role-management/role-management.component';
import { UserManagementComponent } from './component/setup/user-management/user-management.component';
import { ViewPatientInformationComponent } from './component/report/patient-information/view-patient-information/view-patient-information.component';
import { PatientRegistrationComponent } from './component/patient-registration/patient-registration.component';
import { FormsViewModuleComponent } from './component/report/forms-view-module/forms-view-module.component';
import { PageFiveUpdateComponent } from './component/patient-reg-update/page-five-update/page-five-update.component';
import { PageOneUpdateComponent } from './component/patient-reg-update/page-one-update/page-one-update.component';
import { PageTwoUpdateComponent } from './component/patient-reg-update/page-two-update/page-two-update.component';
import { PageThreeUpdateComponent } from './component/patient-reg-update/page-three-update/page-three-update.component';
import { PageFourUpdateComponent } from './component/patient-reg-update/page-four-update/page-four-update.component';
import { FormUpdateComponent } from './component/report/patient-information/view-patient-information/forms/form-update/form-update.component';
import { FormViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/form-view.component';
import { FormRegistrationComponent } from './component/report/patient-information/view-patient-information/forms/form-registration/form-registration.component';
import { CompletePatientInfoComponent } from './component/report/complete-patient-info/complete-patient-info.component';
import { CostOfCareComponent } from './component/report/patient-information/view-patient-information/forms/cost-of-care/cost-of-care.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'form-management', pathMatch: 'full', component: FormManagementComponent, canActivate: [AuthGuard] },
  { path: 'role-management', pathMatch: 'full', component: RoleManagementComponent, canActivate: [AuthGuard] },
  { path: 'permissions/:roleId', pathMatch: 'full', component: PermissionsPageComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', pathMatch: 'full', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'user-management', pathMatch: 'full', component: UserManagementComponent, canActivate: [AuthGuard] },
  { path: 'report/patient-information/:search', pathMatch: 'full', component: PatientInformationComponent, canActivate: [AuthGuard] },
  { path: 'report/patient-information', pathMatch: 'full', component: PatientInformationComponent, canActivate: [AuthGuard] },
  { path: 'report/patient-information-complete', pathMatch: 'full', component: CompletePatientInfoComponent, canActivate: [AuthGuard] },
  { path: 'report/view-patient-information/:patientId', pathMatch: 'full', component: ViewPatientInformationComponent, canActivate: [AuthGuard] },
  { path: 'facility-management', pathMatch: 'full', component: FacilityManagementComponent, canActivate: [AuthGuard] },
  { path: 'patient-registration', pathMatch: 'full', component: PatientRegistrationComponent, canActivate: [AuthGuard] },
  { path: 'cost-of-care/:patientId', pathMatch: 'full', component: CostOfCareComponent, canActivate: [AuthGuard] },
    /* Form Reports */
  { path: 'report/forms/mortality-form-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },
  { path: 'report/forms/speech-treatment-form-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },
  { path: 'report/forms/speech-assessment-form-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },
  { path: 'report/forms/orthodontic-treatment-form-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },
  { path: 'report/forms/nutrition-form-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },
  { path: 'report/forms/surgical-treatment-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },
  { path: 'report/forms/rehabilitation-form-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },
  { path: 'report/forms/complication-form-reports/:formCode', pathMatch: 'full', component: FormsViewModuleComponent, canActivate: [AuthGuard] },

    /* Patient Update */
  { path: 'patient-reg-update/page-five-update/:patientId', pathMatch: 'full', component: PageFiveUpdateComponent, canActivate: [AuthGuard] },
  { path: 'patient-reg-update/page-one-update/:patientId', pathMatch: 'full', component: PageOneUpdateComponent, canActivate: [AuthGuard]},
  { path: 'patient-reg-update/page-two-update/:patientId', pathMatch: 'full', component: PageTwoUpdateComponent, canActivate: [AuthGuard]},
  { path: 'patient-reg-update/page-three-update/:patientId', pathMatch: 'full', component: PageThreeUpdateComponent, canActivate: [AuthGuard] },
  { path: 'patient-reg-update/page-four-update/:patientId', pathMatch: 'full', component: PageFourUpdateComponent, canActivate: [AuthGuard]},

  /* Form Registration */
  { path: 'form-registration/:formCode/:formRef/:patientId', pathMatch: 'full', component: FormRegistrationComponent, canActivate: [AuthGuard]},

  /* Form Update */
  { path: 'form-update/:formCode/:formRef/:patientId', pathMatch: 'full', component: FormUpdateComponent, canActivate: [AuthGuard] },

  /* Form View */
  { path: 'form-view/:formCode/:formRef/:patientId', pathMatch: 'full', component: FormViewComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
