import {GeneralUtil} from '../util/general-util';

export class PaternalData {
    public id: number;
    public cleftOrCraniofacialAnomaly: string;
    public describeTheCleftOrCraniofacialAnomaly: string;
    public ageAtSubjectsBirth: string;
    public occupation: string;
    public education: string;
    public consanguinityOfParents: string;
    public doYouKnowRtpIfConsanguinity: string;
    public describeRtpOfConsanguineousPartners: string;

    constructor() {}

    public mapToPaternalData(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.id = data.id;
            this.cleftOrCraniofacialAnomaly = data.cleftOrCraniofacialAnomaly;
            this.describeTheCleftOrCraniofacialAnomaly = data.describeTheCleftOrCraniofacialAnomaly;
            this.ageAtSubjectsBirth = data.ageAtSubjectsBirth;
            this.consanguinityOfParents = data.consanguinityOfParents;
            this.doYouKnowRtpIfConsanguinity = data.doYouKnowRtpIfConsanguinity;
            this.describeRtpOfConsanguineousPartners = data.describeRtpOfConsanguineousPartners;
            this.occupation = data.occupation;
            this.education = data.education;
        }
    }
}
