import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PatientInformation } from './../../../entity/patient-information';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CleftType } from './../../../entity/cleft-type';
import { PierreRobinAdditionalFeature } from './../../../entity/pierre-robin-additional-feature';
import { PatientService } from 'src/app/service/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralUtil } from 'src/app/util/general-util';




declare function successToast(msg: string): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-page-three-update',
  templateUrl: './page-three-update.component.html',
  styleUrls: ['./page-three-update.component.scss']
})
export class PageThreeUpdateComponent implements OnInit {
  // variables
  public patientInformation= new PatientInformation();
  public cleft: CleftType[] = [];
  cleftTypeList = [];
  cleftTypes = [];
  dropdownSettings: IDropdownSettings = {};
  pierreRobinList = [];
  pierreRobinTypes = [];
  public patientId: string;
  public showPreloader = false;
  public activeButton: boolean = false;


  constructor(public patientService: PatientService,
              public router: Router,
               public route: ActivatedRoute) { }

  ngOnInit(): void {

    // get patient Id
    this.patientId = this.route.snapshot.paramMap.get('patientId');

    setTimeout(() => {
      this.getPatient(this.patientId);
    }, 500)


    // get list of cleft types
    this.cleftTypeList = [
      { item_id: 1, item_text: 'Cleft lip' },
      { item_id: 2, item_text: 'Cleft palate' },
      { item_id: 3, item_text: 'Alveolar cleft' },
      { item_id: 4, item_text: 'Submuccous Cleft' },
      { item_id: 5, item_text: 'None' },
      { item_id: 6, item_text: 'Unknown' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true

    };

     // get list of pierre robin
     this.pierreRobinList = [
      { item_id: 1, item_text: 'Respiratory Disease' },
      { item_id: 2, item_text: 'Micrognathia' },
      { item_id: 3, item_text: 'Glossoptosis' },
      { item_id: 4, item_text: 'Unknown' },
      { item_id: 5, item_text: 'None' },
      { item_id: 6, item_text: 'Other' }
    ];

  }
  addCleftType() {
    const cleftTypeList: CleftType[] = [];
    this.cleftTypes.forEach(c => {
      const cleftT = new CleftType();
      cleftT.type = c.item_text;
      cleftTypeList.push(cleftT);
    });
    this.patientInformation.patientData.cleftTypes = cleftTypeList;

  }

  addPierreRobin() {
    const pierreRobinList: PierreRobinAdditionalFeature[] = [];
    this.pierreRobinTypes.forEach(p => {
      const piereR = new PierreRobinAdditionalFeature();
      piereR.name = p.item_text;
      pierreRobinList.push(piereR);
    });
    this.patientInformation.patientData.pierreRobinAdditionalFeatures = pierreRobinList;
   }

   getPatient(id) {
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);


        return this.patientInformation;
      },
        error1 => {
          this.errorHandler(error1);
        });
  }


  updatePageThree() {
    this.activeButton = true;
    this.showPreloader = true;

    // 1. validate gestational age
    if (GeneralUtil.isValidString(this.patientInformation.patientData.gestationalAgeInWeeks)) {
        const gestationAge = Number(this.patientInformation.patientData.gestationalAgeInWeeks);
        if (gestationAge < 24 || gestationAge > 41) {
            failureToast('Gestational age must fall between 24 and 41 weeks');
             this.showPreloader = false
            return;
        }
    }
    this.patientService.updatePatientData(this.patientInformation.patientData, this.patientId)
        .subscribe(data => {
          this.activeButton = false;
            this.showPreloader = false;
            successToast('Data updated successfully');
             // route
       setTimeout(() => {
        this.router.navigate(['/report/view-patient-information/' + this.patientInformation.id]);
      }, 1000);

        },

            error1 => {
              this.activeButton = false;
                this.showPreloader = false;
                this.errorHandler(error1);
            });
}
    // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
