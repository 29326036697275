import {GeneralUtil} from '../util/general-util';

export class PreviousLiveBirth {
    public dateOfBirth: string;
    public gender: string;
    public isFatherOfChildSameAsFatherOfSubject: string;

    constructor() {}

    public mapToPreviousLiveBirth(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.dateOfBirth = data.dateOfBirth;
            this.gender = data.gender;
            this.isFatherOfChildSameAsFatherOfSubject = data.isFatherOfChildSameAsFatherOfSubject;
        }
    }
}
