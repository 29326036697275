import { Injectable } from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{
  intercept(req: HttpRequest<any>, next: HttpHandler){

    const tokenizedReq = req.clone({
      setHeaders: {
        Authorization: this.authService.getAuthToken()
      }
    });

    return next.handle(tokenizedReq);


  }

  constructor(private authService: AuthService) { }
}
