import {GeneralUtil} from '../util/general-util';

export class MaternalLifestyleAndExposures {
    public contaminant: string;
    public timeOfExposureToContaminant: string;

    public exposureToPollutionFrequency: string;
    public otherExposureFrequency: string;

    constructor() {}

    public mapToMaternalLifestyleAndExposures(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.contaminant = data.contaminant;
            this.timeOfExposureToContaminant = data.timeOfExposureToContaminant;
            this.exposureToPollutionFrequency = data.exposureToPollutionFrequency;
            this.otherExposureFrequency = data.otherExposureFrequency;
        }
    }
}
