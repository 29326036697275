import {GeneralUtil} from '../util/general-util';

export class DashboardStat {
    public preSurgeryPatients = 0;
    public surgeryPatients = 0;
    public postSurgeryPatients = 0;
    public totalRegisteredPatients = 0;

    constructor() {}

    public mapToDashboardStats(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.preSurgeryPatients = data.preSurgeryPatients;
            this.surgeryPatients = data.surgeryPatients;
            this.postSurgeryPatients = data.postSurgeryPatients;
            this.totalRegisteredPatients = data.totalRegisteredPatients;
        }
    }
}
