import {CleftType} from './cleft-type';
import {PierreRobinAdditionalFeature} from './pierre-robin-additional-feature';
import {GeneralUtil} from '../util/general-util';

export class PatientData {
    public id: number;
    public birthWeightInKg: string;
    public gestationalAgeInWeeks: string;
    public multipleBirth: string;
    public consanguinityOfParents: string;
    public ifYesDoYouKnowTheRtp: string;
    public describeRtpOfParents: string;
    public multipleMalformedInfants: string;
    public limbAbnormality: string;
    public describeLimbAbnormarlity: string;
    public craniofacialAnomaly: string;
    public describeCraniofacialAnomaly: string;
    public cardiovascularAnomaly: string;
    public describeCardiovascularAnomaly: string;
    public otherAnomalies: string;
    public describeOtherAnomalies: string;
    public cleftLipDetails: string;
    public typeOfCleftLip: string;
    public cleftPalateDetails: string;
    public alveolarCleftDetails: string;
    public typeOfAlveolarCleft: string;
    public prenatalDiagnosisOfCleft: string;
    public simonartsBands: string;
    public veloPharyngealInsufficiency: string;
    public pierreRobin: string;
    public syndromic: string;
    public syndromicDiagnosis: string;
    public syndromicDescription: string;
    public anyFamilyHistoryOfClefting: string;
    public describeWhoHasHistoryOfClefting: string;
    public birthFacility: string;
    public otherTypeOfCleft: string;

    public cleftTypes: CleftType[] = [];
    public pierreRobinAdditionalFeatures: PierreRobinAdditionalFeature[] = [];

    constructor() {}

    public mapToPatientData(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.id = data.id;
            this.birthWeightInKg = data.birthWeightInKg;
            this.gestationalAgeInWeeks = data.gestationalAgeInWeeks;
            this.multipleBirth = data.multipleBirth;
            this.consanguinityOfParents = data.consanguinityOfParents;
            this.ifYesDoYouKnowTheRtp = data.ifYesDoYouKnowTheRtp;
            this.describeRtpOfParents = data.describeRtpOfParents;
            this.multipleMalformedInfants = data.multipleMalformedInfants;
            this.limbAbnormality = data.limbAbnormality;
            this.describeLimbAbnormarlity = data.describeLimbAbnormarlity;
            this.craniofacialAnomaly = data.craniofacialAnomaly;
            this.describeCraniofacialAnomaly = data.describeCraniofacialAnomaly;
            this.cardiovascularAnomaly = data.cardiovascularAnomaly;
            this.describeCardiovascularAnomaly = data.describeCardiovascularAnomaly;
            this.otherAnomalies = data.otherAnomalies;
            this.describeOtherAnomalies = data.describeOtherAnomalies;
            this.cleftLipDetails = data.cleftLipDetails;
            this.typeOfCleftLip = data.typeOfCleftLip;
            this.cleftPalateDetails = data.cleftPalateDetails;
            this.alveolarCleftDetails = data.alveolarCleftDetails;
            this.typeOfAlveolarCleft = data.typeOfAlveolarCleft;
            this.prenatalDiagnosisOfCleft = data.prenatalDiagnosisOfCleft;
            this.simonartsBands = data.simonartsBands;
            this.veloPharyngealInsufficiency = data.veloPharyngealInsufficiency;
            this.pierreRobin = data.pierreRobin;
            this.syndromic = data.syndromic;
            this.syndromicDiagnosis = data.syndromicDiagnosis;
            this.syndromicDescription = data.syndromicDescription;
            this.anyFamilyHistoryOfClefting = data.anyFamilyHistoryOfClefting;
            this.describeWhoHasHistoryOfClefting = data.describeWhoHasHistoryOfClefting;
            this.birthFacility = data.birthFacility;
            this.otherTypeOfCleft = data.otherTypeOfCleft;

            // set cleft type
            if (data.cleftTypes != null && data.cleftTypes.length > 0) {
                const cleftTypeArray = data.cleftTypes;
                cleftTypeArray.forEach(c => {
                   const cleftType = new CleftType();
                   cleftType.mapToCleftType(c);
                   this.cleftTypes.push(cleftType);
                });
            }

            // set pierre-robin-addition-features
            if (data.pierreRobinAdditionalFeatures != null && data.pierreRobinAdditionalFeatures.length > 0) {
                const pierreRobinArray = data.pierreRobinAdditionalFeatures;
                pierreRobinArray.forEach(p => {
                   const pierreRob = new PierreRobinAdditionalFeature();
                   pierreRob.mapToPierreRobinAdditionalFeature(p);
                   this.pierreRobinAdditionalFeatures.push(pierreRob);
                });
            }
        }

    }

}
