import { updateRoleRequest } from './../dto/update-role-dto';
import { CreateRoleRequestDto } from '../dto/role-dto';
import { GeneralEnum } from '../constant/general-enum.enum';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(public httpClient: HttpClient) {
    }
    public getRoles() {
        const url = GeneralEnum.baseUrl + '/auth/roles';

        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));
    }

    public getPermissions() {
        const url = GeneralEnum.baseUrl + '/auth/permissions';

        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));
    }

    public registerRole(createrolerequestDto: CreateRoleRequestDto) {
        const url = GeneralEnum.baseUrl + '/auth/role/register';

        return this.httpClient.post<any>(url, createrolerequestDto)
            .pipe(catchError(this.errorHandler));

    }

    public changeRoleStatus(roleId: number, status: string) {
     const url = GeneralEnum.baseUrl + '/auth/role/changeStatus/' + roleId +  '/' + status;
        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));
    
    }

    public getRoleById(roleId: number) {

        const url = GeneralEnum.baseUrl + '/auth/role/' + roleId;
        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));

    }

    public updateRole(updaterolerequest: updateRoleRequest, roleId: number) {
        const url = GeneralEnum.baseUrl + '/auth/role/update/' + roleId;

        return this.httpClient.put<any>(url, updaterolerequest)
            .pipe(catchError(this.errorHandler));

    }

    public updateUser(role, roleId) {
        const url = GeneralEnum.baseUrl + '/auth/user/update/' + roleId;
        return this.httpClient.put<any>(url, role)

            .pipe(catchError(this.errorHandler));

    }


    // Error Handler
    errorHandler(error: HttpErrorResponse) {
        return throwError(error);
    }
}
