export enum GeneralEnum {

    // dev
    // baseUrl = 'http://178.62.52.150:8080'

    // stage
    baseUrl = 'http://178.62.52.150:8085',


    // prod
    // baseUrl = 'http://139.59.170.110:8080'

    paginationLimit = 10
}
