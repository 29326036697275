import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormCode } from './../../../constant/form-code-enum';

@Component({
  selector: 'app-forms-view-module',
  templateUrl: './forms-view-module.component.html',
  styleUrls: ['./forms-view-module.component.scss']
})
export class FormsViewModuleComponent implements OnInit {

  // variables
  public formCode: string;
  public formCodeEnum = FormCode;

  constructor(public route: ActivatedRoute) { }

  ngOnInit(): void {
    // get form code
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.formCode = params.get('formCode');
    });
  }

}
