import { GeneralUtil } from 'src/app/util/general-util';

export class ComplicationFormDto {
 public patientFirstName: string;
    public patientLastName: string;
    public patientRef: string;
    public clinicianName: string;
    public dateNoticed: string;
    public detailsOfComplication: string;
    public outcome: string;
    public treatment: string;
    public createdAt: string;

    public mapToComplication(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.patientFirstName = data.patientFirstName;
            this.patientLastName = data.patientLastName;
            this.patientRef = data.patientRef;
            this.clinicianName = data.clinicianName;
            this.dateNoticed =  GeneralUtil.reverseDateFormat(data.dateNoticed);
            this.detailsOfComplication = data.detailsOfComplication;
            this.outcome = data.outcome;
            this.treatment = data.treatment;
            this.createdAt = data.createdAt;
        }
    }
}
