import { Component, OnInit } from '@angular/core';
import { RehabilitationFormDto } from './../../../../../../../dto/forms/rehabilitation-form-dto';
import { Router } from '@angular/router';
import { PatientInformation } from './../../../../../../../entity/patient-information';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { FormsService } from 'src/app/service/forms.service';
import { FormCode } from 'src/app/constant/form-code-enum';
import { FormsDto } from './../../../../../../../dto/forms/forms-dto';


declare function successToast(msg): any;

@Component({
  selector: 'app-rehabilitation-forms',
  templateUrl: './rehabilitation-forms.component.html',
  styleUrls: ['./rehabilitation-forms.component.scss']
})
export class RehabilitationFormsComponent implements OnInit {
  // variables
  public action: string;
  public formCode: string;
  public formRef: string;
  public patientId: number;
  public rehabilitationFormDto = new RehabilitationFormDto();
  public rehabOutcome: string; // Temporary holding string
  public onSubmit = false;
  public patientInformation: PatientInformation;
  public formsDto = new FormsDto();

  constructor(public router: Router,
    public formService: FormsService,
    public patientService: PatientService,) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);

      if (this.action === 'update') {
        this.getSingleRehabilitationForm();
      }
    }, 1000);
  }

  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  checkOutcomeOfRehab() {
    if (this.rehabOutcome === 'Others') {
      this.rehabilitationFormDto.outcome = '';
    } else {
      this.rehabilitationFormDto.outcome = this.rehabOutcome;
    }
  }

  getPatient(id) {
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => this.errorHandler(error));

  }

  getSingleRehabilitationForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
        this.rehabilitationFormDto = new RehabilitationFormDto();
        this.rehabilitationFormDto.mapToRehabilitation(data);
        // Logic to get outcome
        if (this.rehabilitationFormDto.outcome == 'Complete recovery' || this.rehabilitationFormDto.outcome == 'Residual disability'
          || this.rehabilitationFormDto.outcome == 'Permanent disability') {
          this.rehabOutcome = this.rehabilitationFormDto.outcome;
        } else {
          this.rehabOutcome = 'Others';
        }
      },
        error1 => {
          this.errorHandler(error1);
        })
  }

  submitForm() {
    this.onSubmit = true;
    // convert date to 'DD-MM-YYYY'
    this.rehabilitationFormDto.dateRehabEnded = GeneralUtil.convertDateTimeToDateformat(this.rehabilitationFormDto.dateRehabEnded);
    this.rehabilitationFormDto.dateRehabStarted = GeneralUtil.convertDateTimeToDateformat(this.rehabilitationFormDto.dateRehabStarted);
    // assign form, formcode and patient ID to form to be posted
    this.formsDto.rehabilitationFormDto = this.rehabilitationFormDto;
    this.formsDto.code = this.formCode;

    // Registration
    if (this.action === 'registration') {
      this.formsDto.patientId = this.patientId;
      this.formService.formRegistration(this.formsDto)
        .subscribe(data => {
          this.onSubmit = false;
          successToast('Form submitted successfully');
          // route
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        },
          error1 => {
            this.onSubmit = false;
            this.errorHandler(error1);
          });
      return;
    }

    // Update
    if (this.action === 'update') {
      this.formService.formUpdate(this.formsDto, this.formRef)
        .subscribe(data => {
          this.onSubmit = false;
          successToast('Form updated successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, error1 => {
          this.onSubmit = false;
          this.errorHandler(error1);
        });
      return;
    }
  }


  // Error Handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
