import { Component, OnInit } from '@angular/core';
import { PatientInformation } from 'src/app/entity/patient-information';
import { GeneralUtil } from '../../../../../../../util/general-util';
import { Router } from '@angular/router';
import { PatientService } from '../../../../../../../service/patient.service';
import { FormsDto } from '../../../../../../../dto/forms/forms-dto';
import { FormsService } from '../../../../../../../service/forms.service';
import { OrthodonticTreatmentFormDto } from '../../../../../../../dto/forms/orthodontic-treatment-form-dto';
import { OrthodonticTreatmentType } from '../../../../../../../dto/forms/orthodontic-treatment-type-dto';

declare function successToast(msg: string): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-orthodontic-treatment-forms',
  templateUrl: './orthodontic-treatment-forms.component.html',
  styleUrls: ['./orthodontic-treatment-forms.component.scss']
})
export class OrthodonticTreatmentFormsComponent implements OnInit {
  // variables
  public action: string;
  public formRef: string;
  public formCode: string;
  public patientId: number;
  public today = new Date().toJSON().split('T')[0];
  public patientInformation: PatientInformation;
  public orthodonticTreatment = new OrthodonticTreatmentFormDto();
  public orthodonticTreatmentType = new OrthodonticTreatmentType();
  public orthodonticTreatmentTypeList: OrthodonticTreatmentType[] = [];
  public formsDto = new FormsDto();
  public submitButton = false;

  constructor(
        public router: Router,
        public patientService: PatientService,
        public formService: FormsService
  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.getPatient(this.patientId);

      this.getSingleOrthodonticForm();

    }, 500);

  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
        .subscribe(data => {
            this.patientInformation = new PatientInformation();
            this.patientInformation.mapToPatientInformation(data);
            return this.patientInformation;
        },
            error => this.errorHandler(error));

  }

  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  addOrthodonticTreatmentType(orthodonticTreatment) {
    this.orthodonticTreatmentType.dateOfProcedure = GeneralUtil.reverseDateFormat(this.orthodonticTreatmentType.dateOfProcedure)
    this.orthodonticTreatmentTypeList.push(this.orthodonticTreatmentType);

    this.orthodonticTreatmentType = new OrthodonticTreatmentType();
    orthodonticTreatment.resetForm();

    successToast('Orthodontic Treatment added');
  }

  removeOrthodonticTreatmentType(orthodonticTreatment) {
    this.orthodonticTreatmentTypeList = this.orthodonticTreatmentTypeList.filter(o =>
      o.repairType !== orthodonticTreatment.repairType || o.technique !== orthodonticTreatment.technique
    );

    successToast('Orthodontic Treatment removed');
  }

  getSingleOrthodonticForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
          this.orthodonticTreatment = new OrthodonticTreatmentFormDto();
        this.orthodonticTreatment.mapToOrthodonticTreatment(data);
        this.orthodonticTreatmentTypeList = [...this.orthodonticTreatment.orthodonticTreatmentTypes]
        },
        error1 => {
          this.errorHandler(error1);
        })
  }

  submitForm() {
    this.submitButton = true;
    this.formsDto.code = this.formCode;
    this.formsDto.orthodonticTreatmentFormDto = this.orthodonticTreatment;

    /* Registration */
    if (this.action === 'registration') {
      this.orthodonticTreatment.orthodonticTreatmentTypes.push(...this.orthodonticTreatmentTypeList);
      this.formsDto.patientId = this.patientId;

      // submit service

      this.formService.formRegistration(this.formsDto)
        .subscribe(data => {
          this.submitButton = false;
          successToast('Form submitted successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, error1 => {
          this.submitButton = false;
          this.errorHandler(error1);
        });
      return;
    }

    /* Update */
    if (this.action === 'update') {
      this.orthodonticTreatment.orthodonticTreatmentTypes = [];
      this.orthodonticTreatment.orthodonticTreatmentTypes.push(...this.orthodonticTreatmentTypeList);

      // update service
      this.formService.formUpdate(this.formsDto, this.formRef)
        .subscribe(data => {
          this.submitButton = false;
          successToast('Form updated successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, error1 => {
          this.submitButton = false;
          this.errorHandler(error1);
        });
      return;
    }

  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
