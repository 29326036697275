import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeneralEnum } from 'src/app/constant/general-enum.enum';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {FormsDto} from '../dto/forms/forms-dto';
import { FormCode } from '../constant/form-code-enum';
import { GeneralUtil } from '../util/general-util';
import { CostOfCare } from './../dto/forms/cost-of-care';
import { ResourceAllocationFormDto } from './../dto/forms/resource-allocation-form-dto';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(public httpClient: HttpClient){}


      public getFormsByPatientId(patientId: string) {
        const url = GeneralEnum.baseUrl + '/auth/forms/retrieve/' + patientId;

        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));
      }

      public getAllForms() {
        const url = GeneralEnum.baseUrl + '/auth/forms/all';

        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));
      }

      public updateForm(formCode: string, formName: string){
        const url = GeneralEnum.baseUrl + '/auth/form/update/';
        const body = {formName: formName, formCode : formCode};
        return this.httpClient.put<any>(url,body)
            .pipe(catchError(this.errorHandler));
      }

      public changeStatus(formCode: string){
        const url = GeneralEnum.baseUrl + '/auth/form/changeStatus/' + formCode;

        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));
      }

      public getForms(formCode: string, facility: any, page: number, limit: number, searchParam: string) {
        const url = GeneralEnum.baseUrl + '/auth/form/retrieve/' + formCode +'?facility=' + facility + '&limit=' + limit + '&page=' + page + '&searchParam=' + searchParam;

        return this.httpClient.get<any>(url)
          .pipe(catchError(this.errorHandler));
      }

      public getSurgicalForms(formCode: string, patientId: number) {
        const url = GeneralEnum.baseUrl + '/auth/forms/' + formCode + '/' + patientId;

        return this.httpClient.get<any>(url)
            .pipe(catchError(this.errorHandler));
      }

  public formRegistration(form: FormsDto) {
    const formJson = JSON.parse(JSON.stringify(form));
    switch (formJson.code) {
      case FormCode.COMPLICATION_CODE:
        formJson.complicationFormDto.dateNoticed = GeneralUtil.reverseDateFormat(formJson.complicationFormDto.dateNoticed);
        break;
      case FormCode.MORTALITY_CODE:
        formJson.mortalityFormDto.dateOfDeath = GeneralUtil.convertIonDateTimeToDateTimeString(formJson.mortalityFormDto.dateOfDeath);
        break;
    }
    const url = GeneralEnum.baseUrl + '/auth/forms/register';
    return this.httpClient.post<any>(url, formJson)
      .pipe(catchError(this.errorHandler));
  }

      public getSingleForm(formCode: string, formRef: string) {
        const url = GeneralEnum.baseUrl + '/auth/form/' + formCode + '/' + formRef;

        return this.httpClient.get<any>(url)
          .pipe(catchError(this.errorHandler));
      }

      public getLogOfFormsFilledByPatient(patientId: string) {
        const url = GeneralEnum.baseUrl + '/auth/forms/all/' + patientId;

        return this.httpClient.get<any>(url)
          .pipe(catchError(this.errorHandler));
      }

  public formUpdate(formsDto: FormsDto, formRef: string) {
    const formJson = JSON.parse(JSON.stringify(formsDto));
    switch (formJson.code) {
      case FormCode.COMPLICATION_CODE:
        formJson.complicationFormDto.dateNoticed = GeneralUtil.reverseDateFormat(formJson.complicationFormDto.dateNoticed);
        break;
      case FormCode.MORTALITY_CODE:
        formJson.mortalityFormDto.dateOfDeath = GeneralUtil.convertIonDateTimeToDateTimeString(formJson.mortalityFormDto.dateOfDeath);
        break;
    }
    const url = GeneralEnum.baseUrl + '/auth/forms/update/' + formRef;

    return this.httpClient.put<any>(url, formJson)
      .pipe(catchError(this.errorHandler));
  }

  public registerCostOfCareForm(costOfCare: CostOfCare) {
    const url = GeneralEnum.baseUrl + '/auth/form/coc';

    return this.httpClient.post<any>(url, costOfCare)
      .pipe(catchError(this.errorHandler));
  }

  public updateCostOfCareForm(costOfCare: CostOfCare) {
    const url = GeneralEnum.baseUrl + '/auth/form/coc';

    return this.httpClient.patch<any>(url, costOfCare)
      .pipe(catchError(this.errorHandler));
  }

  public getCostOfCareForm(patientId: number) {
    const url = GeneralEnum.baseUrl + '/auth/form/coc/' + patientId;

    return this.httpClient.get<any>(url)
      .pipe(catchError(this.errorHandler));
  }

  public registerResourceAllocationForm(resourceAllocation: ResourceAllocationFormDto) {
    const url = GeneralEnum.baseUrl + '/auth/form/raf';

    return this.httpClient.post<any>(url, resourceAllocation)
      .pipe(catchError(this.errorHandler));
  }

  public updateResourceAllocationForm(resourceAllocation: ResourceAllocationFormDto) {
    const url = GeneralEnum.baseUrl + '/auth/form/raf';

    return this.httpClient.patch<any>(url, resourceAllocation)
      .pipe(catchError(this.errorHandler));
  }

  public getResourceAllocationForm(facility) {
    const url = GeneralEnum.baseUrl + '/auth/form/raf/' + facility;

    return this.httpClient.get<any>(url)
    .pipe(catchError(this.errorHandler));
  }
    // Error Handler
    errorHandler(error: HttpErrorResponse) {
        return throwError(error);
    }
}
