import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PatientInformation } from '../../../entity/patient-information';
import { PreviousLiveBirth } from '../../../entity/previous-live-birth';
import { MaternalIllness } from './../../../entity/maternal-illness';
import { MaternalMedication } from './../../../entity/maternal-medication';
import { MaternalLifestyleAndExposures } from '../../../entity/maternal-lifestyle-and-exposures';
import { OtherMedicationDto } from '../../../dto/other-medication-dto';
import { Malformation } from '../../../entity/malformation';

declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-page-four-update',
  templateUrl: './page-four-update.component.html',
  styleUrls: ['./page-four-update.component.scss']
})
export class PageFourUpdateComponent implements OnInit {
  // variables
  public showPreloader = false;
  public patientId: string;
  public patientInformation: PatientInformation;
  public previousLiveBirth = new PreviousLiveBirth();
  public otherMedicationDto = new OtherMedicationDto();
  public malformationList = [];
  public timeOfExposureList = [];
  public malformations = [];
  public diabetes = [];
  public hypertension = [];
  public asthma = [];
  public chronicDisease = [];
  public tuberculosis = [];
  public threatenedAbortion = [];
  public vaginalBleeding = [];
  public folicAcid = [];
  public iron = [];
  public vitamins = [];
  public nativeMedication = [];
  public otherMedication = [];
  public tobacco = [];
  public smokelessTobacco = [];
  public alcohol = [];
  public diagnosticXrays = [];
  public environmentalPollution = [];
  public environmentalHazard = [];
  public otherMed: string;
  dropdownSettings: IDropdownSettings = {};
  public today = new Date().toJSON().split('T')[0];
  public multipleList = { item_id: 0, item_text: '' };

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public patientService: PatientService
  ) { }

  ngOnInit(): void {
    // get patient Id
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    setTimeout(() => {
      this.getPatient(this.patientId);
    }, 500)

    this.malformationList = [
      { item_id: 1, item_text: 'Cleft' },
      { item_id: 2, item_text: 'Craniofacial anomaly' },
      { item_id: 3, item_text: 'Limb abnormality' },
      { item_id: 4, item_text: 'Cardiovascular anomaly' },
      { item_id: 5, item_text: 'Multiple malformations' },
      { item_id: 6, item_text: 'None' },
      { item_id: 7, item_text: 'Unknown' }
    ];

    this.timeOfExposureList = [
      { item_id: 1, item_text: 'Before conception' },
      { item_id: 2, item_text: 'During 1st Trimester' },
      { item_id: 3, item_text: 'During 2nd Trimester' },
      { item_id: 4, item_text: 'During 3rd Trimester' },
      { item_id: 5, item_text: 'No history' },
      { item_id: 7, item_text: 'Unknown' }
    ]

    /* multiple select settings */
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
  }


  getPatient(patientId) {
    this.patientService.getPatientDetail(patientId)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);

        /* Malformations  */
        this.patientInformation.maternalData.malformations.forEach(m => {
          this.multipleList = { item_id: 0, item_text: '' };
          this.multipleList.item_id = this.patientInformation.maternalData.malformations.indexOf(m);
          this.multipleList.item_text = m.details;
          this.malformations.push(this.multipleList)
        })

        /* diabetes */
        this.patientInformation.maternalData.maternalIllnesses.forEach(m => {
          if (m.illness == 'DIABETES') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalIllnesses.indexOf(m);
            this.multipleList.item_text = m.periodOfIllness;
            this.diabetes.push(this.multipleList)
          }
        })

        /* hypertension */
        this.patientInformation.maternalData.maternalIllnesses.forEach(m => {
          if (m.illness == 'HYPERTENSION') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalIllnesses.indexOf(m);
            this.multipleList.item_text = m.periodOfIllness;
            this.hypertension.push(this.multipleList)
          }
        })

        /* asthma */
        this.patientInformation.maternalData.maternalIllnesses.forEach(m => {
          if (m.illness == 'ASTHMA') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalIllnesses.indexOf(m);
            this.multipleList.item_text = m.periodOfIllness;
            this.asthma.push(this.multipleList)
          }
        })

        /* chronicDisease */
        this.patientInformation.maternalData.maternalIllnesses.forEach(m => {
          if (m.illness == 'CHRONIC_DISEASE') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalIllnesses.indexOf(m);
            this.multipleList.item_text = m.periodOfIllness;
            this.chronicDisease.push(this.multipleList)
          }
        })

        /* tuberculosis */
        this.patientInformation.maternalData.maternalIllnesses.forEach(m => {
          if (m.illness == 'TUBERCULOSIS') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalIllnesses.indexOf(m);
            this.multipleList.item_text = m.periodOfIllness;
            this.tuberculosis.push(this.multipleList)
          }
        })


        /* threatenedAbortion */
        this.patientInformation.maternalData.maternalIllnesses.forEach(m => {
          if (m.illness == 'THREATENED_ABORTION') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalIllnesses.indexOf(m);
            this.multipleList.item_text = m.periodOfIllness;
            this.threatenedAbortion.push(this.multipleList)
          }
        })

        /* vaginalBleeding */
        this.patientInformation.maternalData.maternalIllnesses.forEach(m => {
          if (m.illness == 'VAGINAL_BLEEDING') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalIllnesses.indexOf(m);
            this.multipleList.item_text = m.periodOfIllness;
            this.vaginalBleeding.push(this.multipleList)
          }
        })

        /* folicAcid */
        this.patientInformation.maternalData.maternalMedications.forEach(m => {
          if (m.medicationName == 'FOLIC_ACID') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalMedications.indexOf(m);
            this.multipleList.item_text = m.medicationTakenAt;
            this.folicAcid.push(this.multipleList)
          }
        })


        /* iron */
        this.patientInformation.maternalData.maternalMedications.forEach(m => {
          if (m.medicationName == 'IRON') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalMedications.indexOf(m);
            this.multipleList.item_text = m.medicationTakenAt;
            this.iron.push(this.multipleList)
          }
        })

        /* vitamins */
        this.patientInformation.maternalData.maternalMedications.forEach(m => {
          if (m.medicationName == 'VITAMINS') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalMedications.indexOf(m);
            this.multipleList.item_text = m.medicationTakenAt;
            this.vitamins.push(this.multipleList)
          }
        })

        /* nativeMedication */
        this.patientInformation.maternalData.maternalMedications.forEach(m => {
          if (m.medicationName == 'NATIVE_MEDICATION') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalMedications.indexOf(m);
            this.multipleList.item_text = m.medicationTakenAt;
            this.nativeMedication.push(this.multipleList)
          }
        })

        /* tobacco */
        this.patientInformation.maternalData.maternalLifestyleAndExposures.forEach(m => {
          if (m.contaminant == 'TOBACCO') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalLifestyleAndExposures.indexOf(m);
            this.multipleList.item_text = m.timeOfExposureToContaminant;
            this.tobacco.push(this.multipleList)
          }
        })

        /* smokelessTobacco */
        this.patientInformation.maternalData.maternalLifestyleAndExposures.forEach(m => {
          if (m.contaminant == 'SMOKELESS_TOBACCO') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalLifestyleAndExposures.indexOf(m);
            this.multipleList.item_text = m.timeOfExposureToContaminant;
            this.smokelessTobacco.push(this.multipleList)
          }
        })

        /* alcohol */
        this.patientInformation.maternalData.maternalLifestyleAndExposures.forEach(m => {
          if (m.contaminant == 'ALCOHOL') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalLifestyleAndExposures.indexOf(m);
            this.multipleList.item_text = m.timeOfExposureToContaminant;
            this.alcohol.push(this.multipleList)
          }
        })

        /* diagnosticXrays */
        this.patientInformation.maternalData.maternalLifestyleAndExposures.forEach(m => {
          if (m.contaminant == 'DIAGNOSTIC_XRAYS') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalLifestyleAndExposures.indexOf(m);
            this.multipleList.item_text = m.timeOfExposureToContaminant;
            this.diagnosticXrays.push(this.multipleList)

          }
        })

        /* environmentalPollution */
        this.patientInformation.maternalData.maternalLifestyleAndExposures.forEach(m => {
          if (m.contaminant == 'EXPOSURE_TO_POLLUTIONS') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalLifestyleAndExposures.indexOf(m);
            this.multipleList.item_text = m.timeOfExposureToContaminant;
            this.environmentalPollution.push(this.multipleList)

          }
        })

        /* environmentalHazard */
        this.patientInformation.maternalData.maternalLifestyleAndExposures.forEach(m => {
          if (m.contaminant == 'EXPOSURE_TO_HAZARDS') {
            this.multipleList = { item_id: 0, item_text: '' };
            this.multipleList.item_id = this.patientInformation.maternalData.maternalLifestyleAndExposures.indexOf(m);
            this.multipleList.item_text = m.timeOfExposureToContaminant;
            this.environmentalHazard.push(this.multipleList)
          }
        })

        return this.patientInformation;
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  addPreviousBirth(previousBirthForm) {
    this.patientInformation.maternalData.previousLiveBirths.push(this.previousLiveBirth);

    this.previousLiveBirth = new PreviousLiveBirth();
    previousBirthForm.resetForm();

    successToast('Previous birth added');
  }

  removePreviousBirth(child: PreviousLiveBirth) {
    this.patientInformation.maternalData.previousLiveBirths =
      this.patientInformation.maternalData.previousLiveBirths
        .filter(c => child.dateOfBirth !== c.dateOfBirth);

    successToast('Previous birth removed');
  }

  addMalformations() {
    this.patientInformation.maternalData.malformations = [];
    this.malformations.forEach(m => {
      const malfunc = new Malformation();
      malfunc.details = m.item_text;
      if(!this.patientInformation.maternalData.malformations.includes(malfunc))
      this.patientInformation.maternalData.malformations.push(malfunc)
    })
  }

  addDiabetes() {
    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'DIABETES');
    })

    this.diabetes.forEach(m => {
      const diabetes = new MaternalIllness();
      diabetes.illness = 'DIABETES';
      diabetes.periodOfIllness = m.item_text;
      this.patientInformation.maternalData.maternalIllnesses.push(diabetes)
    })
  }

  addHypertension() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'HYPERTENSION');
    })

    this.hypertension.forEach(m => {
      const hypertens = new MaternalIllness();
      hypertens.illness = 'HYPERTENSION';
      hypertens.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(hypertens)
    })
  }

  addAsthma() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'ASTHMA');
    })

    this.asthma.forEach(m => {
      const asth = new MaternalIllness();
      asth.illness = 'ASTHMA';
      asth.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(asth)
    })

  }

  addChronicDisease() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'CHRONIC_DISEASE');
    })

    this.chronicDisease.forEach(m => {
      const chronicdisease = new MaternalIllness();
      chronicdisease.illness = 'CHRONIC_DISEASE';
      chronicdisease.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(chronicdisease)
    })
  }

  addTuberculosis() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'TUBERCULOSIS');
    })

    this.tuberculosis.forEach(m => {
      const tubercu = new MaternalIllness();
      tubercu.illness = 'TUBERCULOSIS';
      tubercu.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(tubercu)
    })
  }

  addThreatenedAbortion() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'THREATENED_ABORTION');
    })

    this.threatenedAbortion.forEach(m => {
      const threatAbortion = new MaternalIllness();
      threatAbortion.illness = 'THREATENED_ABORTION';
      threatAbortion.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(threatAbortion)
    })
  }

  addVaginalBleeding() {

    const maternalIllnessList = this.patientInformation.maternalData.maternalIllnesses;
    this.patientInformation.maternalData.maternalIllnesses = maternalIllnessList.filter(m => {
      return (m.illness !== 'VAGINAL_BLEEDING');
    })

    this.vaginalBleeding.forEach(m => {
      const vaginalBleed = new MaternalIllness();
      vaginalBleed.illness = 'VAGINAL_BLEEDING';
      vaginalBleed.periodOfIllness = m.item_text;

      this.patientInformation.maternalData.maternalIllnesses.push(vaginalBleed)
    })
  }

  /* Maternal Medications */
  addFolicAcid() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'FOLIC_ACID');
    })

    this.folicAcid.forEach(m => {
      const folicacid = new MaternalMedication();
      folicacid.medicationName = 'FOLIC_ACID';
      folicacid.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(folicacid)
    })
  }

  addIron() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'IRON');
    })

    this.iron.forEach(m => {
      const Iron = new MaternalMedication();
      Iron.medicationName = 'IRON';
      Iron.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(Iron)
    })
  }

  addVitamins() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'VITAMINS');
    })

    this.vitamins.forEach(m => {
      const vitamin = new MaternalMedication();
      vitamin.medicationName = 'VITAMINS';
      vitamin.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(vitamin)
    })
  }

  addNativeMedication() {

    const maternalMedicationList = this.patientInformation.maternalData.maternalMedications;
    this.patientInformation.maternalData.maternalMedications = maternalMedicationList.filter(m => {
      return (m.medicationName !== 'NATIVE_MEDICATION');
    })

    this.nativeMedication.forEach(m => {
      const nativeMed = new MaternalMedication();
      nativeMed.medicationName = 'NATIVE_MEDICATION';
      nativeMed.medicationTakenAt = m.item_text;

      this.patientInformation.maternalData.maternalMedications.push(nativeMed)
    })
  }

  addOtherMedication(otherMedicationForm) {
    // ensure we don't have a medication of same-name created previously
    let itExists = false;
    this.patientInformation.maternalData.maternalMedications.forEach(m => {
      if (m.medicationName === 'OTHERS' && m.otherMedicationName === this.otherMedicationDto.otherMedicationName) {
        itExists = true;
      }
    });

    if (itExists) {
      failureToast(this.otherMedicationDto.otherMedicationName + ' has been created already');
      return;
    }

    // create maternal medications of name OTHER
    const otherMedicationList: MaternalMedication[] = [];
    this.otherMedicationDto.otherMedicationTakenAt.forEach(o => {
      const medication = new MaternalMedication();
      medication.medicationName = 'OTHERS';
      medication.otherMedicationName = this.otherMedicationDto.otherMedicationName;
      medication.otherMedicationTakenAt = o.item_text;
      otherMedicationList.push(medication);
    });

    otherMedicationList.forEach(o => {
      this.patientInformation.maternalData.maternalMedications.push(o);
    });

    successToast(this.otherMedicationDto.otherMedicationName + ' medication added');
    otherMedicationForm.resetForm();
    this.otherMedicationDto = new OtherMedicationDto();
  }

  getOtherMedications() {

    const prop = 'otherMedicationName';
    const lookupObject = {};
    const otherMedications: MaternalMedication[] = [];

    const originalArray = this.patientInformation.maternalData.maternalMedications;

    for (const i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (const i in lookupObject) {
      otherMedications.push(lookupObject[i]);
    }
    return otherMedications.filter(o => o.medicationName === 'OTHERS');
  }

  removeOtherMedication(otherMedicationName: string) {
    this.patientInformation.maternalData.maternalMedications =
      this.patientInformation.maternalData.maternalMedications.filter(m => {
        return (m.otherMedicationName !== otherMedicationName);
      });

    successToast(otherMedicationName + ' removed');
  }

  addTobacco() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'TOBACCO');
    })

    this.tobacco.forEach(m => {
      const tobac = new MaternalLifestyleAndExposures();
      tobac.contaminant = 'TOBACCO';
      tobac.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(tobac)
    })
  }

  addSmokelessTobacco() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'SMOKELESS_TOBACCO');
    })

    this.smokelessTobacco.forEach(m => {
      const smokelesstobac = new MaternalLifestyleAndExposures();
      smokelesstobac.contaminant = 'SMOKELESS_TOBACCO';
      smokelesstobac.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(smokelesstobac)
    })
  }

  addAlcohol() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'ALCOHOL');
    })

    this.alcohol.forEach(m => {
      const alcoh = new MaternalLifestyleAndExposures();
      alcoh.contaminant = 'ALCOHOL';
      alcoh.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(alcoh)
    })
  }

  addDiagnosticXrays() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'DIAGNOSTIC_XRAYS');
    })

    this.diagnosticXrays.forEach(m => {
      const diagnostic = new MaternalLifestyleAndExposures();
      diagnostic.contaminant = 'DIAGNOSTIC_XRAYS';
      diagnostic.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(diagnostic)
    })
  }

  addEnvironmentalPollution() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'EXPOSURE_TO_POLLUTIONS');
    })

    this.environmentalPollution.forEach(m => {
      const environment = new MaternalLifestyleAndExposures();
      environment.contaminant = 'EXPOSURE_TO_POLLUTIONS';
      environment.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(environment)
    })
  }

  addEnvironmentalHazard() {

    const maternalLifestyleAndExposureList = this.patientInformation.maternalData.maternalLifestyleAndExposures;
    this.patientInformation.maternalData.maternalLifestyleAndExposures = maternalLifestyleAndExposureList.filter(m => {
      return (m.contaminant !== 'EXPOSURE_TO_HAZARDS');
    })

    this.environmentalHazard.forEach(m => {
      const environmentHazard = new MaternalLifestyleAndExposures();
      environmentHazard.contaminant = 'EXPOSURE_TO_HAZARDS';
      environmentHazard.timeOfExposureToContaminant = m.item_text;

      this.patientInformation.maternalData.maternalLifestyleAndExposures.push(environmentHazard)
    })
  }

  updateMaternalInfo() {
    this.showPreloader = true;

    this.patientService.updateMaternalData(this.patientInformation.maternalData, this.patientId)
      .subscribe(
        (data) => {
          this.showPreloader = false;
          successToast('Data updated successfully');
          // route back to details page
          this.router.navigate(['/report/view-patient-information/' + this.patientInformation.id]);
        },
        (error1) => {
          this.showPreloader = false;
          this.errorHandler(error1);
        }
      );
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
