import {GeneralUtil} from '../util/general-util';
import {Address} from './address';

export class ParentGuardianInfo {
    public firstName: string;
    public lastName: string;
    public otherNames: string;
    public dateOfBirth: string;
    public phoneNumber: string;
    public relationshipWithChild: string;
    public houseHoldIncome: string;
    public occupation: string;

    public address = new Address();

    constructor() {}

    public mapToParentGuardianInfo(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.otherNames = data.otherNames;
            this.dateOfBirth = data.dateOfBirth;
            this.phoneNumber = data.phoneNumber;
            this.relationshipWithChild = data.relationshipWithChild;
            this.houseHoldIncome = data.houseHoldIncome;
            this.occupation = data.occupation;

            // create address
            this.address.mapToAddress(data.address);
        }
    }
}
