import { Component, OnInit } from '@angular/core';
import { ComplicationFormDto } from './../../../../../../../dto/forms/complication-form-dto';
import { Router } from '@angular/router';
import { SurgicalTreatmentFormDto } from './../../../../../../../dto/forms/surgical-treatment-form-dto';
import { FormsService } from 'src/app/service/forms.service';
import { FormCode } from 'src/app/constant/form-code-enum';
import { GeneralUtil } from 'src/app/util/general-util';
import { PatientInformation } from './../../../../../../../entity/patient-information';
import { PatientService } from 'src/app/service/patient.service';
import { FormsDto } from './../../../../../../../dto/forms/forms-dto';

declare function successToast(msg): any;


@Component({
  selector: 'app-complication-forms',
  templateUrl: './complication-forms.component.html',
  styleUrls: ['./complication-forms.component.scss']
})
export class ComplicationFormsComponent implements OnInit {
  // variables
  public action: string;
  public  formRef: string;
  public formCode: string;
  public patientId: number;
  public complicationform = new ComplicationFormDto();
  public onSubmit = false;
  public patientInformation: PatientInformation;
  public surgicalTreatmentForms: SurgicalTreatmentFormDto[] = [];
  public formsDto = new FormsDto();

  constructor(public router: Router,
    public formService: FormsService,
    public patientService: PatientService,) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.getPatient(this.patientId);

      if (this.action === 'update') {
        this.getSingleComplicationForm();
      }
    }, 500);

    setTimeout(() => {
      this.getSurgicalFormsType();
    }, 1000);
  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => this.errorHandler(error));

  }

  getSurgicalFormsType() {
    this.formService.getSurgicalForms(FormCode.SURGICAL_TREATMENT_CODE, this.patientId)
      .subscribe(data => {
        this.surgicalTreatmentForms = [];
        const surgeryArray = data;
        surgeryArray.forEach(s => {
          const surgeryTreatment = new SurgicalTreatmentFormDto();
          surgeryTreatment.mapToSurgicalTreatmentForm(s);
          this.surgicalTreatmentForms.push(surgeryTreatment);
        })
        return this.surgicalTreatmentForms;
      },
        error1 => {

          this.errorHandler(error1);
        });
  }

  removeUnderscore(underscoreWord) {
    return GeneralUtil.removeUnderscore(underscoreWord);
  }

  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  getSingleComplicationForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
          this.complicationform = new ComplicationFormDto();
          this.complicationform.mapToComplication(data);
          return this.complicationform
        },
        error1 => {
          this.errorHandler(error1);
        })
  }

  submitForm() {
    this.onSubmit = true;
       // assign form, formcode and patient ID to form to be posted
    this.formsDto.complicationFormDto = this.complicationform;
    this.formsDto.code = this.formCode;
    // Registration
    if (this.action === 'registration') {
      this.formsDto.patientId = this.patientId;
    this.formService.formRegistration(this.formsDto)
      .subscribe(data => {
        this.onSubmit = false;
        successToast('Form submitted successfully');
        // route
        this.router.navigate(['/report/view-patient-information/' + this.patientId]);
      },
        error1 => {
          this.onSubmit = false;
          this.errorHandler(error1);
        });
        return;
    }
    
    //  Update
    if (this.action === 'update') {
      this.formService.formUpdate(this.formsDto, this.formRef)
        .subscribe(data => {
          this.onSubmit = false;
          successToast('Form updated successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        },
          error1 => {
            this.onSubmit = false;
            this.errorHandler(error1);
          });
      return;
    }
   }

  // Error Handling
   errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
