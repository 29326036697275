import { MaternalData } from '../entity/maternal-data';
import { PatientData } from '../entity/patient-data';
import { PaternalData } from '../entity/paternal-data';
import { ParentGuardianInfo } from '../entity/parent-guardian-info';
import { GeneralUtil } from '../util/general-util';
import { Facility } from '../entity/facility';
import { MaternalIllness } from '../entity/maternal-illness';
import { MaternalMedication } from '../entity/maternal-medication';
import { MaternalLifestyleAndExposures } from '../entity/maternal-lifestyle-and-exposures';
import { Address } from '../entity/address';

export class PatientDetails {
    public tempId: string;
    public id: number;
    public patientRef: string;
    public firstName: string;
    public lastName: string;
    public otherNames: string;
    public status: string;
    public dateOfBirth: string;
    public gender: string;
    public phone: string;
    public otherPhone: string;
    public race: string;
    public otherRace: string;
    public tribe: string;
    public otherTribe: string;
    public howDidYouHearAboutSmileTrain: string;
    public hasReleaseFormBeenSigned: boolean;
    public partner: string;
    public facilityId: number;
    public surgeryId: number;

    // maternal illness
    public diabetes: MaternalIllness[] = [];
    public hypertension: MaternalIllness[] = [];
    public asthma: MaternalIllness[] = [];
    public chronicDisease: MaternalIllness[] = [];
    public tuberculosis: MaternalIllness[] = [];
    public threatenedAbortion: MaternalIllness[] = [];
    public vaginalBleeding: MaternalIllness[] = [];

    // maternal medication
    public folicAcid: MaternalMedication[] = [];
    public iron: MaternalMedication[] = [];
    public vitamins: MaternalMedication[] = [];
    public nativeMedication: MaternalMedication[] = [];
    public others: MaternalMedication[] = [];

    // maternal lifestyle
    public tobacco: MaternalLifestyleAndExposures[] = [];
    public smokelessTobacco: MaternalLifestyleAndExposures[] = [];
    public alcohol: MaternalLifestyleAndExposures[] = [];
    public diagnosticXrays: MaternalLifestyleAndExposures[] = [];
    public exposureToPollution: MaternalLifestyleAndExposures[] = [];
    public exposureToHazard: MaternalLifestyleAndExposures[] = [];

    public address = new Address();
    public facility = new Facility();
    public maternalData = new MaternalData();
    public patientData = new PatientData();
    public paternalData = new PaternalData();
    public parentGuardianInfo: ParentGuardianInfo[] = [];

    constructor() { }

    public mapToPatientDetails(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.tempId = data.tempId;
            this.id = data.id;
            this.patientRef = data.patientRef;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.otherNames = data.otherNames;
            this.status = data.status;

            //set the date to ISO 8601 Datetime Format: YYYY-MM-DDTHH:mm:ssTZ
            this.dateOfBirth =
                GeneralUtil.convertDateStringToIsoDateTimeFormat(data.dateOfBirth);

            this.gender = data.gender;
            this.phone = data.phone;
            this.otherPhone = data.otherPhone;
            this.race = data.race;
            this.otherRace = data.otherRace;
            this.tribe = data.tribe;
            this.otherTribe = data.otherTribe;
            this.howDidYouHearAboutSmileTrain = data.howDidYouHearAboutSmileTrain;
            this.hasReleaseFormBeenSigned = data.hasReleaseFormBeenSigned;
            this.partner = data.partner;
            this.facilityId = data.facilityId;
            this.surgeryId = data.surgeryId;


            // set address
            this.address.mapToAddress(data.address);

            //  set facility
            this.facility.mapToFacility(data.facility);

            // set maternalData
            this.maternalData.mapToMaternalData(data.maternalData);

            //set maternalDataIllnesses
            this.maternalData.maternalIllnesses.map(ill => {
                switch (ill.illness) {
                    case 'DIABETES':
                        this.diabetes.push(ill)
                        break;
                    case 'HYPERTENSION':
                        this.hypertension.push(ill)
                        break;
                    case 'ASTHMA':
                        this.asthma.push(ill)
                        break;
                    case 'CHRONIC_DISEASE':
                        this.chronicDisease.push(ill)
                        break;
                    case 'TUBERCULOSIS':
                        this.tuberculosis.push(ill)
                        break;
                    case 'THREATENED_ABORTION':
                        this.threatenedAbortion.push(ill)
                        break;
                    case 'VAGINAL_BLEEDING':
                        this.vaginalBleeding.push(ill)
                        break;
                }
            })

            //set maternalMedications
            this.maternalData.maternalMedications.map(med => {
                switch (med.medicationName) {
                    case 'FOLIC_ACID':
                        this.folicAcid.push(med)
                        break;
                    case 'IRON':
                        this.iron.push(med)
                        break;
                    case 'VITAMINS':
                        this.vitamins.push(med)
                        break;
                    case 'NATIVE_MEDICATION':
                        this.nativeMedication.push(med);
                        break;
                    case 'OTHERS':
                        this.others.push(med)
                        break;
                }
            })

            //set maternalLifestyle
            this.maternalData.maternalLifestyleAndExposures.map(lifeStyle => {
                switch (lifeStyle.contaminant) {
                    case 'TOBACCO':
                        this.tobacco.push(lifeStyle)
                        break;
                    case 'SMOKELESS_TOBACCO':
                        this.smokelessTobacco.push(lifeStyle)
                        break;
                    case 'EXPOSURE_TO_POLLUTIONS':
                        this.exposureToPollution.push(lifeStyle)
                        break;
                    case 'ALCOHOL':
                        this.alcohol.push(lifeStyle)
                        break;
                    case 'DIAGNOSTIC_XRAYS':
                        this.diagnosticXrays.push(lifeStyle)
                        break;
                    case 'EXPOSURE_TO_HAZARDS':
                        this.exposureToHazard.push(lifeStyle)
                        break;
                }
            })

            // set patientData
            this.patientData.mapToPatientData(data.patientData);

            // set paternalData
            this.paternalData.mapToPaternalData(data.paternalData);

            // set parentGuardianInfo
            if (data.parentGuardianInfo != null && data.parentGuardianInfo.length > 0) {
                const parentGuardianArray = data.parentGuardianInfo;
                parentGuardianArray.forEach(p => {
                    const parentGuardianInfo = new ParentGuardianInfo();
                    parentGuardianInfo.mapToParentGuardianInfo(p);
                    this.parentGuardianInfo.push(parentGuardianInfo);
                });
            }


        }
    }

}

