import { GeneralUtil } from 'src/app/util/general-util';

export class FormLogDto {
    public formCode: string;
    public formRef: string;
    public formName: string;
    public createdAt: string;
    constructor() { }

    public mapToForms(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.formCode = data.formCode;
            this.formRef = data.formRef;
            this.createdAt = GeneralUtil.convertDateArrayToDdMmYyyy(data.createdAt);
            this.formName = GeneralUtil.removeUnderscore(data.formName);
        }
    }

}

