import { GeneralUtil } from './../../util/general-util';

export class ResourceAllocationFormDto {
  public facilityId: number;
  public numberOfBeds: number;
  public numberOfCleftSurgeons: number;
  public numberOfGeneralOfGeneralICUs: number;
  public numberOfGeneralIcuBeds: number;
  public numberOfNICUs: number;
  public numberOfNICUBeds: number;
  public numberOfPaediatricICUS: number;
  public numberOfPaediatricIcuBeds: number;
  public numberOfPediatricOperatingRooms: number;
  public numberOfOperatingRooms: number;
  public otherTypesOfSurgicalCare: string;
  public typeOfCleftSurgery: string[];

  public mapToResourceAllocationForm(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.facilityId = data.facilityId;
      this.numberOfBeds = data.numberOfBeds;
      this.numberOfCleftSurgeons = data.numberOfCleftSurgeons;
      this.numberOfGeneralOfGeneralICUs = data.numberOfGeneralOfGeneralICUs;
      this.numberOfGeneralIcuBeds = data.numberOfGeneralIcuBeds;
      this.numberOfNICUs = data.numberOfNICUs;
      this.numberOfNICUBeds = data.numberOfNICUBeds;
      this.numberOfPaediatricICUS = data.numberOfPaediatricICUS;
      this.numberOfPaediatricIcuBeds = data.numberOfPaediatricIcuBeds;
      this.numberOfPediatricOperatingRooms = data.numberOfPediatricOperatingRooms;
      this.numberOfOperatingRooms = data.numberOfOperatingRooms;
      this.otherTypesOfSurgicalCare = data.otherTypesOfSurgicalCare;
      this.typeOfCleftSurgery = data.typeOfCleftSurgery;
    }
  }
}
