import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormCode } from '../../../constant/form-code-enum';
import { Forms } from '../../../entity/form';
import { FormsService } from '../../../service/forms.service';
import { HttpErrorResponse } from '@angular/common/http';

declare function successToast(msg): any;
declare function failureToast(msg): any;

@Component({
  selector: 'app-form-management',
  templateUrl: './form-management.component.html',
  styleUrls: ['./form-management.component.scss']
})
export class FormManagementComponent implements OnInit {


  public forms: Forms[] = [];
  public form = new Forms();
  public selectedForm = new Forms();
  public showPreloader = false;
  @ViewChild('closeModal') closeModal: ElementRef;

  constructor(public formService: FormsService) { }

  ngOnInit() {
    this.getAllForms();
  }

  getAllForms(){
    this.showPreloader = true;
    this.formService.getAllForms()
    .subscribe(data => {
      this.showPreloader = false;
      this.forms = [];
      const formsListArray = data;
      formsListArray.forEach(f => {
        const form = new Forms();
        form.mapToForms(f);
        this.forms.push(form);
      }
      );
    },
    (error) => {
      this.showPreloader = false;
      this.errorHandler(error);
    }
    );
  }

  updateForm(){
    this.showPreloader = true;

    this.formService.updateForm(this.selectedForm.code, this.selectedForm.name)
    .subscribe(data => {
      this.formService.updateForm(this.selectedForm.code, this.selectedForm.name);
      this.showPreloader = false;
      this.closeModal.nativeElement.click();
      successToast("Form updated successfully!");

    },
    (error) => {
      this.showPreloader = false;
      this.errorHandler(error);
    }
    );
  }

  getSingleForm(form: Forms) {
    this.selectedForm = new Forms();
    this.selectedForm = form;
  }

  toggleFormStatus(formCode: string){
    this.showPreloader = true;
    this.formService.changeStatus(formCode)
      .subscribe(data => {
        this.showPreloader = false;
        successToast("Form status updated successfully!");

        /* Get a form from Data response and map it to a form object */
        this.selectedForm = new Forms();
        this.selectedForm.mapToForms(data);
        /* find the index of that particular form, from forms array, use the index to 
         update the new form */
        const index = this.forms.findIndex(form => form.code == this.selectedForm.code);
        this.forms[index] = this.selectedForm;
      },
      (error) => {
        this.showPreloader = false;
        this.errorHandler(error);
      });
  }

  // Error handling
  public errorHandler(error) {
    if (error instanceof HttpErrorResponse) {
      if (error.error.message) {
        failureToast(error.error.message);
        return;
      }

      failureToast('Network error');
      return;
    }
    failureToast('Network error');
    return;
  }


}


