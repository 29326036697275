import { TreatmentEncounterStage } from './../../../constant/treatment-encounter-stage.enum';
import { AuthService } from './../../../service/auth/auth.service';
import { PatientService } from './../../../service/patient.service';
import { Component, OnInit } from '@angular/core';
import { PatientInformation } from 'src/app/entity/patient-information';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralUtil } from 'src/app/util/general-util';
import { UserService } from './../../../service/user.service';
import { User } from './../../../entity/user';
import { FilterDto } from './../../../dto/filter-dto';
import { PaginationDataDto } from '../../../dto/pagination-data-dto';
import {GeneralEnum} from '../../../constant/general-enum.enum';

declare function failureToast(msg): any;
@Component({
  selector: 'app-patient-information',
  templateUrl: './patient-information.component.html',
  styleUrls: ['./patient-information.component.scss']
})
export class PatientInformationComponent implements OnInit {
  public showPreloader = false;
  public patients: PatientInformation[] = [];
  public treatmentEncounterStage = TreatmentEncounterStage;
  public user: User;
  public filterDto = new FilterDto();
  public paginationData = new PaginationDataDto();  // pagination object

  constructor(
    public patientService: PatientService,
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getUser();
    if (this.route.snapshot.paramMap.get('search') != null) {
      this.filterDto.search = this.route.snapshot.paramMap.get('search');
    } else {
      this.filterDto.search = '';
    }
    setTimeout(() => {
      this.getPatients();
    }, 500)

  }

  getUser() {
    this.showPreloader = true;
    this.userService.getUser()
      .subscribe(data => {
        this.showPreloader = false;
        this.user = new User();
        this.user.mapToUser(data);
        return this.user;
      },
      error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
  }

  filterPatients() {
    // initialize pagination data
    this.initializePaginationData();

    // hit getPatients function
    this.getPatients();
  }

  getPatients() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.patientService.getPatients(this.filterDto.search, '', this.filterDto.treatmentStage, (currentPage - 1),
      defaultLimit, this.filterDto.facility)
      .subscribe(data => {
        this.showPreloader = false;
        this.patients = [];
        this.paginationData.totalSize = data.total;

        if (data.total > 0) {
          const patientArray = data.entities;
          patientArray.forEach(p => {
            const patient = new PatientInformation();
            patient.mapToPatientInformation(p);
            this.patients.push(patient);
          })
          return this.patients;
        }
      },
        error1 => {
          this.errorHandler(error1);
          this.showPreloader = false;
        });
  }

  removeUnderscore(underscoreWord) {
    return GeneralUtil.removeUnderscore(underscoreWord);
  }

  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

  // error Handler
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }


}
