import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth/auth.service';
import { Router } from '@angular/router';
import { LoginDto } from '../../dto/login-dto';
import { HttpErrorResponse } from '@angular/common/http';

declare function successToast(msg): any;
declare function failureToast(msg): any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginDto = new LoginDto();
  public showPreloader = false;

  constructor(public authService: AuthService, public router: Router) { }

  ngOnInit() {
    this.userLoginCheck();
  }

  loginAction() {
    this.showPreloader = true;

    this.authService.loginUser(this.loginDto)
      .subscribe((data) => {
        this.showPreloader = false;
        successToast('Login Successful');
        this.authService.resetUserDetails();
        this.authService.setUserDetails(data);
        this.router.navigate(['/dashboard']);
        return;
      },
      (error) => {
        this.showPreloader = false;
        this.errorHandler(error);
      }
    );
  }

  // check if user is already logged in
  public userLoginCheck() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
  }

  // Error handling
  public errorHandler(error) {
    if (error instanceof HttpErrorResponse) {
      if (error.error.message) {
        failureToast(error.error.message);
        return;
      }

      failureToast('Network error');
      return;
    }
    failureToast('Network error');
    return;
  }
}
