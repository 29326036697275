import {GeneralUtil} from '../util/general-util';

export class CleftType {
    public type: string;

    constructor() {}

    public mapToCleftType(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.type = data.type;
        }
    }
}
