import { Component, OnInit } from '@angular/core';
import {PatientService} from '../../../../../../../service/patient.service';
import {FormsService} from '../../../../../../../service/forms.service';
import {Router} from '@angular/router';
import {PatientInformation} from '../../../../../../../entity/patient-information';
import {GeneralUtil} from '../../../../../../../util/general-util';
import {SpeechTreatmentFormDto} from '../../../../../../../dto/forms/speech-treatment-form-dto';

@Component({
  selector: 'app-speech-treatment-form-view',
  templateUrl: './speech-treatment-form-view.component.html',
  styleUrls: ['./speech-treatment-form-view.component.scss']
})
export class SpeechTreatmentFormViewComponent implements OnInit {

  public formCode;
  public patientId;
  public formRef: string;
  public patientInformation: PatientInformation;
  public speechTreatmentFormDto: SpeechTreatmentFormDto;

  constructor(public patientService: PatientService,
              public formService: FormsService,
              public router: Router,) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);
    }, 500)
    setTimeout(() => {
      this.getSingleForm();
    }, 700)

  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
      .subscribe(data => {
          this.patientInformation = new PatientInformation();
          this.patientInformation.mapToPatientInformation(data);
          return this.patientInformation;
        },
        error => this.errorHandler(error));
  }

  getSingleForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
        this.speechTreatmentFormDto = new SpeechTreatmentFormDto();
        this.speechTreatmentFormDto.mapToSpeechTreatmentForm(data);
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  setFormDetails(formCode: string, patientId: number, formRef: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.formRef = formRef;
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
