import { FacilityLevel } from './../../../constant/facility-level.enum';
import { FacilityUpdate } from './../../../entity/facility-update';
import { FacilityService } from './../../../service/facility.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Facility } from 'src/app/entity/facility';
import { Country } from 'src/app/entity/country';
import { State } from 'src/app/entity/state';
import { Lga } from 'src/app/entity/lga';
import { GeneralUtil } from 'src/app/util/general-util';
import { CountryAndStateService } from 'src/app/service/country-and-state.service';
import { PaginationDataDto } from '../../../dto/pagination-data-dto';
import { GeneralEnum } from '../../../constant/general-enum.enum';
import { ResourceAllocationFormDto } from './../../../dto/forms/resource-allocation-form-dto';
import { FormsService } from './../../../service/forms.service';

declare function successToast(msg): any;
@Component({
  selector: 'app-facility-management',
  templateUrl: './facility-management.component.html',
  styleUrls: ['./facility-management.component.scss']
})
export class FacilityManagementComponent implements OnInit {
  public facilities: Facility[] = [];
  public singleFacility = new Facility();
  public countries: Country[] = [];
  public states: State[] = [];
  public lgas: Lga[] = [];
  public facility = new Facility();
  public showPreloader = false;
  public facilityLevel = FacilityLevel;
  public updatedFacility = new Facility();
  public paginationData = new PaginationDataDto();  // pagination object
  public status = true;
  public search = '';
  public resourceAllocationForm = new ResourceAllocationFormDto();
  public otherCleftSurgery = '';
  public otherCleft = false;
  public selectedCleft = [];
  public action: string;
  public cleftSurgery = ['Cleft Lip repair', 'Cleft Palate repair',
    'Orthognathic surgery', 'Rhinoplasty', 'Orthodontics', 'Fistula repair',
    'Velopharyngeal Dysfunction', 'Alveolar Bone Graft', 'Presurgical Infant Orthopedics', 'Other'];


  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @ViewChild('closeUpdateModal', { static: false }) closeUpdateModal: ElementRef;
  @ViewChild('closeForm', { static: false }) closeForm: ElementRef;


  constructor(
    public facilityService: FacilityService,
    public countryAndStateService: CountryAndStateService,
    public formService: FormsService
  ) { }

  ngOnInit() {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getCountries();
  }

  filterFacility() {
     // initialize pagination data
    this.initializePaginationData();

    this.getAllFacilities();
  }

  getAllFacilities() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.facilityService.getAllFacilities((currentPage - 1), defaultLimit, this.status, this.search, '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        this.paginationData.totalSize = data.total;

        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  toggleStatus(facilityId: number) {
    this.facilityService.toggleStatus(facilityId)
      .subscribe(data => {
        successToast('Status toggled successfully');
        /* Get the new Facility from Data response and map it to a facility object */
        this.updatedFacility = new Facility();
        this.updatedFacility.mapToFacility(data);
        /* find the index of that particular facility from the facility array
         and use it to replace that facility */
        const index = this.facilities.findIndex(facility => facility.id === this.updatedFacility.id);
        this.facilities[index] = this.updatedFacility;
      }),
      error1 => {
        this.errorHandler(error1);
      };
  }

  getFacility(facility: Facility) {
    this.singleFacility = new Facility();
    this.singleFacility = facility;
  }

  getCountries() {
    this.countries = [];
    this.countryAndStateService.getCountries()
      .subscribe(data => {
        if (data.length > 0) {
          const countryList = data;
          countryList.forEach(c => {
            const country = new Country();
            country.mapToCountry(c);
            this.countries.push(country);
          });
        }
      },
        error1 => this.errorHandler(error1));
  }

  getStates(facilityCountry: string) {
    // get id of country selected.
    const countryName = facilityCountry;
    if (!GeneralUtil.isValidString(countryName)) {
      return;
    }

    let countryId;
    this.countries.forEach(c => {
      if (c.name === countryName) {
        countryId = c.id;
      }
    });

    this.states = [];
    this.countryAndStateService.getStates(countryId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const state = new State();
            state.mapToState(c);
            this.states.push(state);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  getLgas(facilityState: string) {

    // get id of state selected.
    const stateName = facilityState;
    if (!GeneralUtil.isValidString(stateName)) {
      return;
    }

    let stateId;
    this.states.forEach(s => {
      if (s.name === stateName) {
        stateId = s.id;
      }
    });

    this.lgas = [];
    this.countryAndStateService.getLgas(stateId)
      .subscribe(data => {
        if (data.length > 0) {
          data.forEach(c => {
            const lga = new Lga();
            lga.mapToLga(c);
            this.lgas.push(lga);
          });
        }
      },
        error1 => {
          this.errorHandler(error1);
        });

  }

  registerFacility() {
    this.showPreloader = true;

    this.facilityService.registerFacility(this.facility)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Facility created successfully!');
        setTimeout(() => {
          this.facility = new Facility();
          this.closeModal.nativeElement.click();
          this.getAllFacilities();
        }, 1000);
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });

  }

  updateFacility() {
    this.showPreloader = true;
    const facilityUpdate = new FacilityUpdate();
    facilityUpdate.mapToFacility(this.singleFacility);

    this.facilityService.updateFacility(facilityUpdate)
      .subscribe(data => {
        this.showPreloader = false;
        successToast('Facility updated successfully!');
        /* Get the new Facility from Data response and map it to a facility object */
        this.updatedFacility = new Facility();
        this.updatedFacility.mapToFacility(data);
        /* find the index of that particular facility from the facility array
         and use it to replace that facility */
        const index = this.facilities.findIndex(facility => facility.id === this.updatedFacility.id);
        this.facilities[index] = this.updatedFacility;
        setTimeout(() => {
          this.closeUpdateModal.nativeElement.click();
        }, 1000);
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  getResourceAllocationForm(facility) {
    this.getFacility(facility);

    this.formService.getResourceAllocationForm(this.singleFacility.id)
      .subscribe(data => {
        if (data) {
          if (data.facilityId) {
            this.action = 'update'
            this.resourceAllocationForm.mapToResourceAllocationForm(data);
          } else {
            this.action = 'create'
            this.resourceAllocationForm = new ResourceAllocationFormDto();
          }
        }
      }, error1 => {
        this.errorHandler(error1);
    })
  }

  onCleftSelect(cleft) {
    // selected cleft surgery
    if (cleft.checked) {
      if (cleft.value == 'Other') {
        this.otherCleft = true;
      }
      if (this.selectedCleft.includes(cleft.value) === false && cleft.value !== 'Other') {
        this.selectedCleft.push(cleft.value)
      }
      // unselected cleft surgery
    } else {

      this.selectedCleft.forEach(c => {
        if (c == cleft.value) {
          if (c == cleft.value && cleft.value == 'Other') {
            this.otherCleft = false;
          }
          const index = this.selectedCleft.indexOf(c);
          if (index > -1) {
            this.selectedCleft.splice(index, 1);
          }
        }
      })
    }
  }

  submitResourceAllocation() {
    this.showPreloader = true;
    this.resourceAllocationForm.facilityId = this.singleFacility.id;

    if (this.action == 'create') {
      this.resourceAllocationForm.typeOfCleftSurgery = [...this.selectedCleft, this.otherCleftSurgery];
      this.formService.registerResourceAllocationForm(this.resourceAllocationForm)
        .subscribe(data => {
          this.showPreloader = false;
          successToast('Form Submitted Successfully');
          this.closeForm.nativeElement.click();
          this.resourceAllocationForm = new ResourceAllocationFormDto();
      },
      error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      })
    }

    if (this.action == 'update') {
      this.resourceAllocationForm.typeOfCleftSurgery = [];
      this.resourceAllocationForm.typeOfCleftSurgery = [...this.selectedCleft, this.otherCleftSurgery];

      this.formService.updateResourceAllocationForm(this.resourceAllocationForm)
        .subscribe(data => {
          this.showPreloader = false;
          successToast('Form Updated Successfully');
          this.closeForm.nativeElement.click();
          this.resourceAllocationForm = new ResourceAllocationFormDto();
      },
      error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      })
    }

  }

    /*********PAGINATION FUNCTIONS***************/
    initializePaginationData() {
      this.paginationData.currentPage = 1;
      this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
      this.paginationData.totalSize = 100;
    }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
