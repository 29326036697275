import { GeneralUtil } from '../../util/general-util'

export class SpeechTreatmentFormDto {
  public patientRef: string;
  public firstName: string;
  public lastName: string;
  public clinicianName: string;
  public comments: string;
  public recommendation: string;
  public status: string;
  public treatment: string;
  public createdAt: string;

  public mapToSpeechTreatmentForm(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.patientRef = data.patientRef;
      this.firstName = data.patientFirstName;
      this.lastName = data.patientLastName;
      this.clinicianName = data.clinicianName;
      this.comments = data.comments;
      this.recommendation = data.recommendation;
      this.status = GeneralUtil.removeUnderscore(data.status);
      this.treatment = data.treatment;
      this.createdAt = data.createdAt;
      }
  }
}
