import { GeneralUtil } from "src/app/util/general-util";
 
export class Forms {
    
    public name: string;
    public code: string;
    public stage: string;
    public status: string;

    constructor(){ }

    public mapToForms(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.code = data.code;
            this.name = GeneralUtil.removeUnderscore(data.name);
            this.stage = GeneralUtil.removeUnderscore(data.stage);
            this.status = data.status;
        }
    }


}