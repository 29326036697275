import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientDetails } from 'src/app/entity/patient-details-dto';
import { AuthService } from 'src/app/service/auth/auth.service';
import { FormsService } from 'src/app/service/forms.service';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { FormCode } from 'src/app/constant/form-code-enum';
import { FormLogDto } from '../../../../dto/form-log-dto';
import { FacilityService } from '../../../../service/facility.service';
import { Facility } from '../../../../entity/facility';
import { Forms } from './../../../../entity/form';

declare function successToast(msg): any;

@Component({
  selector: 'app-view-patient-information',
  templateUrl: './view-patient-information.component.html',
  styleUrls: ['./view-patient-information.component.scss']
})
export class ViewPatientInformationComponent implements OnInit {
  public id: any;
  public showPreloader = false;
  public patient: PatientDetails;
  public treatmentStage: String;
  public patientNextStage: string;
  public forms: Forms[] = [];
  public otherForms: Forms[] = [];
  public formLogs: FormLogDto[] = [];
  public facilities: Facility[] = [];
  public newFacility: number;

  @ViewChild('closeFormModal', { static: false }) closeFormModal: ElementRef;
  @ViewChild('closeFormLogModal', { static: false }) closeFormLogModal: ElementRef;
  @ViewChild('closeDischargeModal', { static: false }) closeDischargeModal: ElementRef;
  @ViewChild('closeStageModal', { static: false }) closeStageModal: ElementRef;
  @ViewChild('closeDeleteModal', { static: false }) closeDeleteModal: ElementRef;
  @ViewChild('closeTransferModal', { static: false }) closeTransferModal: ElementRef;
  @ViewChild('costOfCare', { static: false }) costOfCare: ElementRef;
  @ViewChild('costOfCarePrompt', { static: false }) costOfCarePrompt: ElementRef;


  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public patientService: PatientService,
    public router: Router,
    public facilityService: FacilityService,
    public formService: FormsService

  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('patientId');
    this.getPatient(this.id);
    this.checkTreatmentStage();
    this.getFormsByPatientId();
    this.getFormLogs();
    setTimeout(() => {
      this.getAllFacilities();
    }, 500)
  }

  getPatient(id) {
    this.showPreloader = true;
    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patient = new PatientDetails();
        this.patient.mapToPatientDetails(data);
        this.showPreloader = false;
        return this.patient;
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  convertDateTimeToDateformat(date) {
    return GeneralUtil.convertDateTimeToDateformat(date);
  }

  formRegistration(formCode, formRef) {
    this.closeFormModal.nativeElement.click();
    this.router.navigate(['/form-registration/' + formCode + '/' + formRef + '/' + this.id]);
  }

  formUpdate(formCode, formRef) {
    this.closeFormLogModal.nativeElement.click();
    this.router.navigate(['/form-update/' + formCode + '/' + formRef + '/' + this.id]);
  }

  formView(formCode, formRef) {
    this.closeFormLogModal.nativeElement.click();
    this.router.navigate(['/form-view/' + formCode + '/' + formRef + '/' + this.id]);
  }

  removeUnderscore(underscoreWord) {
    return GeneralUtil.removeUnderscore(underscoreWord);
  }

  switchBoolean(input: boolean): string {
    switch (input) {
      case (true):
        return 'Yes';
      case (false):
        return 'No';
    }
  }

  checkTreatmentStage() {
    this.patientService.getPatientTreatmentStage(this.id)
      .subscribe(data => {
        this.treatmentStage = data;
        this.checkStage();
      })
  }

  changeStage() {
    this.patientService.movePatientToNextStage(this.id)
      .subscribe(data => {
        this.closeStageModal.nativeElement.click();
        successToast('Patient Moved Successfully to ' + this.patientNextStage);
        this.checkTreatmentStage();
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  dischargePatient() {
    const patientStatus = "DISCHARGED"
    this.patientService.changePatientStatus(this.patient.patientRef, patientStatus)
      .subscribe(data => {
        this.closeDischargeModal.nativeElement.click();
        successToast('Patient Discharged Successfully');
        setTimeout(() => {
          this.costOfCarePrompt.nativeElement.click();
        }, 500)
      },
        error1 => {
          this.errorHandler(error1);
        });
  }

  deletePatient() {
    const patientStatus = 'DELETED';
    this.patientService.changePatientStatus(this.patient.patientRef, patientStatus)
      .subscribe(data => {
        this.closeDeleteModal.nativeElement.click();
        successToast('Patient Deleted Successfully');
        this.router.navigate(['/report/patient-information'])
    },
    error1 => {
      this.errorHandler(error1);
    });
  }

  checkStage() {
    switch (this.treatmentStage) {
      case 'PRE SURGERY':
        this.patientNextStage = 'Surgery';
        break;
      case 'SURGERY':
        this.patientNextStage = 'Post Surgery';
        break;
      case 'POST SURGERY':
        this.patientNextStage = 'Complete';
        break;
      default:
    }
  }

  getFormsByPatientId() {
    this.formService.getFormsByPatientId(this.id)
      .subscribe(data => {
        this.forms = [];
        const formsArray = data;
        formsArray.forEach(f => {
          const form = new Forms();
          form.mapToForms(f);
          // check if form is mortality
          if (form.code !== FormCode.MORTALITY_CODE) {
            this.forms.push(form);
          } else {
            this.otherForms.push(form);
          }
        }
        );
        // add mortality as the last form in the array
        if (this.otherForms.length > 0) {
          this.forms.push(this.otherForms[0]);
        }
      },
        error1 => {
          this.errorHandler(error1);
        }
      );
  }

  getFormLogs() {
    this.formService.getLogOfFormsFilledByPatient(this.id)
      .subscribe(data => {
          this.formLogs = [];
          const formsArray = data;
          formsArray.forEach(f => {
            const form = new FormLogDto();
            form.mapToForms(f);
            this.formLogs.push(form);
          });
        },
        error1 => {
          this.errorHandler(error1);
        }
      );
  }

  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
          // Remove the originating facility from the facility list
          this.facilities = this.facilities.filter(f => {
            return f.id !== this.patient.facility.id
          })
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  transferPatient() {
    this.patientService.transferPatient(this.patient.patientRef, this.newFacility)
      .subscribe(data => {
        this.getPatient(this.id);
        // on transfer success run getAllFacility() to remove the new selected facility from list
        setTimeout(() => {
          this.closeTransferModal.nativeElement.click();
          this.getAllFacilities();
          successToast('Patient Transferred Successfully');
        }, 500);

    },
    error1 => {
      this.showPreloader = false;
      this.errorHandler(error1);
    })
  }

  goToCostOfCare() {
    this.costOfCare.nativeElement.click();
    this.router.navigate(['cost-of-care/' + this.id]);
  }
  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
