
import {Router, ActivatedRoute} from '@angular/router';
import { RoleService } from './../../../../service/role.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Role } from './../../../../entity/role';
import { AuthService } from './../../../../service/auth/auth.service';
import { Permission } from './../../../../entity/permission';
import { Status } from './../../../../constant/status.enum';
import { GeneralUtil } from '../../../../util/general-util';



declare function successToast(msg): any;
declare function failureToast(msg): any;

 @Component({
  selector: 'app-permissions-page',
  templateUrl: './permissions-page.component.html',
  styleUrls: ['./permissions-page.component.scss']
})
export class PermissionsPageComponent implements OnInit {
  // variables
  public roles = [];
   public roleId: any;
   public showPreloader = false
  public permissions: Permission[]=[];
  public newRole = new Role();
  public status = Status;
  public selectedPermissions =[]
  @ViewChild('close') close: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;


  constructor(public roleService : RoleService,
              public authService: AuthService,
              public router: Router,
              public route: ActivatedRoute) { }

 ngOnInit() {
    // get role id
    this.roleId = this.route.snapshot.paramMap.get('roleId');

   setTimeout(() => {
    this.getRoleToBeUpdated(this.roleId);
    }, 2000)
    // get roles
     this.getRoles()

     //  get permissions
    this.getPermission()
     }

    getRoles() {
      this.roleService.getRoles()
        .subscribe(data => {
          const roleArray = data;
          if (roleArray.length > 0) {
            roleArray.forEach(r => {
              const role = new Role();
              role.mapToRole(r);
              this.roles.push(role);
            });
          }
      }, error1 => {
        this.errorHandler(error1);
      })
    }

    removeUnderscore(mystring: string): string {
          return mystring.replace(/_/g, ' ');
      }

   updateRole() {
      this.showPreloader = true;
      this.newRole.permissions = this.selectedPermissions;
      this.roleService.updateRole(this.newRole, this.newRole.id)
        .subscribe(data => {
          this.showPreloader = false;
          successToast('Role updated successfully');
          setTimeout(() => {
            this.router.navigate(['/role-management']);
          }, 1000)
        }, error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        })

  }

getRoleToBeUpdated(roleId: number){
  this.selectedPermissions = [];
  this.roleService.getRoleById(roleId)
  .subscribe(data => {
    this.newRole.mapToRole(data);
    this.newRole.permissions.forEach(p => {
      if (!this.selectedPermissions.includes(p.id)) {
        this.selectedPermissions.push(p.id)
      }
    });
    this.permissions.forEach(permis => this.newRole.permissions.forEach(f => {
      if (f.id === permis.id) {
        permis.selected = true;
      }
    }))
  },   error1 => {
    this.errorHandler(error1);
  })
}

  onCheck(event, permission) {
    // selected checkboxes
    if (event.target.checked) {
        permission.selected = true;
       if (this.selectedPermissions.includes(Number(event.target.value)) === false) {
         this.selectedPermissions.push(Number(event.target.value))
      }

    } else {
       //  unselected boxes
      permission.selected = false;

       this.selectedPermissions.forEach(e => {
         if (e == event.target.value) {
           const index = this.selectedPermissions.indexOf(e);

           if (index > -1) {
             this.selectedPermissions.splice(index, 1);
           }
         }
       });
     }
   }

 getPermission() {
   this.showPreloader = true;
   this.roleService.getPermissions()
      .subscribe(data => {
        this.permissions=[];
        this.showPreloader = false;
          if (data.length > 0) {
            data.forEach(p => {
              const rol = new Permission();
              rol.mapToPermission(p);
              this.permissions.push(p);
               });
          }
      },
      error1 => {
        this.showPreloader = false;
        this.errorHandler(error1);
      });
}

 // Error handling
 errorHandler(error) {
  return GeneralUtil.errorHandler(error)
}

}
