import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientInformation } from 'src/app/entity/patient-information';
import { FormsService } from 'src/app/service/forms.service';
import { PatientService } from 'src/app/service/patient.service';
import { RehabilitationFormDto } from './../../../../../../../dto/forms/rehabilitation-form-dto';
import { GeneralUtil } from 'src/app/util/general-util';


@Component({
  selector: 'app-rehabilitation-form-view',
  templateUrl: './rehabilitation-form-view.component.html',
  styleUrls: ['./rehabilitation-form-view.component.scss']
})
export class RehabilitationFormViewComponent implements OnInit {
  public formCode;
  public patientId;
  public formRef: string;
  public patientInformation: PatientInformation;
  public rehabilitationFormDto: RehabilitationFormDto


  constructor(public patientService: PatientService,
    public formService: FormsService,
    public router: Router,) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);

      this.getSingleForm();
    }, 500)

  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => this.errorHandler(error));
  }

  getSingleForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
        this.rehabilitationFormDto = new RehabilitationFormDto();
        this.rehabilitationFormDto.mapToRehabilitation(data);
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  setFormDetails(formCode: string, patientId: number, formRef: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.formRef = formRef;
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
