import {Component, OnInit} from '@angular/core';
import {FormCode} from '../../../../constant/form-code-enum';
import {MortalityFormDto} from '../../../../dto/forms/mortality-form-dto';
import {FormsService} from '../../../../service/forms.service';
import { GeneralUtil } from '../../../../util/general-util';
import { FacilityService } from '../../../../service/facility.service';
import { Facility } from '../../../../entity/facility';
import { PaginationDataDto } from '../../../../dto/pagination-data-dto';
import { GeneralEnum } from '../../../../constant/general-enum.enum';
import { FilterDto } from './../../../../dto/filter-dto';

@Component({
  selector: 'app-mortality-form',
  templateUrl: './mortality-form.component.html',
  styleUrls: ['./mortality-form.component.scss']
})

export class MortalityFormComponent implements OnInit {
  public showPreloader = false;
  public formCode = FormCode;
  public facilities: Facility[] = [];
  public mortalityForms: MortalityFormDto[] = [];
  public paginationData = new PaginationDataDto();  // pagination object
  public filterDto = new FilterDto();

  constructor(public formService: FormsService, public facilityService: FacilityService) {
  }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getMortalityForms();
  }

  filterForm() {
    this.initializePaginationData();

    this.getMortalityForms()
  }

  getMortalityForms() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.formService.getForms(this.formCode.MORTALITY_CODE, this.filterDto.facility, (currentPage - 1), defaultLimit, this.filterDto.search)
      .subscribe(data => {
          this.mortalityForms = [];
          this.showPreloader = false;
          const mortalityFormsArray = data.entities;
          this.paginationData.totalSize = data.total;
          if (mortalityFormsArray !== null && mortalityFormsArray.length > 0) {
            mortalityFormsArray.forEach(m => {
              const mortality = new MortalityFormDto();
              mortality.mapToMortalityForm(m);
              this.mortalityForms.push(mortality);
            })
          }
        },
        error1 => {
          this.errorHandler(error1);
          this.showPreloader = false;
        })
  }

  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  export2csv() {
    return GeneralUtil.export2csv('mortality-form-report.csv');
  }

  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

  // Error Handling

  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }
}
