import { GeneralUtil } from './../../../util/general-util';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PatientInformation } from './../../../entity/patient-information';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CleftType } from './../../../entity/cleft-type';
import { PierreRobinAdditionalFeature } from './../../../entity/pierre-robin-additional-feature';


declare function successToast(msg: string): any;
declare function failureToast(msg): any;


@Component({
  selector: 'app-page-three',
  templateUrl: './page-three.component.html',
  styleUrls: ['./page-three.component.scss']
})
export class PageThreeComponent implements OnInit {

  // variables
  public patientInformation: PatientInformation;
  @Output() pageThreeCompleted = new EventEmitter<string>();
  @Output() previousPage = new EventEmitter<string>();
  @Output() toPageOne = new EventEmitter<string>();
  public cleft: CleftType[] = [];
  cleftTypeList = [];
  cleftTypes = [];
  dropdownSettings: IDropdownSettings = {};
  pierreRobinList = [];
  pierreRobinTypes = [];


  constructor() { }

  ngOnInit(): void {

    if (sessionStorage.getItem('patientInformation')) {
      this.patientInformation = JSON.parse(sessionStorage.getItem('patientInformation'))
    } else {
      this.gotoPageOne();
    }

    // get list of cleft types
    this.cleftTypeList = [
      { item_id: 1, item_text: 'Cleft lip' },
      { item_id: 2, item_text: 'Cleft palate' },
      { item_id: 3, item_text: 'Alveolar cleft' },
      { item_id: 4, item_text: 'Submuccous Cleft' },
      { item_id: 5, item_text: 'None' },
      { item_id: 6, item_text: 'Unknown' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true

    };

    // get list of pierre robin
    this.pierreRobinList = [
      { item_id: 1, item_text: 'Respiratory Disease' },
      { item_id: 2, item_text: 'Micrognathia' },
      { item_id: 3, item_text: 'Glossoptosis' },
      { item_id: 4, item_text: 'Unknown' },
      { item_id: 5, item_text: 'None' },
      { item_id: 6, item_text: 'Other' }
    ];
  }

  //  go to previous page
  toPreviousPage(event: Event) {
    this.previousPage.emit('two');
    event.preventDefault();
  }

  addCleftType() {
    const cleftTypeList: CleftType[] = [];
    this.cleftTypes.forEach(c => {
      const cleftT = new CleftType();
      cleftT.type = c.item_text;
      cleftTypeList.push(cleftT);
    });
    this.patientInformation.patientData.cleftTypes = cleftTypeList;

  }


  addPierreRobin() {
    const pierreRobinList: PierreRobinAdditionalFeature[] = [];
    this.pierreRobinTypes.forEach(p => {
      const piereR = new PierreRobinAdditionalFeature();
      piereR.name = p.item_text;
      pierreRobinList.push(piereR);
    });
    this.patientInformation.patientData.pierreRobinAdditionalFeatures = pierreRobinList;
   }

  gotoPageOne() {
    this.toPageOne.emit('one');
  }

  // go to next-pge
  pageThreeFinished(event: Event) {

    // validate form
    // 1. validate gestational age
    if (GeneralUtil.isValidString(this.patientInformation.patientData.gestationalAgeInWeeks)) {
      const gestationAge = Number(this.patientInformation.patientData.gestationalAgeInWeeks);
      if (gestationAge < 24 || gestationAge > 41) {
        failureToast('Gestational age must fall between 24 and 41 weeks');
        return;
      }
    }
    // 2. validate patient weight
    if (GeneralUtil.isValidString(this.patientInformation.patientData.birthWeightInKg)) {
      const birthWeight = Number(this.patientInformation.patientData.birthWeightInKg);
      if (birthWeight < 2 || birthWeight > 200) {
        failureToast('The birth weight must be between 2kg and 200kg');
        return;
      }
    }
    sessionStorage.setItem('patientInformation', JSON.stringify(this.patientInformation))
    this.pageThreeCompleted.emit('three');
    event.preventDefault();
  }

}
