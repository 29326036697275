import { Component, OnInit } from '@angular/core';
import { FormCode } from 'src/app/constant/form-code-enum';
import { SpeechAssessmentFormDto } from 'src/app/dto/forms/speech-assessment-form-dto';
import { FormsService } from 'src/app/service/forms.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { FacilityService } from '../../../../service/facility.service';
import { Facility } from '../../../../entity/facility';
import { PaginationDataDto } from '../../../../dto/pagination-data-dto';
import { GeneralEnum } from '../../../../constant/general-enum.enum';
import { FilterDto } from './../../../../dto/filter-dto';

@Component({
  selector: 'app-speech-assessment-form',
  templateUrl: './speech-assessment-form.component.html',
  styleUrls: ['./speech-assessment-form.component.scss']
})
export class SpeechAssessmentFormComponent implements OnInit {
  public showPreloader = false;
  public formCode = FormCode;
  public facilities: Facility[] = [];
  public speechAssessmentForms = [];
  public paginationData = new PaginationDataDto();  // pagination object
  public filterDto = new FilterDto();


  constructor(private formService: FormsService, public facilityService: FacilityService) { }

  ngOnInit(): void {
    this.initializePaginationData();
    this.getAllFacilities();
    this.getSpeechAssessmentForms()
  }

  filterForm() {
    this.initializePaginationData();

    this.getSpeechAssessmentForms();
  }

  getSpeechAssessmentForms() {
    this.showPreloader = true;
    const defaultLimit = this.paginationData.defaultLimit;
    const currentPage = this.paginationData.currentPage;

    this.formService.getForms(this.formCode.SPEECH_ASSESSMENT_CODE, this.filterDto.facility, (currentPage - 1),
      defaultLimit, this.filterDto.search)
      .subscribe(data => {
        this.speechAssessmentForms = [];
        this.showPreloader = false;
        const speechAssessmentFormsArray = data.entities;
        this.paginationData.totalSize = data.total;
        if (speechAssessmentFormsArray !== null && speechAssessmentFormsArray.length > 0) {
          speechAssessmentFormsArray.forEach(s => {
            const speechAssessment = new SpeechAssessmentFormDto();
            speechAssessment.mapFormToData(s);
            this.speechAssessmentForms.push(speechAssessment);
          })
        }
      },
        error1 => {
          this.errorHandler(error1);
          this.showPreloader = false;
        });
  }

  getAllFacilities() {
    this.showPreloader = true;

    this.facilityService.getAllFacilities('', 500, true, '', '')
      .subscribe(data => {
        this.showPreloader = false;
        this.facilities = [];
        if (data.total > 0) {
          const facilityArray = data.entities;
          facilityArray.forEach(facil => {
            const facility = new Facility();
            facility.mapToFacility(facil);
            this.facilities.push(facility);
          });
        }
      },
        error1 => {
          this.showPreloader = false;
          this.errorHandler(error1);
        });
  }

  /*********PAGINATION FUNCTIONS***************/
  initializePaginationData() {
    this.paginationData.currentPage = 1;
    this.paginationData.defaultLimit = GeneralEnum.paginationLimit;
    this.paginationData.totalSize = 100;
  }

  export2csv() {
    return GeneralUtil.export2csv('speech-assessment-report.csv');
  }

  // Error Handling

  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
   }

}
