import { GeneralUtil } from '../util/general-util';
import { Facility } from './facility';

export class FacilityUpdate {
    public facilityLevel: string;
    public name: string;
    public facilityId: number;

    public mapToFacility(data: Facility) {

        if (GeneralUtil.isValidJSON(data)) {

            this.name = data.name;
            this.facilityLevel = data.facilityLevel;
            this.facilityId = data.id;
        }
    }
}
