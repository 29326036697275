import {GeneralUtil} from '../util/general-util';

export class Malformation {
    public details: string;

    constructor() {}

    public mapToMalformation(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.details = data.details;
        }
    }
}
