import { MortalityFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/mortality-forms/mortality-forms.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { SideMenuComponent } from './component/tags/side-menu/side-menu.component';
import { HeaderComponent } from './component/tags/header/header.component';
import { AuthGuard } from './service/auth/auth.guard';
import { AuthService } from './service/auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './service/auth/token-interceptor.service';
import { FormsModule } from '@angular/forms';
import { FacilityManagementComponent } from './component/setup/facility-management/facility-management.component';
import { PatientInformationComponent } from './component/report/patient-information/patient-information.component';
import { FormManagementComponent } from './component/setup/form-management/form-management.component';
import { RoleManagementComponent } from './component/setup/role-management/role-management.component';
import { PermissionsPageComponent } from './component/setup/role-management/permissions-page/permissions-page.component';
import { UserManagementComponent } from './component/setup/user-management/user-management.component';
import { ViewPatientInformationComponent } from './component/report/patient-information/view-patient-information/view-patient-information.component';
import { MortalityFormComponent } from './component/report/forms-view-module/mortality-form/mortality-form.component';
import { FormsViewModuleComponent } from './component/report/forms-view-module/forms-view-module.component';
import { PageOneComponent } from './component/patient-registration/page-one/page-one.component';
import { PatientRegistrationComponent } from './component/patient-registration/patient-registration.component';
import { NutritionFormComponent } from './component/report/forms-view-module/nutrition-form/nutrition-form.component';
import { SpeechTreatmentFormComponent } from './component/report/forms-view-module/speech-treatment-form/speech-treatment-form.component';
import { SpeechAssessmentFormComponent } from './component/report/forms-view-module/speech-assessment-form/speech-assessment-form.component';
import { SurgicalTreatmentFormComponent } from './component/report/forms-view-module/surgical-treatment-form/surgical-treatment-form.component';
import { OrthodonticTreatmentFormComponent } from './component/report/forms-view-module/orthodontic-treatment-form/orthodontic-treatment-form.component';
import { RehabilitationFormComponent } from './component/report/forms-view-module/rehabilitation-form/rehabilitation-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageTwoComponent } from './component/patient-registration/page-two/page-two.component';
import { ComplicationFormComponent } from './component/report/forms-view-module/complication-form/complication-form.component';
import { PageThreeComponent } from './component/patient-registration/page-three/page-three.component';
import { PageFourComponent } from './component/patient-registration/page-four/page-four.component';
import { PageFiveComponent } from './component/patient-registration/page-five/page-five.component';
import { PageFiveUpdateComponent } from './component/patient-reg-update/page-five-update/page-five-update.component';
import { PageOneUpdateComponent } from './component/patient-reg-update/page-one-update/page-one-update.component';
import { PageTwoUpdateComponent } from './component/patient-reg-update/page-two-update/page-two-update.component';
import { PageThreeUpdateComponent } from './component/patient-reg-update/page-three-update/page-three-update.component';
import { PageFourUpdateComponent } from './component/patient-reg-update/page-four-update/page-four-update.component';
import { FormRegistrationComponent } from './component/report/patient-information/view-patient-information/forms/form-registration/form-registration.component';
import { SpeechTreatmentFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/speech-treatment-forms/speech-treatment-forms.component';
import { ComplicationFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/complication-forms/complication-forms.component';
import { SurgicalTreatmentFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/surgical-treatment-forms/surgical-treatment-forms.component';
import { RehabilitationFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/rehabilitation-forms/rehabilitation-forms.component';
import { SpeechAssessmentFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/speech-assessment-forms/speech-assessment-forms.component';
import { OrthodonticTreatmentFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/orthodontic-treatment-forms/orthodontic-treatment-forms.component';
import { NutritionFormsComponent } from './component/report/patient-information/view-patient-information/forms/form-module/nutrition-forms/nutrition-forms.component';
import { FormUpdateComponent } from './component/report/patient-information/view-patient-information/forms/form-update/form-update.component';
import { FormViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/form-view.component';
import { SurgicalTreatmentViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/surgical-treatment-view/surgical-treatment-view.component';
import { NutritionFormViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/nutrition-form-view/nutrition-form-view.component';
import { RehabilitationFormViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/rehabilitation-form-view/rehabilitation-form-view.component';
import { SpeechAssessmentViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/speech-assessment-view/speech-assessment-view.component';
import { OrthodonticTreatmentViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/orthodontic-treatment-view/orthodontic-treatment-view.component';
import { SpeechTreatmentFormViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/speech-treatment-form-view/speech-treatment-form-view.component';
import { MortalityFormViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/mortality-form-view/mortality-form-view.component';
import { ComplicationFormViewComponent } from './component/report/patient-information/view-patient-information/forms/form-view/complication-form-view/complication-form-view.component';
import { CompletePatientInfoComponent } from './component/report/complete-patient-info/complete-patient-info.component';
import { CostOfCareComponent } from './component/report/patient-information/view-patient-information/forms/cost-of-care/cost-of-care.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    SideMenuComponent,
    HeaderComponent,
    FacilityManagementComponent,
    UserManagementComponent,
    PatientInformationComponent,
    FormManagementComponent,
    RoleManagementComponent,
    PermissionsPageComponent,
    ViewPatientInformationComponent,
    PatientRegistrationComponent,
    MortalityFormComponent,
    FormsViewModuleComponent,
    NutritionFormComponent,
    SpeechTreatmentFormComponent,
    SpeechAssessmentFormComponent,
    SurgicalTreatmentFormComponent,
    OrthodonticTreatmentFormComponent,
    RehabilitationFormComponent,
    ComplicationFormComponent,
    PageOneComponent,
    PageTwoComponent,
    PageThreeComponent,
    PageFourComponent,
    PageFiveComponent,
    PageFiveUpdateComponent,
    PageOneUpdateComponent,
    PageTwoUpdateComponent,
    PageThreeUpdateComponent,
    PageFourUpdateComponent,
    FormRegistrationComponent,
    MortalityFormsComponent,
    SpeechTreatmentFormsComponent,
    ComplicationFormsComponent,
    SurgicalTreatmentFormsComponent,
    RehabilitationFormsComponent,
    SpeechAssessmentFormsComponent,
    OrthodonticTreatmentFormsComponent,
    NutritionFormsComponent,
    FormUpdateComponent,
    FormViewComponent,
    SurgicalTreatmentViewComponent,
    OrthodonticTreatmentViewComponent,
    CompletePatientInfoComponent,
    NutritionFormViewComponent,
    RehabilitationFormViewComponent,
    SpeechAssessmentViewComponent,
    OrthodonticTreatmentViewComponent,
    SpeechTreatmentFormViewComponent,
    MortalityFormViewComponent,
    ComplicationFormViewComponent,
    CostOfCareComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
