import {Component, OnInit} from '@angular/core';
import {PatientInformation} from '../../../../../../../entity/patient-information';
import {SpeechTreatmentFormDto} from '../../../../../../../dto/forms/speech-treatment-form-dto';
import {FormsDto} from '../../../../../../../dto/forms/forms-dto';
import {GeneralUtil} from '../../../../../../../util/general-util';
import {PatientService} from '../../../../../../../service/patient.service';
import {Router} from '@angular/router';
import {FormsService} from '../../../../../../../service/forms.service';

declare function successToast(msg): any;

@Component({
  selector: 'app-speech-treatment-forms',
  templateUrl: './speech-treatment-forms.component.html',
  styleUrls: ['./speech-treatment-forms.component.scss']
})
export class SpeechTreatmentFormsComponent implements OnInit {

  // variables
  public action: string;
  public formRef: string;
  public formCode: string;
  public patientId: number;
  public showPreloader = false;
  public showFormPreloader = false;
  public patientInformation: PatientInformation;
  public speechTreatmentForm = new SpeechTreatmentFormDto();
  public formsDto = new FormsDto();


  constructor(
    public router: Router,
    public patientService: PatientService,
    public formService: FormsService
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);

      if (this.action === 'update') {
        this.getSingleSpeechTreatmentForm();
      }
    }, 500);
  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
      .subscribe(data => {
          this.patientInformation = new PatientInformation();
          this.patientInformation.mapToPatientInformation(data);
          return this.patientInformation;
        },
        error => this.errorHandler(error));

  }

  convertDateTimeToDateformat(date) {
    return GeneralUtil.convertDateTimeToDateformat(date);
  }

  getSingleSpeechTreatmentForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
          this.speechTreatmentForm = new SpeechTreatmentFormDto();
          this.speechTreatmentForm.mapToSpeechTreatmentForm(data);

        },
        error1 => {
          this.errorHandler(error1);
        })
  }


  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  submitForm() {
    this.showFormPreloader = true;
    this.formsDto.code = this.formCode;
    this.formsDto.speechTreatmentFormDto = this.speechTreatmentForm;

    /* Registration */
    if (this.action === 'registration') {
      this.formsDto.patientId = this.patientId;

      // hit the submit service

      this.formService.formRegistration(this.formsDto)
        .subscribe(data => {
          this.showFormPreloader = false;
          successToast('Speech Treatment Form submitted successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, error1 => {
          this.showFormPreloader = false;
          this.errorHandler(error1);
        });
      return;
    }

    /* Update */
    if (this.action === 'update') {

      // hit the update service
      this.formService.formUpdate(this.formsDto, this.formRef)
        .subscribe(data => {
          this.showFormPreloader = false;
          successToast('Form updated successfully');
          this.router.navigate(['/report/view-patient-information/' + this.patientId]);
        }, error1 => {
          this.showFormPreloader = false;
          this.errorHandler(error1);
        });
      return;
    }

  }

  // Error handling
  public errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
