import { Address } from './address';
import { MaternalData } from './maternal-data';
import { PatientData } from './patient-data';
import { PaternalData } from './paternal-data';
import { GeneralUtil } from '../util/general-util';
import { Facility } from '../entity/facility';
import { ParentGuardianInfo } from './parent-guardian-info';

export class PatientInformation {
    public tempId: string;
    public id: number;
    public patientRef: string;
    public firstName: string;
    public lastName: string;
    public otherNames: string;
    public status: string;
    public dateOfBirth: string;
    public gender: string;

    public tribe: string;
    public otherTribe: string;

    public phone: string;
    public otherPhone: string;
    public race: string;
    public otherRace: string;
    public howDidYouHearAboutSmileTrain: string;
    public hasReleaseFormBeenSigned: string;
    public partner: string;
    public facilityId: number;
    public surgeryId: number;

    public address = new Address();
    public facility = new Facility();
    public maternalData = new MaternalData();
    public patientData = new PatientData();
    public paternalData = new PaternalData();
    public parentGuardianInfo: ParentGuardianInfo[] = [];

    constructor() { }

    public mapToPatientInformation(data: any) {
        if (GeneralUtil.isValidJSON(data)) {
            this.tempId = data.tempId;
            this.id = data.id;
            this.patientRef = data.patientRef;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.otherNames = data.otherNames;
            this.status = data.status;

            this.dateOfBirth = data.dateOfBirth;

            this.gender = data.gender;
            this.tribe = data.tribe;
            this.otherTribe = data.otherTribe;
            this.phone = data.phone;
            this.otherPhone = data.otherPhone;
            this.race = data.race;
            this.otherRace = data.otherRace;
            this.howDidYouHearAboutSmileTrain = data.howDidYouHearAboutSmileTrain;
            this.hasReleaseFormBeenSigned = data.hasReleaseFormBeenSigned;
            this.partner = data.partner;
            this.facilityId = data.facilityId;
            this.surgeryId = data.surgeryId;


            // set address
            this.address.mapToAddress(data.address);

            //  set facility
            this.facility.mapToFacility(data.facility);

            // set maternalData
            this.maternalData.mapToMaternalData(data.maternalData);

            // set patientData
            this.patientData.mapToPatientData(data.patientData);

            // set paternalData
            this.paternalData.mapToPaternalData(data.paternalData);

            // set parentGuardianInfo
            if (data.parentGuardianInfo != null && data.parentGuardianInfo.length > 0) {
                const parentGuardianArray = data.parentGuardianInfo;
                parentGuardianArray.forEach(p => {
                    const parentGuardianInfo = new ParentGuardianInfo();
                    parentGuardianInfo.mapToParentGuardianInfo(p);
                    this.parentGuardianInfo.push(parentGuardianInfo);
                });
            }


        }
    }

}

