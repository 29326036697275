import { GeneralUtil } from '../../util/general-util';
import { OrthodonticTreatmentType } from './orthodontic-treatment-type-dto';


export class OrthodonticTreatmentFormDto{
  public patientRef: string;
  public firstName: string;
  public lastName: string;
  public clinicianName: string;
  public clinicianComment: string;
  public createdAt: string;
  public orthodonticTreatmentTypes: OrthodonticTreatmentType[] = [];


  public mapToOrthodonticTreatment(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.patientRef = data.patientRef;
      this.firstName = data.patientFirstName;
      this.lastName = data.patientLastName;
      this.clinicianName = data.clinicianName;
      this.clinicianComment = data.clinicianComment;
      this.createdAt = data.createdAt;

      // Treatment types
      if (data.orthodonticTreatmentTypes != null && data.orthodonticTreatmentTypes.length > 0) {
        const orthodonticTreatmentArray = data.orthodonticTreatmentTypes;
        orthodonticTreatmentArray.forEach(t => {
          const orthodonticTreatment = new OrthodonticTreatmentType();
          orthodonticTreatment.mapToOrthodonticTreatmentType(t);
          this.orthodonticTreatmentTypes.push(orthodonticTreatment)
        });
      }
     }
  }

}
