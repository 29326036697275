import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NutritionFormDto } from './../../../../../../../dto/forms/nutritionFormDto';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PatientInformation } from './../../../../../../../entity/patient-information';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { FormsService } from 'src/app/service/forms.service';
import { FormCode } from 'src/app/constant/form-code-enum';
import { FormsDto } from './../../../../../../../dto/forms/forms-dto';

declare function successToast(msg): any;


@Component({
  selector: 'app-nutrition-forms',
  templateUrl: './nutrition-forms.component.html',
  styleUrls: ['./nutrition-forms.component.scss']
})
export class NutritionFormsComponent implements OnInit {
  // variables
  public action: string;
  public formRef: string;
  public formCode: string;
  public patientId: number;
  public onSubmit = false;
  public formsDto = new FormsDto();
  public patientInformation: PatientInformation;
  public nutritionFormDto = new NutritionFormDto();
  
  public today = new Date().toJSON().split('T')[0];
  public feedingInfoReasonInfantList = [];
  public feedingInfoReasonInfantTypes = [];
  public outpatientList = [];
  public outpatientTypes = [];
  public referListInfant = [];
  public referTypesInfant = [];
  public nutritonPlanListInfant = [];
  public nutritonPlanTypesInfant = [];
  public anemiaMgtListInfant = [];
  public anemiaMgtTypesInfant = [];
  public feedingInfoListChild = [];
  public feedingInfoTypesChild = [];
  public nutritionStatusChildList = [];
  public nutritionStautsChildTypes = [];
  public nutritionPlanListChild = [];
  public nutritionPlanTypesChild = [];
  public anemiaMgtListChild = [];
  public anemiaMgtTypesChild = [];
  public feedingInfoListAdol = [];
  public feedingInfoTypesAdol = [];
  public nutritionStatusListAdol = [];
  public nutritionStatusTypesAdol = [];
  public nutritionPlanListAdol = [];
  public nutritionPlanTypesAdol = [];
  dropdownSettings: IDropdownSettings = {};
  multipleList = { item_id: 0, item_text: ''}
  public outPatientCareOption: string; //temporal variable
  public referToAnotherfacility: string; //temporal variable

  constructor(public patientService: PatientService,
    public router: Router,
    public formService: FormsService,) { }


  ngOnInit(): void {
     
    setTimeout(() => {
      this.getPatient(this.patientId);

      if (this.action === 'update') {
        this.getSingleNutritionForm();
      }
    }, 500);

   
    // Outpatient care  multiple-options for Infant(less than 6months)
    this.outpatientList = [
      { item_id: 1, item_text: 'A staff member is in charge of the follow-up' },
      { item_id: 2, item_text: 'The mother/caregiver is asked to return on a specific follow-up date' },
      { item_id: 3, item_text: 'None of the above' }

    ];

    // Feeding infomation Reason   multiple-options for Infant(less than 6months)
    this.feedingInfoReasonInfantList = [
      { item_id: 1, item_text: 'The infant has difficulty feeding' },
      { item_id: 2, item_text: 'The infant presents other complications' },
      { item_id: 3, item_text: 'The mother has difficulty breastfeeding' },
      { item_id: 4, item_text: 'None of the above' }

    ];

    // Refer to another Facility  multiple-options for Infant(less than 6months)
    this.referListInfant = [
      { item_id: 1, item_text: 'A staff member is in charge of the follow-up' },
      { item_id: 2, item_text: 'The mother/caregiver is asked to return for visits' },
      { item_id: 3, item_text: 'None of the above' }

    ];

    // Nutrition Care Plan  multiple-options for Infant(less than 6months)
    this.nutritonPlanListInfant = [
      { item_id: 1, item_text: 'Provision of breastfeeding support' },
      { item_id: 2, item_text: 'Provision of food rations or support to the mother for the purchase of food commodities that can improve her nutritional status' },
      { item_id: 3, item_text: 'Advice regarding food commodities and preparations that can improve the mothers nutritional status' }

    ];

    // Anemia Management multiple-options for Infant(less than 6months)
    this.anemiaMgtListInfant = [
      { item_id: 1, item_text: 'Provision and/or prescription of iron supplements' },
      { item_id: 2, item_text: 'Referral for further investigations' },
      { item_id: 3, item_text: 'Additional/other approaches in the management of anemia' }

    ];

    // Feeding Information  multiple-options for Child(6months-5years)
    this.feedingInfoListChild = [
      { item_id: 1, item_text: 'Child has difficulty in feeding' },
      { item_id: 2, item_text: 'Child has other complications' },
      { item_id: 3, item_text: 'The mother does not feed breast milk to her child' }

    ];

    //Nutrition Status multiple-options for Child(6months-5years)
    this.nutritionStatusChildList = [
      { item_id: 1, item_text: 'Moderately wasted' },
      { item_id: 2, item_text: 'Severely wasted' },
      { item_id: 3, item_text: 'Stunted' },
      { item_id: 4, item_text: 'Presenting bilateral pitting edema' },
      { item_id: 5, item_text: 'Anemic' }

    ];

    //Nutrition care plan multiple-options for Child(6months-5years)
    this.nutritionPlanListChild = [
      { item_id: 1, item_text: 'Treatment with therapeutic milks and/or ready-to-use therapeutic feeds (RUTF)' },
      { item_id: 2, item_text: 'Provision of food commodities or support for the purchase of food commodities for and by the mother to improve her nutritional intake and/or that of the child' },
      { item_id: 3, item_text: 'Advice regarding food commodities and preparations that can improve the mothers and/or the childs nutritional intake' },
      { item_id: 4, item_text: 'Additional/other approaches to care' },

    ];

    // Anemia Management multiple-options for Child(6months-5years)
    this.anemiaMgtListChild = [
      { item_id: 1, item_text: 'Advice on food sources of iron and B vitamins' },
      { item_id: 2, item_text: 'Provision and/or prescription of iron and/or B vitamins and/or MVM supplements' },
      { item_id: 3, item_text: 'Financial support to access and/or provision of food sources of iron and/or B vitamins' },
      { item_id: 4, item_text: 'Additional and/or other approaches in the management of anemia' },

    ];

    // Feeding Information  multiple-options for Child(5years-Adolescent)
    this.feedingInfoListAdol = [
      { item_id: 1, item_text: 'Patient has difficulty in feeding' },
      { item_id: 2, item_text: 'Patient has other complications' },
      { item_id: 3, item_text: 'None of the above' }

    ];

    //Nutrition Status multiple-options for Child(5years-Adolescent)
    this.nutritionStatusListAdol = [
      { item_id: 1, item_text: 'Malnourished' },
      { item_id: 2, item_text: 'Presenting bilateral pitting edema' },
      { item_id: 3, item_text: 'Anemic' },

    ];

    // Nutrition Care Plan  multiple-options for Child(5years-Adolescent)
    this.nutritionPlanListAdol = [
      { item_id: 1, item_text: 'Therpeautic rehabilitation' },
      { item_id: 2, item_text: 'Provision of Supplemental feeds' },
      { item_id: 3, item_text: 'Provision and/or prescription of multivitamin and mineral supplements' },
      { item_id: 4, item_text: 'Provision of food commodities that will help improve thechild/adolescents nutritional status' }

    ];

    // Drop-down settings
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true

    };
  }

  addOption(option) {
    if (option === this.outPatientCareOption) {
      this.nutritionFormDto.referralToAnotherFacility = null;
      this.nutritionFormDto.outPatientCare = [this.outPatientCareOption];
     } 
     else if (option === this.referToAnotherfacility) {
      this.nutritionFormDto.outPatientCare = null;
      this.nutritionFormDto.referralToAnotherFacility = [this.referToAnotherfacility];
      
    }
  }

  getPatient(id) {
  this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => this.errorHandler(error));

  }

  setAgeRange() {
    switch (this.nutritionFormDto.ageRange) {
      case 'Infant (less than 6months)':
        return this.nutritionFormDto.infant = true;
      case 'Child (6months-5years)':
        return this.nutritionFormDto.child = true;
      case 'Child (5years-Adolescent)':
        return this.nutritionFormDto.adolescent = true;
      case 'Adult':
        return this.nutritionFormDto.adult = true;
    }
  }


  setFormDetails(formCode: string, patientId: number, formRef: string, action: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.action = action;
    this.formRef = formRef;
  }

  calculateBMI() {
    const Bmi = (this.nutritionFormDto.weight) /
      Math.pow((this.nutritionFormDto.height / 100), 2);
    this.nutritionFormDto.bmi = Number(Bmi.toFixed(2));
  }

  // Add outpatient care (Infant and Child)
  addOutPatient() {
    this.nutritionFormDto.outPatientCare = [];
    this.outpatientTypes.forEach(p => {
      if (!this.nutritionFormDto.outPatientCare.includes(p.item_text)) {
        this.nutritionFormDto.outPatientCare.push(p.item_text)
      }
    })

  }

// Add Feeding Information Reason (Infant)
  addfeedingInfoReason() {
    this.nutritionFormDto.feedingInformationReason = []
    this.feedingInfoReasonInfantTypes.forEach(r => {
      if (!this.nutritionFormDto.feedingInformationReason.includes(r.item_text)) {
        this.nutritionFormDto.feedingInformationReason.push(r.item_text)
      }
    })

  }

  
  // Add Refer to another Facility (Infant and Child)
  addReferToAnotherFacility() {
    this.nutritionFormDto.referralToAnotherFacility = [];
    this.referTypesInfant.forEach(r => {
      if (!this.nutritionFormDto.referralToAnotherFacility.includes(r.item_text)) {
        this.nutritionFormDto.referralToAnotherFacility.push(r.item_text)
      }
    })
  }

  
  // Add Nutrition care plan (Infant)
  addNutritionPlanInfant() {
    this.nutritionFormDto.nutritionCarePlan = [];
    this.nutritonPlanTypesInfant.forEach(i => {
      if (!this.nutritionFormDto.nutritionCarePlan.includes(i.item_text)) {
        this.nutritionFormDto.nutritionCarePlan.push(i.item_text)
      }
    })

  }

  
  // Add Nutrition Care Plan (Child)
  addNutritionPlanChild() {
    this.nutritionFormDto.nutritionCarePlan = [];
    this.nutritionPlanTypesChild.forEach(c => {
      if (!this.nutritionFormDto.nutritionCarePlan.includes(c.item_text)) {
        this.nutritionFormDto.nutritionCarePlan.push(c.item_text)
      }
    })

  }

  
  // Add Nutrition Status (Child)
  addNutritionStatChild() {
    this.nutritionFormDto.nutritionalStatus = [];
    this.nutritionStautsChildTypes.forEach(n => {
      if (!this.nutritionFormDto.nutritionalStatus.includes(n.item_text)) {
        this.nutritionFormDto.nutritionalStatus.push(n.item_text)
      }
    })
  }

  
  // Add Anaemia (Infant)
  addAnemia() {
    this.nutritionFormDto.anemiaManagement = [];
    this.anemiaMgtTypesInfant.forEach(a => {
      if (!this.nutritionFormDto.anemiaManagement.includes(a.item_text)) {
        this.nutritionFormDto.anemiaManagement.push(a.item_text)
      }
    })
  }


  // Add Anaemia (Child , Adolesent, and Adult)
  addAnemiaChild() {
    this.nutritionFormDto.anemiaManagement = [];
    this.anemiaMgtTypesChild.forEach(a => {
      if (!this.nutritionFormDto.anemiaManagement.includes(a.item_text)) {
        this.nutritionFormDto.anemiaManagement.push(a.item_text)
      }
    })
  }

  
  // Add Feeding Information (Child)
  addfeedingInfo() {
    this.nutritionFormDto.feedingInformation = [];
    this.feedingInfoTypesChild.forEach(f => {
      if (!this.nutritionFormDto.feedingInformation.includes(f.item_text)) {
        this.nutritionFormDto.feedingInformation.push(f.item_text)
      }
    })

  }

  
  // Add Feeding Infomation (Adolesent and Adult)
  addfeedingInfoAdolesent() {
    this.nutritionFormDto.feedingInformation = [];
    this.feedingInfoTypesAdol.forEach(f => {
      if (!this.nutritionFormDto.feedingInformation.includes(f.item_text)) {
        this.nutritionFormDto.feedingInformation.push(f.item_text)
      }
    })
  }

  
  // Add Nutrition Status(Adolesent and Adult)
  addNutritionStatAdol() {
    this.nutritionFormDto.nutritionalStatus = [];
    this.nutritionStatusTypesAdol.forEach(c => {
      if (!this.nutritionFormDto.nutritionalStatus.includes(c.item_text)) {
        this.nutritionFormDto.nutritionalStatus.push(c.item_text)
      }
    })
     }

  
  // Add Nutrition Care Plan (Adolesent and Adult)
  addNutritionPlanAdol() {
    this.nutritionFormDto.nutritionCarePlan = [];
    this.nutritionPlanTypesAdol.forEach(c => {
      if (!this.nutritionFormDto.nutritionCarePlan.includes(c.item_text)) {
        this.nutritionFormDto.nutritionCarePlan.push(c.item_text)
      }
    })
  }

  getSingleNutritionForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
          this.nutritionFormDto = new NutritionFormDto();
          this.nutritionFormDto.mapToNutritionForms(data);
          if (this.nutritionFormDto.referralToAnotherFacility != null) {
            this.referToAnotherfacility = this.nutritionFormDto.referralToAnotherFacility[0];
          };
          if (this.nutritionFormDto.outPatientCare != null) {
          this.outPatientCareOption =  this.nutritionFormDto.outPatientCare[0];
          };
          this.nutritionFormDto.evaluationDate = GeneralUtil.reverseDateFormat(this.nutritionFormDto.evaluationDate);
          
          // Get outpatient care (Infant and Child)
          if(this.nutritionFormDto.outPatientCare){
              this.nutritionFormDto.outPatientCare.forEach(o => {
              this.multipleList = {item_id: 0, item_text: ''};
              this.multipleList.item_id = this.nutritionFormDto.outPatientCare.indexOf(o);
              this.multipleList.item_text = o;
              this.outpatientTypes.push(this.multipleList)
            })
          }
          
          // Get Refer to another Facility (Infant and Child)
          if(this.nutritionFormDto.referralToAnotherFacility) {
              this.nutritionFormDto.referralToAnotherFacility.forEach(o => {
              this.multipleList = {item_id: 0, item_text: ''};
              this.multipleList.item_id = this.nutritionFormDto.referralToAnotherFacility.indexOf(o);
              this.multipleList.item_text = o;
              this.referTypesInfant.push(this.multipleList)
            })
          }
          
        //  Get Nutritional Care Plan (Infant)
         if(this.nutritionFormDto.nutritionCarePlan){
            this.nutritionFormDto.nutritionCarePlan.forEach(o => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.nutritionCarePlan.indexOf(o);
            this.multipleList.item_text = o;
            this.nutritonPlanTypesInfant.push(this.multipleList)
          })
         }
         
            // Get Anemia (Infant)
            if(this.nutritionFormDto.anemiaManagement){
            this.nutritionFormDto.anemiaManagement.forEach(a => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.anemiaManagement.indexOf(a);
            this.multipleList.item_text = a;
            this.anemiaMgtTypesInfant.push(this.multipleList)
          })
        }
        // Get Feeding Information (Child)
          if(this.nutritionFormDto.feedingInformation){
            this.nutritionFormDto.feedingInformation.forEach(a => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.feedingInformation.indexOf(a);
            this.multipleList.item_text = a;
            this.feedingInfoTypesChild.push(this.multipleList)
          }) 
        }

        // Get Nutrition Status Child
        if(this.nutritionFormDto.nutritionalStatus){
          this.nutritionFormDto.nutritionalStatus.forEach(n => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.nutritionalStatus.indexOf(n);
            this.multipleList.item_text = n;
            this.nutritionStautsChildTypes.push(this.multipleList)
          })   
        }
 
        // Get Nutrition Care Plan (Child)
         if(this.nutritionFormDto.nutritionCarePlan){
          this.nutritionFormDto.nutritionCarePlan.forEach(n => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.nutritionCarePlan.indexOf(n);
            this.multipleList.item_text = n;
            this.nutritionPlanTypesChild.push(this.multipleList)
          })   
        }

          // Get Anemia (Child, Adolesent , and Adult)
          if(this.nutritionFormDto.anemiaManagement){
            this.nutritionFormDto.anemiaManagement.forEach(a => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.anemiaManagement.indexOf(a);
            this.multipleList.item_text = a;
            this.anemiaMgtTypesChild.push(this.multipleList)
          })
        }

        // Get Feeding Information (Adolescent and Adult)
         if(this.nutritionFormDto.feedingInformation){
          this.nutritionFormDto.feedingInformation.forEach(n => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.feedingInformation.indexOf(n);
            this.multipleList.item_text = n;
            this.feedingInfoTypesAdol.push(this.multipleList)
          })
        }

        // Get Nutrition Status(Adolesent and Adult)
         if(this.nutritionFormDto.nutritionalStatus){
          this.nutritionFormDto.nutritionalStatus.forEach(n => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.nutritionalStatus.indexOf(n);
            this.multipleList.item_text = n;
            this.nutritionStatusTypesAdol.push(this.multipleList)
          })
        }

        // Get Nutrition Care Plan (Adolesent and Adult)
        if(this.nutritionFormDto.nutritionCarePlan){
          this.nutritionFormDto.nutritionCarePlan.forEach(n => {
            this.multipleList = {item_id: 0, item_text: ''};
            this.multipleList.item_id = this.nutritionFormDto.nutritionCarePlan.indexOf(n);
            this.multipleList.item_text = n;
            this.nutritionPlanTypesAdol.push(this.multipleList)
          })
        }

       return this.nutritionFormDto;
         
        },
       
        error1 => {
          this.errorHandler(error1);
        })
  }

  submitForm() {
    this.onSubmit = true;
    this.setAgeRange()
      // convert date to 'DD-MM-YYYY'
      this.nutritionFormDto.evaluationDate = GeneralUtil.reverseDateFormat(this.nutritionFormDto.evaluationDate);
      this.formsDto.code = this.formCode;
      this.formsDto.nutritionFormDto = this.nutritionFormDto;
    // Registration
    if (this.action === 'registration') {
    this.formsDto.patientId = this.patientId;
     this.formService.formRegistration(this.formsDto)
      .subscribe(data => {
        this.onSubmit = false;
        successToast('Form submitted successfully');
        this.router.navigate(['/report/view-patient-information/' + this.patientId]);

      },
        error1 => {
          this.onSubmit = false;
          this.nutritionFormDto.evaluationDate = GeneralUtil.reverseDateFormat(this.nutritionFormDto.evaluationDate);
          this.errorHandler(error1);
        });
    return;
      }

     /* Update */
    if (this.action === 'update') {
      this.formService.formUpdate(this.formsDto, this.formRef)
     .subscribe(data => {
        this.onSubmit = false;
        successToast('Form updated successfully');
         this.router.navigate(['/report/view-patient-information/' + this.patientId]);
      }, 
      error1 => {
        this.onSubmit = false;
        this.nutritionFormDto.evaluationDate = GeneralUtil.reverseDateFormat(this.nutritionFormDto.evaluationDate)
        this.errorHandler(error1);
      });
    return;
  }

}

  // Error Handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
