import { GeneralUtil } from 'src/app/util/general-util';

export class CostOfCare{
  public patientId: number;
  public amountSpentOutOfPocket: number;
  public costOfTransportation: number;
  public costOfUpkeep: number;
  public hasHealthInsurance: boolean;
  public otherMedicalCosts: number;
  public percentageOfCostCoveredByHealthInsurance: number;
  public sourcesOfFinance: string[];
  public totalCostOfBedStays: number;
  public totalCostOfICUStays: number;
  public totalCostOfLabTests: number;
  public totalCostOfMedications: number;
  public totalCostOfNutritionalCare: number;
  public totalImagingStudies: number;
  public otherNonMedicalCosts: number;
  public totalMedicalCosts: number;
  public totalNonMedicalCosts: number;
  public totalSurgicalProcedure: number;

  public mapToCostOfCare(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.patientId = data.patientId;
      this.amountSpentOutOfPocket = data.amountSpentOutOfPocket;
      this.costOfTransportation = data.costOfTransportation;
      this.costOfUpkeep = data.costOfUpkeep;
      this.hasHealthInsurance = data.hasHealthInsurance;
      this.otherMedicalCosts = data.otherMedicalCosts;
      this.percentageOfCostCoveredByHealthInsurance = data.percentageOfCostCoveredByHealthInsurance;
      this.sourcesOfFinance = data.sourcesOfFinance;
      this.totalCostOfBedStays = data.totalCostOfBedStays;
      this.totalCostOfICUStays = data.totalCostOfICUStays;
      this.totalCostOfLabTests = data.totalCostOfLabTests;
      this.totalCostOfMedications = data.totalCostOfMedications;
      this.totalCostOfNutritionalCare = data.totalCostOfNutritionalCare;
      this.otherNonMedicalCosts = data.otherNonMedicalCosts;
      this.totalImagingStudies = data.totalImagingStudies;
      this.totalMedicalCosts = data.totalMedicalCosts;
      this.totalNonMedicalCosts = data.totalNonMedicalCosts;
      this.totalSurgicalProcedure = data.totalSurgicalProcedure;
    }
  }
}
