import { Component, OnInit } from '@angular/core';
import {PatientInformation} from '../../../../../../../entity/patient-information';
import {MortalityFormDto} from '../../../../../../../dto/forms/mortality-form-dto';
import {PatientService} from '../../../../../../../service/patient.service';
import {FormsService} from '../../../../../../../service/forms.service';
import {Router} from '@angular/router';
import {GeneralUtil} from '../../../../../../../util/general-util';
import {SurgicalTreatmentFormDto} from '../../../../../../../dto/forms/surgical-treatment-form-dto';
import {FormCode} from '../../../../../../../constant/form-code-enum';

@Component({
  selector: 'app-mortality-form-view',
  templateUrl: './mortality-form-view.component.html',
  styleUrls: ['./mortality-form-view.component.scss']
})
export class MortalityFormViewComponent implements OnInit {

  public formCode;
  public patientId;
  public formRef: string;
  public patientInformation: PatientInformation;
  public mortalityFormDto: MortalityFormDto;
  public surgicalTreatmentForms: SurgicalTreatmentFormDto[] = [];

  constructor(public patientService: PatientService,
              public formService: FormsService,
              public router: Router,) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);
      this.getSingleForm();
    }, 500)
    setTimeout(() => {
      this.getSurgicalFormsType();
    }, 800)
  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
      .subscribe(data => {
          this.patientInformation = new PatientInformation();
          this.patientInformation.mapToPatientInformation(data);
          return this.patientInformation;
        },
        error => this.errorHandler(error));
  }

  getSingleForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {
        this.mortalityFormDto = new MortalityFormDto();
        this.mortalityFormDto.mapToMortalityForm(data);
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  removeUnderscore(wordString) {
    return GeneralUtil.removeUnderscore(wordString);
  }

  getSurgicalFormsType() {
    this.formService.getSurgicalForms(FormCode.SURGICAL_TREATMENT_CODE, this.patientId)
      .subscribe(data => {
          this.surgicalTreatmentForms = [];
          const surgeryArray = data;
          surgeryArray.forEach(s => {
            const surgeryTreatment = new SurgicalTreatmentFormDto();
            surgeryTreatment.mapToSurgicalTreatmentForm(s);
            this.surgicalTreatmentForms.push(surgeryTreatment);
          })

          return this.surgicalTreatmentForms;
        },
        error1 => {

          this.errorHandler(error1);
        });
  }


  setFormDetails(formCode: string, patientId: number, formRef: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.formRef = formRef;
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
