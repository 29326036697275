
export class UserDto{
  public firstName: string;
  public lastName: string;
  public username: string;
  public email: string;
  public phone: string;
  public designation: string;
  public specialty: string;
  public roleId: number;
  public roleName: string;
  public facilityIds = [];
}
