import { Permission } from './permission';
import { GeneralUtil } from 'src/app/util/general-util';



export class Role {
  public id: number;
  public role: string;
  public status: string
  public permissions : Permission[] = []


  public mapToRole(data: any) {
    if (GeneralUtil.isValidJSON(data)) {
      this.id = data.id;
      this.role = data.name;
      this.status = data.status;
      this.permissions = data.permissions;
    }
  }
}

