import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GeneralEnum } from '../../constant/general-enum.enum';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Permission } from '../../entity/permission';
import { GeneralUtil } from '../../util/general-util';
import { LoginDto } from '../../dto/login-dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static userDetailsEmail = 'USER-DETAILS-EMAIL';
  public static userDetailsUsername = 'USER-DETAILS-USERNAME';
  public static userDetailsFullName = 'USER-DETAILS-FULL-NAME';
  public static userDetailsRole = 'USER-DETAILS-ROLE';
  public static userDetailsToken = 'USER-DETAILS-TOKEN';
  public static userDetailsPermissions = 'USER-DETAILS-PERMISSIONS';
  public static userDetailsIsFirstTimeUser = 'USER-DETAILS-IS-FIRST-TIME-USER';

  constructor(private httpClient: HttpClient) { }

      // Login user
      loginUser(loginDto: LoginDto) {
        loginDto.platformType = 'SMILE_TRAIN_WEB_ADMIN';

        const url = GeneralEnum.baseUrl + '/login';

        return this.httpClient.post<any>(url, loginDto)

        .pipe(catchError(this.errorHandler));
      }

      // Confirm if user is logged in
      isLoggedIn() {
        return !!localStorage.getItem(AuthService.userDetailsToken);
    }

    // Get Token value
    getToken() {
        return localStorage.getItem(AuthService.userDetailsToken);
    }

    // Get Authorization Token
    getAuthToken() {
        if (this.getToken() == null) {
            return 'Bearer ';
        } else {
            return 'Bearer ' + this.getToken();
        }
    }

    // after login, set userdetails
    public setUserDetails(data: any) {
        localStorage.setItem(AuthService.userDetailsEmail, data.email);
        localStorage.setItem(AuthService.userDetailsUsername, data.username);
        localStorage.setItem(AuthService.userDetailsFullName, data.fullName);
        localStorage.setItem(AuthService.userDetailsToken, data.token);
        localStorage.setItem(AuthService.userDetailsRole, data.role.name);

        const permissionsList: Permission[] = [];
        data.permissions.forEach(s => {
            const permission = new Permission();
            permission.mapToPermission(s);
            permissionsList.push(permission);
        });
        localStorage.setItem(AuthService.userDetailsPermissions, JSON.stringify(permissionsList));
        localStorage.setItem(AuthService.userDetailsIsFirstTimeUser, 'true');
    }

    // reset user details
    public resetUserDetails() {
        localStorage.removeItem(AuthService.userDetailsEmail);
        localStorage.removeItem(AuthService.userDetailsUsername);
        localStorage.removeItem(AuthService.userDetailsFullName);
        localStorage.removeItem(AuthService.userDetailsToken);
        localStorage.removeItem(AuthService.userDetailsRole);
        localStorage.removeItem(AuthService.userDetailsPermissions);
        localStorage.removeItem(AuthService.userDetailsIsFirstTimeUser);
    }

    public getUserDetailItem(userDetailItem: string): string {
        const userDetail = localStorage.getItem(userDetailItem);
        if (!GeneralUtil.isValidString(userDetail)) {
            return null;
        }
        return userDetail;
    }

    public setOneUserDetail(userDetailItem: string, value: string) {
        const userDetail = localStorage.getItem(userDetailItem);

        if (GeneralUtil.isValidString(userDetail)) {
            localStorage.setItem(userDetailItem, value);
        }
    }

    public hasPermission(permissionName: string): boolean {
        const permissions: Permission[] = JSON.parse(localStorage.getItem(AuthService.userDetailsPermissions));

        if (permissions == null || permissions.length === 0) {
            return false;
        }

        let check = false;
        permissions.forEach(p => {
            if (p.name === permissionName) {
                check = true;
            }
        });

        return check;
    }

    // Error Handler
    errorHandler(error: HttpErrorResponse) {
        return throwError(error);
    }
}
