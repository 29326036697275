import { SpeechAssessmentFormDto } from './../../../../../../../dto/forms/speech-assessment-form-dto';
import { Component, OnInit } from '@angular/core';
import { PatientInformation } from 'src/app/entity/patient-information';
import { PatientService } from 'src/app/service/patient.service';
import { GeneralUtil } from 'src/app/util/general-util';
import { FormsService } from './../../../../../../../service/forms.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-speech-assessment-view',
  templateUrl: './speech-assessment-view.component.html',
  styleUrls: ['./speech-assessment-view.component.scss']
})
export class SpeechAssessmentViewComponent implements OnInit {
  public formCode;
  public patientId;
  public formRef: string;
  public patientInformation: PatientInformation;
  public formDetails: SpeechAssessmentFormDto;

  constructor(
    public patientService: PatientService,
    public formService: FormsService,
    public router: Router
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getPatient(this.patientId);

      this.getSingleForm();
    }, 500)
  }

  getPatient(id) {

    this.patientService.getPatientDetail(id)
      .subscribe(data => {
        this.patientInformation = new PatientInformation();
        this.patientInformation.mapToPatientInformation(data);
        return this.patientInformation;
      },
        error => this.errorHandler(error));
  }

  getSingleForm() {
    this.formService.getSingleForm(this.formCode, this.formRef)
      .subscribe(data => {

        this.formDetails = new SpeechAssessmentFormDto();
        this.formDetails.mapFormToData(data);
      }, error1 => {
        this.errorHandler(error1);
      });
  }

  setFormDetails(formCode: string, patientId: number, formRef: string) {
    this.formCode = formCode;
    this.patientId = patientId;
    this.formRef = formRef;
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
