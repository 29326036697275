import {GeneralUtil} from '../util/general-util';

export class SmileTrainPartner {
    public id: number;
    public name: string;
    public status: string;

    public mapToEntity(data: any): void {
        if (GeneralUtil.isValidJSON(data)) {
            this.id = data.id;
            this.name = data.name;
            this.status = data.status;
        }
    }
}
