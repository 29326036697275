import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PatientInformation } from '../../../entity/patient-information';
import {CountryAndStateService} from '../../../service/country-and-state.service';
import {Country} from '../../../entity/country';
import {GeneralUtil} from '../../../util/general-util';
import {State} from '../../../entity/state';
import {Lga} from '../../../entity/lga';
import {ParentGuardianInfo} from '../../../entity/parent-guardian-info';

declare function successToast(msg: string): any;
declare function failureToast(msg): any;
@Component({
  selector: 'app-page-two',
  templateUrl: './page-two.component.html',
  styleUrls: ['./page-two.component.scss']
})

export class PageTwoComponent implements OnInit {
  // variables
  public parentGuardianInfo = new ParentGuardianInfo();
  public today = new Date().toJSON().split('T')[0];
  public countries: Country[] = [];
  public states: State[] = [];
  public lgas: Lga[] = [];
  public ageNumber: number;
  public ageRange: string;
  public age: number;
  public enterAge = 'dateOfBirth';
  public patientInformation: PatientInformation;
  @Output() pageTwoCompleted = new EventEmitter<string>();
  @Output() previousPage = new EventEmitter<string>();

  constructor(public countryAndStateService: CountryAndStateService) { }


  ngOnInit(): void {
    if (sessionStorage.getItem('patientInformation')) {
      this.patientInformation = JSON.parse(sessionStorage.getItem('patientInformation'));
      // get countries
      this.getCountries();
    } else {
      this.toPreviousPage(event);
    }
  }

  pageTwoFinished(event: Event) {
    if (this.patientInformation.parentGuardianInfo.length < 1) {
      failureToast('Father or Mother or Guardian details MUST be registered')
    } else {
    sessionStorage.setItem('patientInformation', JSON.stringify(this.patientInformation));
    this.pageTwoCompleted.emit('two');
    event.preventDefault();
    }
  }

  toPreviousPage(event: Event) {
    sessionStorage.setItem('patientInformation', JSON.stringify(this.patientInformation));
    this.previousPage.emit('one');
    event.preventDefault();
  }

  getCountries() {
    this.countries = [];
    this.countryAndStateService.getCountries()
      .subscribe(data => {
          if (data.length > 0) {
            const countryList = data;
            countryList.forEach(c => {
              const country = new Country();
              country.mapToCountry(c);
              this.countries.push(country);
            });
          }
        },
        error1 => this.errorHandler(error1));
  }

  getStates(country: string) {
    // get id of country selected.
    const countryName = country;
    if (!GeneralUtil.isValidString(countryName)) {
      return;
    }

    let countryId;
    this.countries.forEach(c => {
      if (c.name === countryName) {
        countryId = c.id;
      }
    });

    this.states = [];
    this.countryAndStateService.getStates(countryId)
      .subscribe(data => {
          if (data.length > 0) {
            data.forEach(c => {
              const state = new State();
              state.mapToState(c);
              this.states.push(state);
            });
          }
        },
        error1 => {
          this.errorHandler(error1);
        });
  }

  getLgas(state: string) {

    // get id of state selected.
    const stateName = state;
    if (!GeneralUtil.isValidString(stateName)) {
      return;
    }

    let stateId;
    this.states.forEach(s => {
      if (s.name === stateName) {
        stateId = s.id;
      }
    });

    this.lgas = [];
    this.countryAndStateService.getLgas(stateId)
      .subscribe(data => {
          if (data.length > 0) {
            data.forEach(c => {
              const lga = new Lga();
              lga.mapToLga(c);
              this.lgas.push(lga);
            });
          }
        },
        error1 => {
          this.errorHandler(error1);
        });

  }

  setDateOfBirth() {
    const dob = GeneralUtil.getDateOfBirthByAgeRange(this.ageRange, this.ageNumber);
    this.parentGuardianInfo.dateOfBirth =
    GeneralUtil.convertDateTimeToDateInputFormat(GeneralUtil.convertDateStringToIsoDateTimeFormat(dob));
  }

  addNewParentOrGuardian(patientForm) {
    // validate - ensure Father and Mother are not created multiple times
    const parentGuardianList = this.patientInformation.parentGuardianInfo;
    if (this.parentGuardianInfo.relationshipWithChild === 'FATHER') {
      if (parentGuardianList.filter(p => {
        return p.relationshipWithChild === 'FATHER';
      }).length > 0) {
        failureToast('Father has been created already');
        return;
      }
    }

    if (this.parentGuardianInfo.relationshipWithChild === 'MOTHER') {
      if (parentGuardianList.filter(p => {
        return p.relationshipWithChild === 'MOTHER';
      }).length > 0) {
        failureToast('Mother has been created already');
        return;
      }
    }

    if (this.parentGuardianInfo.relationshipWithChild === 'GUARDIAN') {
      if (parentGuardianList.filter(p => {
        return p.relationshipWithChild === 'GUARDIAN';
      }).length > 0) {
        failureToast('Guardian has been created already');
        return;
      }
    }


    parentGuardianList.push(this.parentGuardianInfo);
    this.patientInformation.parentGuardianInfo = parentGuardianList;

    this.parentGuardianInfo = new ParentGuardianInfo();

    patientForm.resetForm();
    successToast('Parent/Guardian added');

  }

  removeGuardian(pg: ParentGuardianInfo) {
    this.patientInformation.parentGuardianInfo =
      this.patientInformation.parentGuardianInfo
        .filter(g => pg.firstName !== g.firstName ||
          pg.relationshipWithChild !== g.relationshipWithChild);

    successToast('Parent/Guardian has been deleted');
  }

  // Error handling
  errorHandler(error) {
    return GeneralUtil.errorHandler(error)
  }

}
