import { Component, OnInit } from '@angular/core';
import { FormCode } from './../../../constant/form-code-enum';
import { AuthService } from 'src/app/service/auth/auth.service';
 
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public formCode = FormCode;
  constructor(public authService: AuthService) { }

  ngOnInit() {
  }

}
